var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-filter-wrapper p-mb-3"},[_c('div',{staticClass:"filter-dropdown-wrapper p-grid"},[_c('div',{class:[_vm.selectedFilters.length === 0 ? 'p-col-12' : 'p-col-6']},[_c('div',{staticClass:"p-grid"},_vm._l((_vm.headerswithFilters),function(header,index){return _c('div',{key:index,class:[
                        _vm.filterList[header.fieldName] && _vm.filterList[header.fieldName].length > 0
                            ? 'p-col-4'
                            : '' ]},[(
                            _vm.filterList[header.fieldName] &&
                            _vm.filterList[header.fieldName].length > 0
                        )?_c('div',[(_vm.filterList[header.fieldName])?_c('div',{staticClass:"p-mb-3 filter-label"},[_vm._v(" "+_vm._s(_vm.t(header.header, _vm.language))+" ")]):_vm._e(),_c('MultiSelect',{attrs:{"options":_vm.filterList[header.fieldName],"optionLabel":"value","optionValue":"key","placeholder":_vm.t('SELECT', _vm.language),"filter":true},on:{"change":function($event){return _vm.applyFilter()}},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps.value && slotProps.value.length)?_vm._l((slotProps.value),function(option,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(_vm.getLabelValue(header.fieldName, option))+", ")])}):_vm._e(),(slotProps.value && slotProps.value.length === 0)?[_vm._v(" "+_vm._s(slotProps.placeholder)+" ")]:_vm._e()]}},{key:"option",fn:function(slotProps){return [_c('div',{staticClass:"country-item"},[(header.fieldName === 'norm')?_c('div',{class:[
                                            slotProps.option.filterActive
                                                ? 'highlight-text'
                                                : 'normal-text' ]},[_vm._v(" "+_vm._s(slotProps.option.value)+" ")]):(header.fieldName === 'applicable')?_c('div',[_vm._v(" "+_vm._s(_vm.t((slotProps.option.value.toUpperCase()), _vm.language))+" ")]):_c('div',[_vm._v(_vm._s(slotProps.option.value))])])]}}],null,true),model:{value:(_vm.formData[header.fieldName]),callback:function ($$v) {_vm.$set(_vm.formData, header.fieldName, $$v)},expression:"formData[header.fieldName]"}})],1):_vm._e()])}),0)]),(_vm.selectedFilters.length > 0)?_c('div',{staticClass:"p-col-6"},[_c('div',{staticClass:"selected-items-chip-section"},_vm._l((_vm.selectedFilters),function(item,index){return _c('div',{key:index,staticClass:"chip-wrapper p-mr-1",staticStyle:{"display":"inline-flex"},on:{"click":function($event){return _vm.removeSelectedItem(item.field, item.key)}}},[(item.value)?_c('div',{staticClass:"chip-text"},[_c('span',[_vm._v(_vm._s(item.value))]),_c('span',{class:[
                                'chip-remove-icon',
                                'pi pi-times-circle',
                                'text',
                                item.field === 'netRiskStyleClass' ? item.value : '' ]})]):_vm._e()])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }