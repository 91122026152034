













































































import { useState } from '@/shared/mixins/helpers'
import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import Table from '@/shared/components/Table.vue'
import controlQuery from '@/shared/queries/controlQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import { ControlAssessmentDTO } from '@/dto/backend-response/controlAssessmentsDTO'
import generalData from '@/assets/data/general-data.json'
import moment from 'moment'
import controlAssessmentDropdownData from '@/assets/data/control-assessment-data.json'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import ControlAssessment from '@/components/control-assessment/ControlAssessment.vue'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import Confirmation from '@/shared/components/Confirmation.vue'
import ConfirmMessage from '@/shared/components/ConfirmMessage.vue'

export default defineComponent({
    props: {
        controlDetails: {
            type: Object,
        },
    },
    components: {
        Table,
        ControlAssessment,
        Confirmation,
        ConfirmMessage,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const controlassessments = ref([])
        const loading = ref(false)
        const displayControlAssessment = ref(false)
        const lastRecordNo = ref(null)
        const firstRecordNo = ref(null)
        const controlAssessmentRecordNo = ref(null)
        const tableCmp = ref(null)
        const { role } = useState(['role'])
        const assessmentId = ref(null)
        const newAssessmentSourceId: Ref<number> = ref(null)
        const confirm = ref(null)

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    fieldName: 'refId',
                    style: 'min-width: 150px;',
                },
                {
                    header: t('ASSESSMENT_LABEL_EXECUTION_DATE', language.value),
                    fieldName: 'fexecutionDate',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_EXECUTED_BY', language.value),
                    fieldName: 'fexecutedBy',
                },
                {
                    header: t('ASSESSMENT_LABEL_MATURITY', language.value),
                    fieldName: 'maturity',
                },
                {
                    header: t('ASSESSMENT_LABEL_DESIGN', language.value),
                    fieldName: 'design',
                    effectiveness: true,
                },
                {
                    header: t('ASSESSMENT_LABEL_EXISTENCE', language.value),
                    fieldName: 'existence',
                    effectiveness: true,
                },
                {
                    header: t('ASSESSMENT_LABEL_OPERATIONAL_EFFECTIVENESS', language.value),
                    fieldName: 'operationalEffectiveness',
                    effectiveness: true,
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_STATUS', language.value),
                    fieldName: 'status',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_PERIOD', language.value),
                    fieldName: 'period',
                },
            ]
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, state)

            return t(stateVal, language.value)
        }

        // go to control-assessment record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `controlassessments`  or sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the record id (of previous/ next)
                assessmentId.value = controlassessments.value.find(
                    (assessment) => assessment.no === record.no
                ).id
                controlAssessmentRecordNo.value = record.no
            }
        }

        // updating controlassessments array with additional fields and setting description content
        const toTableFormat = (cas: Array<ControlAssessmentDTO>) => {
            controlassessments.value = []
            cas.map((controlAssessment) => {
                let ca = {
                    no: null,
                    fexecutedBy: '',
                    fexecutionDate: '',
                    status: null,
                    fmaturity: '',
                    fdesign: '',
                    fexistence: '',
                    foperationalEffectiveness: '',
                    period: '',
                }
                // creating a copy of object to hold formatted and extra attributes
                ca = Object.create(controlAssessment)
                ca.no = utils.padLeft('000', controlAssessment.refId)
                ca.fexecutedBy =
                    controlAssessment.executedBy &&
                    controlAssessment.executedBy.firstName +
                        ' ' +
                        controlAssessment.executedBy.lastName
                ca.fexecutionDate = controlAssessment.executionDate
                    ? moment(controlAssessment.executionDate).format('DD-MM-YYYY')
                    : ''
                ca.status = convertIdToNames(controlAssessment.state)
                ca.fmaturity = controlAssessmentDropdownData.MATURITY.find(
                    (val) => val.SCORE === controlAssessment.maturity
                )?.VALUE
                if (controlAssessment.opStartDate && controlAssessment.opEndDate) {
                    ca.period =
                        (controlAssessment.opStartDate &&
                            moment(controlAssessment.opStartDate).format('DD-MM-YYYY')) +
                        '-' +
                        (controlAssessment.opEndDate &&
                            moment(controlAssessment.opEndDate).format('DD-MM-YYYY'))
                }
                controlassessments.value.push(ca)
            })
        }

        // get control assessments
        const getControlAssessments = async () => {
            loading.value = true
            const query = `
               query{
                    controlassessments(controlId:${props.controlDetails.id}){
                        items{
                            ${controlQuery.CONTROL_ASSESSMENTS_LIST_TAB}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving control assessments')
            }
            if (result) {
                const assessments = result.data.controlassessments.items
                // sort by executionDate (newest to old)
                assessments.sort((a, b) => (a.executionDate < b.executionDate ? 1 : -1))
                // updating controlassessments with additional fields and setting description content
                toTableFormat(assessments)
            }
        }

        // Show an existing assessment in the sidebar
        const openControlAssessmentPopup = (id) => {
            displayControlAssessment.value = true
            assessmentId.value = id
            controlAssessmentRecordNo.value = controlassessments.value.find(
                (assessment) => assessment.id == id
            ).no

            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        const createNewAssessment = () => {
            assessmentId.value = null
            const latestAssessmentId = controlassessments.value?.[0]?.id

            const createEmptyAssessment = () => {
                newAssessmentSourceId.value = null
                displayControlAssessment.value = true
            }

            const createAssessmentFromLatest = () => {
                newAssessmentSourceId.value = latestAssessmentId
                displayControlAssessment.value = true
            }

            if (latestAssessmentId) {
                // Ask user if we should use the latest assessment or not
                confirm.value.confirm(
                    "CONFIRMATION_POPUP_USE_PREVIOUS_ASSESSMENT_RESULTS",
                    createAssessmentFromLatest,
                    createEmptyAssessment,
                )
            } else {
                // There are no assessments to reuse, so create one from scratch
                createEmptyAssessment()
            }
        }


        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                const thirdSidebar = document.querySelector('.third-sidebar')
                if (event.key === 'Escape' && !thirdSidebar) {
                    //close assessment sidebar if it's open and actionplan sidebar(third-sidebar) is closed
                    if (displayControlAssessment.value) {
                        displayControlAssessment.value = false
                    }
                }
            })
        }

        const updateRecords = () => {
            getControlAssessments()
            // refresh the details page
            emit('post-assessment-update', { no: props.controlDetails.no, type: '' })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )

        onMounted(() => {
            closeSidebarEsc()
            getControlAssessments()
        })

        return {
            tableHeaders,
            controlassessments,
            openControlAssessmentPopup,
            loading,
            displayControlAssessment,
            getControlAssessments,
            assessmentId,
            lastRecordNo,
            controlAssessmentRecordNo,
            tableCmp,
            goToRecord,
            variables,
            role,
            t,
            language,
            createNewAssessment,
            updateRecords,
            UserRole,
            firstRecordNo,
            newAssessmentSourceId,
            confirm,
        }
    },
})
