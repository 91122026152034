import { render, staticRenderFns } from "./ControlAssessmentList.vue?vue&type=template&id=0bb81854&scoped=true&"
import script from "./ControlAssessmentList.vue?vue&type=script&lang=ts&"
export * from "./ControlAssessmentList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb81854",
  null
  
)

export default component.exports