var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.assetsWithACassessments && _vm.assetsWithACassessments.length === 0)?_c('Message',{attrs:{"severity":"info","closable":false}},[_vm._v(" "+_vm._s(_vm.t('NO_LIST_TABLE_DATA_AVAILABLE_MESSAGE', _vm.language))+" ")]):_vm._e(),(_vm.assetsWithACassessments && _vm.assetsWithACassessments.length > 0)?_c('table',{staticClass:"asset-item-table"},_vm._l((_vm.assetsWithACassessments),function(asset){return _c('tr',{key:asset.assetId,staticClass:"asset"},[_c('td',{on:{"click":function($event){return _vm.gotoAsset(asset.assetId)}}},[_c('Button',{staticClass:"p-button-link",attrs:{"label":asset.name}})],1),_c('td',[(
                        _vm.role === _vm.UserRole.EMPLOYEE ||
                        _vm.role === _vm.UserRole.PERIUM_ADMIN ||
                        _vm.role === _vm.UserRole.DEVELOPER_ADMIN
                    )?_c('Button',{attrs:{"label":_vm.t('CREATE_ASSESSMENT_LABEL', _vm.language)},on:{"click":function () { return _vm.openAssetControlAssessmentPopup(
                        asset.assetId, 
                        asset.controlId, 
                        asset.assessments
                    ); }}}):_vm._e()],1)])}),0):_vm._e(),_c('div',{staticClass:"entity-details-slider"},[_c('Sidebar',{staticClass:"second-sidebar",attrs:{"visible":_vm.displayAssetControlAssessment,"baseZIndex":1000,"position":"right","modal":false},on:{"update:visible":function($event){_vm.displayAssetControlAssessment=$event}}},[_c('AssetControlAssessment',{attrs:{"controlId":_vm.controlId,"assetId":_vm.assetId,"type":'asset',"accessedFrom":_vm.accessedFrom,"assessmentId":null,"newAssessmentSourceId":_vm.newAssessmentSourceId},on:{"close-assessment":function($event){_vm.displayAssetControlAssessment = false},"post-assessment-update":_vm.updateRecords}})],1)],1),_c('ConfirmMessage',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }