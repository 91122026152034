
































import { defineComponent, computed } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'

export default defineComponent({
    name: 'ControlOperations',
    props: {
        entityDetails: {
            type: Object,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const templateDocuments = computed(
            () => props.entityDetails?.templates ?? []
        )
        return {
            t,
            language,
            templateDocuments,
        }
    },
})
