














import { defineComponent, PropType, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
// http://vuejscomponent.com/package?name=remove-markdown
import removeMd from 'remove-markdown'
import moment from 'moment'
import TableContents from '@/shared/components/table/TableContents.vue'
import type { SortOrderDTO } from "@/dto/sortOrderDTO"

export default defineComponent({
    props: {
        columnHeaders: {
            type: null,
            required: true,
        },
        tableData: {
            type: null,
            required: true,
        },
        viewDetailsPopup: {
            type: Boolean,
        },
        // totalPages of each entities based on perPage
        totalPages: {
            type: Number,
        },
        sortBy: {
            type: Array as PropType<SortOrderDTO>
        },
        activeRowNr: {
            // Set the no. of the row that should be highlighted in the table
            // Type should match the type of the record.no field (string or number)
        }
    },

    components: {
        TableContents,
    },

    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const pageNum = ref(1)

        /**
         * Modify sort order by toggling one field. Field goes through these states: 
         *   1. Ascending
         *   2. Descending
         *   3. Unsorted
         *
         *  When toggled, it is also moved to the front of the list (to get the highest priority
         *  in the sort order). 
         *
         *  Default sort order is not defined in this component, but handled in the back-end.
         **/
        const toggleSortField = (oldSort: SortOrderDTO, toggledField: string): SortOrderDTO => {
            // Determine if this field is already present
            const oldField = oldSort.find(({sortField}) => sortField === toggledField)

            if (oldField?.direction === 'asc') {
                // Go from Ascending -> Descending
                return [
                    {
                        sortField: toggledField,
                        direction: 'desc'
                    },
                    // Remove this field from its old position, because it is added at the front
                    ...oldSort.filter(({sortField}) => sortField !== toggledField)
                ]
            } else if (oldField?.direction === 'desc'){
                // Go from Descending -> Unsorted
                return [
                    // Unsorted is represented by removing this field from the list
                    ...oldSort.filter(({sortField}) => sortField !== toggledField)
                ]
            } else {
                // Go from Unsorted -> Ascending
                return [
                    {
                        sortField: toggledField,
                        direction: 'asc'
                    },
                    ...oldSort
                ]
            }
        }

        /**
         * This component just forwards requests to change the sort order. Actually sorting the data
         * is the responsibility of the parent component. The parent should listen for events of
         * type change-sort-order, and update the props tableData and sortBy accordingly (or ignore
         * the event).
         **/
        const sortOnColumn = (column: {sortField: string}) => {
            emit('change-sort-order', toggleSortField(props.sortBy, column.sortField))
        }

        const openViewDetailsPopup = (rowNr: number) => {
            const rowId = props.tableData.find((val) => val.no === rowNr).id
            emit('open-details-popup', rowId)
        }

        const loadMoreIfRequired = () => {
            if (props.totalPages > pageNum.value) {
                pageNum.value = pageNum.value + 1
                emit('loadRecords', { pageNum: pageNum.value })
            }
        }


        return {
            t,
            language,
            utils,
            moment,
            openViewDetailsPopup,
            removeMd,
            sortOnColumn,
            loadMoreIfRequired,
        }
    },
})
