


































































































































import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import removeMd from 'remove-markdown'
import variables from '@/shared/variables'
import AssetControlAssessmentsList from '@/shared/components/ac-assessment/AssetControlAssessmentsList.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
        assetId: {
            type: Number,
        },
        controlId: {
            type: Number,
        },
        accessedFrom: {
            type: String,
        },
        controlDetails: {
            type: Object,
        },
        assetDetails: {
            type: Object,
        },
    },
    components: {
        AssetControlAssessmentsList,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labels = ref({})
        const copyOfEntityDetails = ref({})
        const levelExplanation = ref('')
        const TabviewCmp = ref(null)
        const nameInitals = ref('')
        const AssetControlAssessmentsListcmp = ref(null)

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.owner && props.entityDetails.owner.firstName) +
                ' ' +
                (props.entityDetails.owner && props.entityDetails.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            labels,
            copyOfEntityDetails,
            levelExplanation,
            TabviewCmp,
            removeMd,
            nameInitals,
            variables,
            AssetControlAssessmentsListcmp,
        }
    },
})
