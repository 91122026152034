import { render, staticRenderFns } from "./Controls.vue?vue&type=template&id=cab1a3f4&scoped=true&"
import script from "./Controls.vue?vue&type=script&lang=ts&"
export * from "./Controls.vue?vue&type=script&lang=ts&"
import style0 from "./Controls.vue?vue&type=style&index=0&id=cab1a3f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cab1a3f4",
  null
  
)

export default component.exports