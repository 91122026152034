import moment from "moment";
import { FrequencyUnit } from "@/shared/enum/general-enum";

/**
 * Add an interval to a date.
 * 
 * Interval is given as a FrequencyUnit unit and an amount.
 */
export const addIntervalToDate = (input: Date, intervalUnit: FrequencyUnit, amount: number): Date => {
    const intervalParameters: {
        [key: string]: {
            unit: moment.unitOfTime.DurationConstructor;
            multiplier?: number;
        };
    } = {
        [FrequencyUnit.DAILY]: {unit: 'day'},
        [FrequencyUnit.WEEKLY]: {unit: 'week'},
        [FrequencyUnit.MONTHLY]: {unit: 'month'},
        [FrequencyUnit.QUARTERLY]: {unit: 'quarter'},
        [FrequencyUnit.SEMI_ANUALLY]: {unit: 'month', multiplier: 6},
        [FrequencyUnit.ANNUALLY]: {unit: 'year'},
    }
    if (intervalUnit in intervalParameters) {
        const parameters = intervalParameters[intervalUnit]
        return moment(input).add(
            // If no multiplier is given, multiply by one
            amount * (parameters.multiplier ?? 1),
            parameters.unit
        ).toDate()
    }
    // Should throw a sentry error here.
    return input;
}

/**
 * Get date with day granularity.
 * 
 * Returns a Date with the same day as the input, but with the time
 * components ste to zero.
 */
export const dateWithDayGranularity = (input: Date): Date => {
    return new Date(input.toDateString())
}