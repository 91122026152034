























































































































































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, inject, onMounted, Ref, ref, watch, PropType} from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import controldetailsDropdownData from '@/assets/data/controls-data.json'
import { TranslateResult } from 'vue-i18n'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { NormDTO } from '@/dto/backend-response/normsDTO'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        editable: {
            type: Boolean,
        },
        superAdminEdit: {
            type: Boolean,
        },
        createdByUser: {
            type: Boolean,
        },
        normsCreatedByUser: {
            type: Array as PropType<Array<NormDTO>>,
        },
        isConnectedControl: {
            type: Boolean,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        const keyControlDropdown = ref([])
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id

        const formData = ref({
            NORM: 0,
            CATEGORY: '',
            SUB_CATEGORY: '',
            CONTROL_NUMBER: '',
            DESCRIPTION: '',
            OWNER: userId ? userId : 0,
            TOPIC: '',
            CONTROL_TYPE: [],
            KEY_CONTROL: 0,
            OBJECTUVE: '',
            CATEGORY_TEXT: '',
            SUB_CATEGORY_TEXT: '',
        })
        const errors = ref({
            OWNER: { expire: false, missingValue: false },
            NORM: { missingValue: false },
            CONTROL_NUMBER: { missingValue: false },
            TOPIC: { missingValue: false },
            DESCRIPTION: { missingValue: false },
            OBJECTUVE: { missingValue: false },
            CONTROL_TYPE: { missingValue: false },
            CATEGORY_TEXT: { alreadyExist: false, missingValue: false },
            SUB_CATEGORY_TEXT: { alreadyExist: false },
            CATEGORY: { missingValue: false },
        })
        const keyControlName: Ref<TranslateResult> = ref('')
        const selectedControlTypes = ref([])

        const labelInfomationOpNorms = ref(null)
        const labelInfomationOpCategory = ref(null)
        const labelInfomationOpSubCategory = ref(null)
        const labelInfomationOpControlNumber = ref(null)
        const labelInfomationOpDescription = ref(null)
        const labelInfomationOpControlOwner = ref(null)
        const labelInfomationOpTopic = ref(null)
        const labelInfomationOpControlType = ref(null)
        const labelInfomationOpKeyControl = ref(null)

        const labelInfomationOpObjective = ref(null)
        const ownerOptions = ref([])
        const categories = ref([])
        const subCategories = ref([])
        const controlTypes = ref([])
        // injectors
        const controlDropdowns: Ref = inject('controlDropdowns')
        const controlCategories: Ref = inject('controlCategories')
        const BEvalidationError: Ref = inject ('BEvalidationError')

        // set category and sub category dropdown options
        const setCategorySubCategoryDropdown = () => {
            categories.value = []
            subCategories.value = []
            if (controlCategories.value.categories)
                categories.value = controlCategories.value.categories
            // remove null values from categories
            categories.value = categories.value.filter((val) => val !== null)
            // add Other option manually
            categories.value.push(t('OTHER', language.value))
            if (controlCategories.value.subCategories)
                subCategories.value = controlCategories.value.subCategories
            // remove null values from sub categories
            subCategories.value = subCategories.value.filter((val) => val !== null)
            // add Other option manually
            subCategories.value.push(t('OTHER', language.value))
        }

        // display control type values in English when the norm's `isControlTypeEn` property is true (#777)
        const setControlTypeDropdownValues = (language: string) => {
            controlTypes.value = []
            const ctrTypesCopy = controlDropdowns.value.controlTypes
            // push control type name based on language
            ctrTypesCopy.map((ctrlType) => {
                controlTypes.value.push({
                    id: ctrlType.id,
                    name:
                        language === variables.LANGUAGES.ENGLISH
                            ? ctrlType.name_en
                            : ctrlType.name_nl,
                })
            })
        }

        const setDropdownValues = () => {
            // key type dropdown
            keyControlDropdown.value = []

            controldetailsDropdownData.KEY_CONTROL.map((key) => {
                keyControlDropdown.value.push({
                    id: key.ID,
                    value: t(key.VALUE, language.value),
                })
            })

            // Convert option value from string to numeric ID because that is currently the expected format in these forms
            // And add old User fields.
            ownerOptions.value = controlDropdowns.value.owner
                .filter(option => !option.inactive)
                .map(option => ({
                    ...option,
                    id: Number(option.value),
                    fname: option.label,
                    userExpired: option.inactive,
                }))

            if (
                props.entityDetails &&
                props.entityDetails.originNorm &&
                props.entityDetails.originNorm.isControlTypeEn
            )
                setControlTypeDropdownValues(variables.LANGUAGES.ENGLISH)
            else setControlTypeDropdownValues(language.value)

            if (controlCategories.value) {
                setCategorySubCategoryDropdown()
            }
        }

        // convert id values to name values
        const setFormattedDetails = () => {
            keyControlName.value = props.entityDetails.keyControl
                ? t('KEY_CONTROL_OPTION_1', language.value)
                : t('KEY_CONTROL_OPTION_2', language.value)

            const ctrlType = props.entityDetails.controlTypes

            // translate control type to english/ dutch based on the control norm (#777)
            if (
                props.entityDetails &&
                props.entityDetails.originNorm &&
                props.entityDetails.originNorm.isControlTypeEn
            )
                selectedControlTypes.value = ctrlType.map((type) => type.name_en)
            else selectedControlTypes.value = ctrlType.map((type) => type.name_nl)
        }

        // validate all fields when creating a new entity/ updating self created entity
        const validateAllFields = () => {
            // missingValue checks
            errors.value.NORM.missingValue = utils.validateFields(formData.value.NORM, 'number')

            errors.value.CONTROL_NUMBER.missingValue = utils.validateFields(
                formData.value.CONTROL_NUMBER,
                'string'
            )

            errors.value.TOPIC.missingValue = utils.validateFields(formData.value.TOPIC, 'string')
            errors.value.DESCRIPTION.missingValue = utils.validateFields(
                formData.value.DESCRIPTION,
                'string'
            )
            errors.value.OBJECTUVE.missingValue = utils.validateFields(
                formData.value.OBJECTUVE,
                'string'
            )

            errors.value.CONTROL_TYPE.missingValue = utils.validateFields(
                formData.value.CONTROL_TYPE,
                'array'
            )
            errors.value.OWNER.missingValue = utils.validateFields(formData.value.OWNER, 'number')
            errors.value.CATEGORY.missingValue = utils.validateFields(
                formData.value.CATEGORY,
                'string'
            )
            // make CATEGORY_TEXT mandatory when Other is selected as Category
            if (formData.value.CATEGORY === t('OTHER', language)) {
                errors.value.CATEGORY_TEXT.missingValue = utils.validateFields(
                    formData.value.CATEGORY_TEXT,
                    'string'
                )
            }
        }

        const validateForm = () => {
            errors.value = {
                OWNER: { expire: false, missingValue: false },
                NORM: { missingValue: false },
                CONTROL_NUMBER: { missingValue: false },
                TOPIC: { missingValue: false },
                DESCRIPTION: { missingValue: false },
                OBJECTUVE: { missingValue: false },
                CONTROL_TYPE: { missingValue: false },
                CATEGORY_TEXT: { alreadyExist: false, missingValue: false },
                SUB_CATEGORY_TEXT: { alreadyExist: false },
                CATEGORY: { missingValue: false },
            }
            // validate all fields when creating a new entity/ updating self created entity
            if (!props.entityDetails || props.createdByUser) validateAllFields()
            else {
                // if user is editing super admin fields, validate only superadmin fields
                /* This super admin and employee seperate validations had to add as some madatory data of system entered records
                 are missing in db.In order to avoid throwing validation errors while editing such data, this condition has added.
                 We can remove this once we have stable data in db. (after BE ticket #458 is completed)*/
                if (props.superAdminEdit) {
                    // check missing values

                    errors.value.CONTROL_NUMBER.missingValue = utils.validateFields(
                        formData.value.CONTROL_NUMBER,
                        'string'
                    )

                    errors.value.TOPIC.missingValue = utils.validateFields(
                        formData.value.TOPIC,
                        'string'
                    )

                    errors.value.DESCRIPTION.missingValue = utils.validateFields(
                        formData.value.DESCRIPTION,
                        'string'
                    )

                    errors.value.CONTROL_TYPE.missingValue = utils.validateFields(
                        formData.value.CONTROL_TYPE,
                        'array'
                    )
                } else {
                    // validate employee edit fields
                    errors.value.OWNER.missingValue = utils.validateFields(
                        formData.value.OWNER,
                        'number'
                    )
                }
            }
            /* check whether selected owner is expired or not.
            Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
            In this case, it will ask to select an active user
                */
            const owner = ownerOptions.value.find((employee) => employee.id === formData.value.OWNER)
            if (owner) errors.value.OWNER.expire = owner.userExpired

            // check weather the entered category(`CATEGORY_TEXT`) is already in the categories array
            if (formData.value.CATEGORY_TEXT)
                errors.value.CATEGORY_TEXT.alreadyExist = utils.checkItemAlreadyExists(
                    categories.value,
                    formData.value.CATEGORY_TEXT
                )
            // check weather entered sub category(`SUB_CATEGORY_TEXT`) is already in the subCategories array
            if (formData.value.SUB_CATEGORY_TEXT)
                errors.value.SUB_CATEGORY_TEXT.alreadyExist = utils.checkItemAlreadyExists(
                    subCategories.value,
                    formData.value.SUB_CATEGORY_TEXT
                )
        }

        // set form data
        const setFormData = () => {
            const controlDetails = props.entityDetails
            formData.value = {
                NORM: controlDetails.originNorm && controlDetails.originNorm.id,
                CATEGORY: controlDetails.category,
                SUB_CATEGORY: controlDetails.subCategory,
                CONTROL_NUMBER: controlDetails.controlNumber,
                DESCRIPTION: controlDetails.description,
                OWNER:
                    controlDetails.owner &&
                    controlDetails.owner.username !== variables.DEFAULT_USERNAME
                        ? controlDetails.owner.id
                        : userId,
                TOPIC: controlDetails.topic,
                CONTROL_TYPE: controlDetails.controlTypes
                    ? controlDetails.controlTypes.map((type) => type.id)
                    : [],
                KEY_CONTROL: controlDetails.keyControl ? 1 : 2,
                OBJECTUVE: controlDetails.objective,
                CATEGORY_TEXT: '',
                SUB_CATEGORY_TEXT: '',
            }

            setFormattedDetails()
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        const changeCategory = () => {
            formData.value.CATEGORY_TEXT = ''
            errors.value.CATEGORY_TEXT.alreadyExist = false
            errors.value.CATEGORY_TEXT.missingValue = false
        }

        const changeSubCategory = () => {
            formData.value.SUB_CATEGORY_TEXT = ''
            errors.value.SUB_CATEGORY_TEXT.alreadyExist = false
        }

        onMounted(() => {
            setDropdownValues()
            if (props.entityDetails) {
                setFormData()
                setFormattedDetails()
            }
        })
        // reset fields on cancel
        const cancelForm = () => {
            setFormData()
        }

        // clear form fields
        const clearFields = () => {
            formData.value = {
                NORM: 0,
                CATEGORY: '',
                SUB_CATEGORY: '',
                CONTROL_NUMBER: '',
                DESCRIPTION: '',
                OWNER: userId ? userId : 0,
                TOPIC: '',
                CONTROL_TYPE: [],
                KEY_CONTROL: 0,
                OBJECTUVE: '',
                CATEGORY_TEXT: '',
                SUB_CATEGORY_TEXT: '',
            }
        }

        // Fields which super admin can edit, will be view under following conditions
        const isSuperAdminViewFieldEnabled = computed(() => {
            return (
                !props.superAdminEdit &&
                !(props.createdByUser && props.editable) &&
                props.entityDetails
            )
        })

        // Fields which super admin can edit, will be editable under following conditions
        const isSuperAdminEditFieldEnabled = computed(() => {
            return (
                props.superAdminEdit ||
                (props.createdByUser && props.editable) ||
                !props.entityDetails
            )
        })

        // Fields which employee can edit, will be view under following conditions
        const isEmployeeViewFieldEnabled = computed(() => {
            return (
                !props.editable && props.entityDetails && !(props.createdByUser && props.editable)
            )
        })

        // Fields which employee can edit, will be editable under following conditions
        const isEmployeeEditFieldEnabled = computed(() => {
            return props.editable || !props.entityDetails || (props.createdByUser && props.editable)
        })

        // display norm under following conditions
        const isNormViewEnabled = computed(() => {
            return props.entityDetails && !(props.createdByUser && props.editable)
        })

        // display norm edit under following conditions
        const isNormEditEnabled = computed(() => {
            return !props.entityDetails || (props.createdByUser && props.editable)
        })

        // page will be in editable mode under following conditions
        // Todo: #398 (Differentiate displaying mandatory marks based on user roles)
        const isEditableMode = computed(() => {
            return props.editable || props.superAdminEdit || !props.entityDetails
        })

        // set control type dropdown based on selected norm
        const normChangeHandler = () => {
            const norms: Array<NormDTO> = props.normsCreatedByUser
            const selectedNorm = norms.find((norm) => norm.id === formData.value.NORM)
            if (selectedNorm && selectedNorm.isControlTypeEn)
                setControlTypeDropdownValues(variables.LANGUAGES.ENGLISH)
        }

        watch(
            () => props.entityDetails,
            () => {
                if (props.entityDetails) {
                    setDropdownValues()
                    setFormattedDetails()
                }
            },
            {
                immediate: true,
            }
        )

        // watch for language chaneges and update dropdowns
        watch(language, () => {
            setDropdownValues()
            if (props.entityDetails) {
                setFormattedDetails()
            }
        })

        watch(
            () => controlCategories.value,
            () => {
                if (controlCategories.value) {
                    setCategorySubCategoryDropdown()
                }
            }
        )

        return {
            t,
            language,
            formData,
            users,
            keyControlDropdown,
            keyControlName,
            labelInfomationOpNorms,
            labelInfomationOpCategory,
            labelInfomationOpSubCategory,
            labelInfomationOpControlNumber,
            labelInfomationOpDescription,
            labelInfomationOpControlOwner,
            labelInfomationOpTopic,
            labelInfomationOpControlType,
            labelInfomationOpKeyControl,
            labelInfomationOpObjective,
            toggleInformation,
            cancelForm,
            utils,
            variables,
            clearFields,
            ownerOptions,
            errors,
            validateForm,
            categories,
            subCategories,
            changeCategory,
            changeSubCategory,
            isSuperAdminViewFieldEnabled,
            isSuperAdminEditFieldEnabled,
            isEmployeeViewFieldEnabled,
            isEmployeeEditFieldEnabled,
            isNormViewEnabled,
            isNormEditEnabled,
            isEditableMode,
            controlDropdowns,
            controlTypes,
            normChangeHandler,
            selectedControlTypes,
            BEvalidationError
        }
    },
})
