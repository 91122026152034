











































































import { useState } from '@/shared/mixins/helpers'
import { defineComponent, onMounted, provide, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import Table from '@/shared/components/Table.vue'
import taskQueries from '@/shared/queries/taskQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import { TaskDTO } from '@/dto/backend-response/tasksDTO'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import TaskRegister from '@/components/task-register/TaskRegister.vue'

export default defineComponent({
    props: {
        controlDetails: {
            type: Object,
        },
        msrDetails: {
            type: Object,
        },
    },
    components: {
        Table,
        TaskRegister,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const taskList = ref([])
        const loading = ref(false)
        const lastRecordNo = ref(null)
        const firstRecordNo = ref(null)
        const tableCmp = ref(null)
        const { role } = useState(['role'])
        const displayTaskDetails = ref(false)
        const taskId = ref(null)
        const taskDropdownValues = ref({
            taskPriorities: [],
            taskStatuses: [],
            taskTypes: [],
            taskRepeatStrategies: [],
        })
        const taskDetailsRecordNo = ref('')
        const statusInOrder = ref([])

        // providers
        provide('taskDropdownValues', taskDropdownValues.value)

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    fieldName: 'refId',
                    style: 'min-width: 180px;',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_DESCRIPTION', language.value),
                    fieldName: 'description',
                },
                {
                    header: t('TASK_LABEL_OWNER', language.value),
                    fieldName: 'fowner',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_START_DATE', language.value),
                    fieldName: 'startDate',
                    type: 'date',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_END_DATE', language.value),
                    fieldName: 'endDate',
                    type: 'date',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_STATUS', language.value),
                    fieldName: 'taskStatus',
                    sort: true,
                },
                {
                    header: t('TASK_LABEL_EXECUTED_BY', language.value),
                    fieldName: 'fexecutedBy',
                    sort: true,
                },
            ]
        }

        // updating taskList array with additional fields and setting description content
        const toTableFormat = (tasks: Array<TaskDTO>) => {
            taskList.value = []
            tasks.map((task) => {
                let tr = {
                    no: null,
                    fowner: '',
                    taskStatus: null,
                    fexecutedBy: '',
                }
                // creating a copy of object to hold formatted and extra attributes
                tr = Object.create(task)
                tr.no = utils.padLeft('000', task.refId)
                tr.fowner = task.owner && task.owner.firstName + ' ' + task.owner.lastName
                tr.fexecutedBy =
                    task.executedBy && task.executedBy.firstName + ' ' + task.executedBy.lastName
                if (task.status)
                    tr.taskStatus =
                        language.value === variables.LANGUAGES.DUTCH
                            ? task.status.name_nl
                            : task.status.name_en
                taskList.value.push(tr)
            })
        }

        // get task list
        const getTaskList = async () => {
            loading.value = true
            const query = `
                query tasks($filters: TaskFilterInput){
                    tasks(filters: $filters){
                        items{
                            ${taskQueries.TAB_TASK_LIST}
                        }
                        status
                        error
                    }

                }
                
            `
            const variables = {
                filters: {
                    controlIds: props.controlDetails ? [props.controlDetails.id] : [],
                    msrIds: props.msrDetails ? [props.msrDetails.id] : [],
                },
            }
            let result
            try {
                result = await apolloClient.getGraphqlData(query, variables)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving tasks')
            }
            if (result) {
                const tasks = result.data.tasks.items
                // sort by executionDate (newest to old)
                // tasks.sort((a, b) => (a.refId > b.refId ? 1 : -1))
                // updating taskList with additional fields and setting description content
                toTableFormat(tasks)
            }
        }

        const getTaskDropdownValues = async () => {
            const query = `
                query{
                   taskValues{
                    ${taskQueries.TASK_DROPDOWN_VALUES}
                    error
                    status
                   } 
                } 
                `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
            } catch (err) {
                throw Error('Error while retrieving task dropdown values')
            }

            if (result.data.taskValues && result.data.taskValues.status) {
                taskDropdownValues.value.taskPriorities = result.data.taskValues?.taskPriorities
                taskDropdownValues.value.taskStatuses = result.data.taskValues?.taskStatuses
                taskDropdownValues.value.taskTypes = result.data.taskValues?.taskTypes
                taskDropdownValues.value.taskRepeatStrategies =
                    result.data.taskValues?.taskRepeatStrategies

                // store task status in a array to use in custom sort function (recieving order of the statuses from BE)
                const isLangNL = language.value === variables.LANGUAGES.DUTCH
                const status = taskDropdownValues.value.taskStatuses.map((status) =>
                    isLangNL ? status.name_nl : status.name_en
                )
                statusInOrder.value = status
            }
        }

        // go to task record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `taskList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )

                // use the id of the record to get next/previous details
                taskId.value = taskList.value.find((task) => task.no === record.no).id

                taskDetailsRecordNo.value = record.no
            }
        }

        //  display therat details sidebar
        const openTaskDetailsPopup = async (id: number) => {
            displayTaskDetails.value = true
            taskId.value = id
            taskDetailsRecordNo.value = taskList.value.find((actionplan) => actionplan.id == id).no
            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                const thirdSidebar = document.querySelector('.third-sidebar')
                if (event.key === 'Escape' && !thirdSidebar) {
                    //close assessment sidebar if it's open and actionplan sidebar(third-sidebar) is closed
                    if (displayTaskDetails.value) {
                        displayTaskDetails.value = false
                    }
                }
            })
        }

        // post action updates (update records and sort by task status after any action)
        const updateRecords = async () => {
            await getTaskList()
            tableCmp.value && tableCmp.value.ascSortByTaskStatus('taskStatus')
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            closeSidebarEsc()
            getTaskDropdownValues()
            await getTaskList()
            // default sort by  `taskStatus`
            tableCmp.value && tableCmp.value.ascSortByTaskStatus('taskStatus')
        })

        return {
            tableHeaders,
            taskList,
            openTaskDetailsPopup,
            loading,
            getTaskList,
            updateRecords,
            lastRecordNo,
            tableCmp,
            goToRecord,
            variables,
            role,
            t,
            language,
            UserRole,
            firstRecordNo,
            displayTaskDetails,
            taskId,
            taskDetailsRecordNo,
            statusInOrder,
        }
    },
})
