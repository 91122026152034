











































































import { defineComponent, onMounted, ref, Ref} from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import AssetControlAssessment from '@/components/asset-control assessments/AssetControlAssessment.vue'
import router from '@/router'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import controlQueries from '@/shared/queries/controlQueries'
import MutualAssetControlAssessmentList from '@/shared/components/ac-assessment/MutualAssetControlAssessmentList.vue'
import { UserRole } from '@/shared/enum/general-enum'
import ConfirmMessage from '@/shared/components/ConfirmMessage.vue'

export default defineComponent({
    props: {
        controlDetails: {
            type: Object,
        },
        accessedFrom: {
            type: String,
        },
    },
    components: {
        ConfirmMessage,
        AssetControlAssessment,
        MutualAssetControlAssessmentList,
    },

    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const assetsWithACassessments = ref(null)
        const displayAssetControlAssessment = ref(false)
        const controlId = ref(null)
        const assetId = ref(null)
        const assetsList = ref(null)
        const selectedAssetDetails = ref(null)
        const { role } = useState(['role'])
        const newAssessmentSourceId: Ref<number | null> = ref(null)
        const confirm = ref(null)

        // get filtered assessment data for the selected asset via control
        const filterAssessmentWithControl = () => {
            const assets = assetsList.value
            assetsWithACassessments.value = []

            const assetsCopy = assets
            if (assetsCopy && assetsCopy.length > 0)
                assetsCopy.map((asset) => {
                    // filter assessments by selected control id
                    const filteredByControl = asset.assessments.filter(
                        (val) => val.control.id === props.controlDetails.id
                    )

                    assetsWithACassessments.value.push({
                        assetId: asset.id,
                        controlId: props.controlDetails.id,
                        name: asset.name,
                        assessments: filteredByControl,
                    })
                })
        }

        // get list of assets connected to selected control
        const getAssetsList = async () => {
            const getAssetsQuery = `
                query{
                    assets(controlId: ${props.controlDetails.id}){
                        items{
                            ${controlQueries.ASSETS_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getAssetsQuery)
            } catch (err) {
                throw Error('Error while retrieving assets')
            }
            if (result) {
                // Copy array to be able to sort it in place
                const assets = [...result.data.assets.items]
                // sort by id (ascending)
                assets.sort((a, b) => (a.id > b.id ? 1 : -1))
                // updating assets array with additional fields
                assetsList.value = assets
                filterAssessmentWithControl()
            }
        }

        // call update records post assessment is update/created
        const updateRecords = async () => {
            // fetch updated assets list
            await getAssetsList()
            // fetch asset control assessments lists connected to control
            emit('refresh-assessments')
            // call post assessment update function
            emit('post-assessment-update', { no: props.controlDetails.no, type: '' })
        }

        // navigate to asset list page opening selected asset details in popup
        const gotoAsset = (assetId) => {
            router.push({
                name: 'Assets',
                params: { idfilter: assetId },
            })
        }

        const openAssetControlAssessmentPopup = (
            aId: number, // Asset ID
            cId: number, // Control ID
            existingAssessments: Array<{id: number}> // Existing assessments for asset-control relation
            ) => {
            /* controlId and assetId are required to pass here to get asset and control data to header, 
            if in case assessment is not available(create new assessment scenario) */
            controlId.value = cId
            assetId.value = aId

            // Set newAssessmentSourceId to id of last assessment or null 
            const latestAssessmentId = existingAssessments?.[0]?.id

            const createAssessmentFormLatest = () => {
                newAssessmentSourceId.value = latestAssessmentId
                displayAssetControlAssessment.value = true
            }

            const createEmptyAssessment = () => {
                newAssessmentSourceId.value = null
                displayAssetControlAssessment.value = true
            }

            if (latestAssessmentId) {
                confirm.value.confirm(
                    "CONFIRMATION_POPUP_USE_PREVIOUS_ASSESSMENT_RESULTS",
                    createAssessmentFormLatest,
                    createEmptyAssessment,
                )
            } else {
                createEmptyAssessment()
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    // close create assessment if it's opened
                    if (displayAssetControlAssessment.value) {
                        displayAssetControlAssessment.value = false
                    }
                }
            })
        }

        onMounted(() => {
            getAssetsList()
            closeSidebarEsc()
        })

        return {
            t,
            language,
            assetsWithACassessments,
            controlId,
            assetId,
            displayAssetControlAssessment,
            openAssetControlAssessmentPopup,
            gotoAsset,
            getAssetsList,
            updateRecords,
            selectedAssetDetails,
            role,
            UserRole,
            confirm,
            newAssessmentSourceId,
        }
    },
})
