






































import { defineComponent, ref } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
export default defineComponent({
    name: 'Confirmation',
    props: {
        icon: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const onYes = ref(null)
        const onNo = ref(null)
        const showDialog = ref(false)
        const message = ref('')
        const confirm = (messageKey: string, yes = () => {/* empty */}, no = () => {/* empty */}) => {
            message.value = t(messageKey, language.value)
            onYes.value = yes
            onNo.value = no
            showDialog.value = true
        }

        // Yes button event
        const successConfirmation = () => {
            showDialog.value = false
            onYes.value()
        }

        // No button event
        const closeConfirmation = () => {
            showDialog.value = false
            onNo.value()
        }

        return {
            closeConfirmation,
            successConfirmation,
            showDialog,
            confirm,
            message,
            t,
            language,
        }
    },
})
