





















import { defineComponent } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import ControlDetails from './ControlDetails.vue'

export default defineComponent({
    name: 'CreateControl',
    components: { ControlDetails },
    props: {
        controlCategories: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])

        // close create new popup
        const closeCreateNew = () => {
            emit('close-create-new')
        }

        // save create new control data
        const saveControl = (params) => {
            emit('save-control', params)
        }

        return {
            t,
            language,
            closeCreateNew,
            saveControl,
        }
    },
})
