




































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '../mixins/helpers'
import utils from '../mixins/utils'

export default defineComponent({
    props: {
        filterFields: {
            type: Array,
        },
        defaultFilters: {
            type: Object,
        },
        filterOptions: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const formData = ref({})
        const headerswithFilters = ref([])
        const filterList = ref({})
        const selectedFilters = ref([])
        const { users } = useState(['users'])

        // set filter dropdown data
        const setFilterData = (field) => {
            const options = props.filterOptions
            if (options) filterList.value[field] = options[field]
        }
        // get headers of filter fields
        const getHeaderswithFilters = () => {
            filterList.value = []
            headerswithFilters.value = props.filterFields
            headerswithFilters.value.map((val) => {
                setFilterData(val.fieldName)
            })
        }

        /* when the logged in user is not available in the filtered list, get user name to display in chipset
        This is only required when user navigating from open items */
        const getUser = (id: number) => {
            const findUser = users.value.find((user) => user.id === id)
            if (findUser) {
                return findUser.name
            }
        }

        // get value to display in chips
        const getValue = (field: string, key: string): string | null => {
            // if selected filter items are available in filter list
            if (filterList.value[field].find((val) => val.key === key)) {
                return filterList.value[field].find((val) => val.key === key).value
            } else {
                // if selected items are not available in filter list (when navigating from openitems)
                const indexOfFormData = formData.value[field] && formData.value[field].indexOf(key)
                if (indexOfFormData > -1) {
                    formData.value[field].splice(indexOfFormData, 1)
                }

                return field === 'owner' ? getUser(Number(key)) : ''
            }
        }

        // display selected filters in chips
        const setChips = () => {
            // store selected items to display in chips (selectedFilters)
            selectedFilters.value = []
            const applicable = formData.value["applicable"];
            if (!Array.isArray(applicable))
                formData.value["applicable"] = typeof applicable !== 'boolean' ? [] : [applicable];
            
            const filterData = { ...formData.value }

            for (const key in filterData) {
                if (filterData[key]) {
                    // store selected items in selectedFilters to display in chips
                    for (const index in filterData[key]) {
                        const value = key === 'applicable' 
                            ? t(filterData[key][index] === true ? 'YES' : 'NO', language)
                            : getValue(key, filterData[key][index])
                            
                            selectedFilters.value.push({
                                field: key,
                                key: filterData[key][index],
                                value,
                            })
                    }
                }

                // if the field is a Norm, store values in localStorage
                if (key === 'norm') {
                    // (`'control' + val.fieldName` will be the unique name to save in localStorage)
                    utils.ls.set('control' + key, JSON.stringify(filterData[key]))
                }
                // commented until client confirm to introduce persistent filter for all control filters
                // utils.ls.set('control' + key, JSON.stringify(formData.value[key]))
            }
            // number of chips
            emit('set-count', selectedFilters.value.length)
        }
        // apply filter (active)
        const applyFilter = () => {
            setChips()
            emit('apply-backend-filter', formData.value)
        }

        // remove selected chip item
        const removeSelectedItem = (field: string, key: string | number) => {
            const indexOfFormData = formData.value[field] && formData.value[field].indexOf(key)
            if (indexOfFormData > -1) {
                // remove selected items from `formData.value`
                formData.value[field].splice(indexOfFormData, 1)
                // remove  selected filter items from localstorage
                /* since we are saving only norm in local storage atm, this `if` condition will only applicable 
                when the field is `norm`. This will be removed later when we introduce filters for all control fields*/
                if (field === 'norm') localStorage.removeItem('control' + field)
            }
            // after items have been removed
            applyFilter()
        }

        // set default filters for lists
        const setDefaultFilters = () => {
            const defaultFilters = props.defaultFilters
            // set `formData` values(default filters)
            Object.keys(defaultFilters).forEach((key) => {
                if (key) formData.value[key] = defaultFilters[key]
            })
            // display data in chips
            setChips()
        }

        // watch for default values and set them if there is any
        watch(
            () => props.defaultFilters,
            () => {
                getHeaderswithFilters()
                if (props.defaultFilters && Object.keys(props.defaultFilters).length > 0) {
                    setDefaultFilters()
                }
            }
        )

        // watch for filter options change and update `filterLists` array
        watch(
            () => Object.assign({}, props.filterOptions),
            () => {
                if (props.filterOptions) getHeaderswithFilters()
            }
        )

        const getLabelValue = (fieldName: string, key: string | number | boolean) => {
            const value = filterList.value[fieldName].find(option => option.key === key).value || ''
            return fieldName === 'applicable' ? t(value.toUpperCase(), language) : value   
        }

        return {
            t,
            language,
            selectedFilters,
            filterList,
            headerswithFilters,
            formData,
            applyFilter,
            removeSelectedItem,
            getLabelValue
        }
    },
})
