import { render, staticRenderFns } from "./TaskRegister.vue?vue&type=template&id=1918db75&scoped=true&"
import script from "./TaskRegister.vue?vue&type=script&lang=ts&"
export * from "./TaskRegister.vue?vue&type=script&lang=ts&"
import style0 from "./TaskRegister.vue?vue&type=style&index=0&id=1918db75&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1918db75",
  null
  
)

export default component.exports