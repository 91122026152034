import { gql } from '@apollo/client/core'

// control assessment query
const CONTROL_ASSESSMENT_DETAILS = `
    id
    refId
    order
    state
    alertColor{
        color
        info
        infoKey
    }
    validationAlertColor{
        color
        info
        infoKey
    }
    opStartDate
    opEndDate
    design
    designSetDate
    designRemark
    existence
    existenceSetDate
    existenceRemark
    operationalEffectiveness
    operationalEffectivenessRemark
    overallConclusion
    overallConclusionRemark
    maturity
    implementationStatus
    maturityAmbition
    remainingRisk
    remainingRiskAccepted
    remainingRiskAcceptedRemark
    documentationUrl
    dateNextAssessment
    actionplanRequired
    actionplanExplanation
    assessmentExplanation
    executedBy{
        username
        firstName
        lastName
        id
    }
    executionDate
    validationBy{
        id
        username
        firstName
        lastName
    }
    validationDate
    validationRemark
    actionPlans{
        id
    }
    control{
        id
        category
        topic
        controlNumber
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
        owner{
            id
            firstName
            lastName
            username
        }
    }
    lastModified
    `
// control data get query in control assessment
const CONTROL_DETAILS = `
        id
        category
        topic
        controlNumber
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
    `

// control assessment approval query
const CONTROL_ASSESSMENT_APPROVAL_DETAILS = `
    id
    state
    validationBy{
        id
        username
        firstName
        lastName
    }
    validationDate
    validationRemark
    `
//  control assessments list
const CONTROL_ASSESSMENTS_LIST = `
        id
        order
        refId
        alertColor{
            color
            info
            infoKey
        }
        validationAlertColor{
            color
            info
            infoKey
        }
        control {
            id
            description
            category
            topic
            controlNumber
        }
        executedBy {
            username
            lastName
            firstName
        }
        dateNextAssessment
        overallConclusion
        state
        validationBy {
            username
            lastName
            firstName
        }
        validationDate
    `

const CONTROL_LIST = `
    id
    order
    alertColor{
        color
        info
        infoKey
    }
    category
    controlNumber
    description
    topic
    owner{
        id
        firstName
        lastName
        username
    }
    originNorm{
        name
    }
    assessments{
        id
        maturity
        implementationStatus
        executionDate
    }
    applicable
    `
// direct access control details from control list
const CONTROL_LIST_CONTROL_DETAILS = `
        id
        order
        alertColor{
            info
            color
            infoKey
        }
        category
        subCategory
        controlNumber
        topic
        description
        applicable
        applicableReason
        assets{
            id
            name
            description
        }
        owner{
            id
            username
            firstName
            lastName
            

        }
        created_by{
            id
            username
        }
        risks{
            id
            description
        }
        objective
        consideration
        impactIntegrity
        impactAvailability
        impactConfidentiality
        implementationGuide
        originNorm{
            id
            name
            isControlTypeEn
        }
        process
        controlTypes{
            id
            name_nl
            name_en
        }
        keyControl       
        implementationGuideAdditionalInformation
        executionType
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
        validFrom
        validThru
        controlExplanation
        documentationUrl
        executionGuide
        assessments{
            id
        }
        controlReferences{
            id
            order
            controlNumber
            originNorm {
                name
            }
            topic
        }
        cyberSecurityConcepts{
            id
            name
        }
        operationalCapabilities{
            id
            name
        }
        securityDomains{
            id
            name
        }
        templates {
            id
            file
            filename
            displayName
            documentType
        }
    `
const CLIENT_CONFIG_NORMS = `
    selectedNorms{
        id
        name
    }
    `
const RISK_LIST = `
    id
    refId
    order
    description
    fullDescription
    `

const ASSETS_LIST = `
    id
    refId
    order
    name
    description
    assessments{
        id
        control{
            id
        }
    }

`
const ASSET_CONTROL_ASSESSMENTS_LIST = `
    id
    order
    refId
    created
    asset {
        id
        refId
        order
        name
    }
    control{
        id
        controlNumber
        originNorm {
            name
        }
    }
    state
    opStartDate
    opEndDate
    design
    existence
    maturity
    operationalEffectiveness
    executedBy{
        username
        firstName
        lastName
        id
    }
    executionDate
    `

const CONTROL_ASSESSMENTS_LIST_TAB = `
    id
    order
    refId
    control {
        id
    }
    state
    opStartDate
    opEndDate
    design
    existence
    maturity
    operationalEffectiveness
    executedBy{
        username
        firstName
        lastName
        id
    }
    executionDate
`

const CONTROL_LIST_CC_DATA = `
    selectedPriorityCategories{
        id
        name
    }
    priorityCategoryActivated
    `

const CLIENT_CONFIGURATION_DETAILS = `
    selectedNorms{
        id
        name
        description
        created_by{
            username
        }
    }
    `
const FILTER_OPTIONS = `
    owner{
        id
        username
        firstName
        lastName
    }
    norm{
        id
        name
        filterActive
    }
    category
    alertColor{
        color
        info
        infoKey
    }
    maturityLevel{
        score
        refName
    }
    `

const CATEGORIES_SUBCATEGORIES = `
    categories
    subCategories
    `

const CONTROL_DROPDOWNS = gql`
query ControlDropdownValues {
    controlValues {
        controlTypes{
            id
            name_nl
            name_en
        }
        cyberSecurityConcepts{
            id
            name
        }
        operationalCapabilities{
            id
            name
        }
        securityDomains{
            id
            name
        }
    }
    allowedFieldValues {
        values {
            owner {
                value
                label
                inactive
            }
        }
    }
}
`

export default {
    CONTROL_ASSESSMENT_DETAILS,
    CONTROL_DETAILS,
    CONTROL_ASSESSMENT_APPROVAL_DETAILS,
    CONTROL_ASSESSMENTS_LIST,
    CONTROL_LIST,
    CONTROL_LIST_CONTROL_DETAILS,
    CLIENT_CONFIG_NORMS,
    RISK_LIST,
    ASSETS_LIST,
    ASSET_CONTROL_ASSESSMENTS_LIST,
    CONTROL_ASSESSMENTS_LIST_TAB,
    CLIENT_CONFIGURATION_DETAILS,
    FILTER_OPTIONS,
    CONTROL_LIST_CC_DATA,
    CATEGORIES_SUBCATEGORIES,
    CONTROL_DROPDOWNS,
}
