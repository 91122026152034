



















































































































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import router from '@/router'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import CircleCmp from '@/shared/components/CircleCmp.vue'
import riskdata from '@/assets/data/risk-assessment-data.json'
import utils from '@/shared/mixins/utils'
import riskQueries from '@/shared/queries/riskQueries'
import variables from '@/shared/variables'
import Table from '@/shared/components/Table.vue'
import { RiskDTO } from '@/dto/backend-response/risksDTO'
import { RiskAssessmentDTO } from '@/dto/backend-response/riskAssessmentsDTO'
import { ControlDTO } from '@/dto/backend-response/controlsDTO'

export default defineComponent({
    name: 'Heatmap',
    components: { CircleCmp, Table },
    props: {
        originType: {},
        id: {},
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const id = props.id
        const threats = ref([])
        const vulnerabilities = ref([])
        const risks = ref([])
        const riskScores = ref([])
        const controls = ref([])
        const netScoreOp = ref(null)
        const clientConfigData = ref(null)
        const threatTableHeaders = ref([])
        const vultableHeaders = ref([])
        const risktableHeaders = ref([])
        const controltableHeaders = ref([])
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const netScoreData = ref([])
        const active = ref(0)
        const originalThreats = ref([])
        const originalVulnerabilities = ref([])
        const originalRisks = ref([])
        const originalControls = ref([])
        const showMore = ref(true)
        const infomationOpHeatmapTitle = ref(null)

        // open information overlay panel for Heatmap title
        const toggleInformation = (event: object) => {
            infomationOpHeatmapTitle.value.toggle(event)
        }

        const getSubTitle = () => {
            switch (props.originType) {
                case 'threat': {
                    return t('THREAT', language)
                }
                case 'vulnerability': {
                    return t('VULNERABILITY', language)
                }
                case 'risk': {
                    return t('RISK', language)
                }
                case 'control': {
                    return t('CONTROL', language)
                }
            }
        }

        // set selected risk data
        const toggleNetScore = (event: MouseEvent, risks: Array<RiskDTO>) => {
            // to display in overlay panel
            netScoreData.value = risks
            netScoreOp.value.toggle(event)
            utils.findMousePointerLocationOP(event)
        }

        const queryByThreat = `
            query{
              threat(id: ${id}) {
                id
                order
                refId
                description
                risks {
                  id
                  order
                  refId
                  assessments {
                    id
                    netChance
                    netImpact
                    executionDate
                    }
                  controls {
                    id
                    order
                    refId
                    description
                    category
                    controlNumber
                    topic
                    assessments {
                      executionDate
                      maturity
                      overallConclusion
                    }
                  }
                  description
                  fullDescription
                  vulnerabilities {
                    id
                    order
                    refId
                    description
                    vulnerabilityLevel
                  }
                }
                threatLevel
              }
            }
        `

        const queryByVulnerability = `
            query{
              vulnerability(id: ${id}) {
                id
                order
                refId
                description
                vulnerabilityLevel
                risks {
                  id
                  order
                  refId
                  assessments {
                    id
                    netChance
                    netImpact
                    executionDate
                    }
                  controls {
                    id
                    order
                    refId
                    description
                    category
                    controlNumber
                    topic
                    assessments {
                      executionDate
                      maturity
                      overallConclusion
                    }
                  }
                  description
                  fullDescription
                  threats {
                    id
                    order
                    refId
                    description
                    threatLevel
                  }
                }

              }
            }
        `
        const queryByRisk = `
            query{
              risk(id: ${id}) {
                id
                order
                refId
                assessments {
                  id
                  netChance
                  netImpact
                  executionDate
                }
                controls {
                  id
                  order
                  refId
                  description
                    category
                    controlNumber
                    topic
                  assessments {
                    executionDate
                    maturity
                    overallConclusion
                  }
                }
                description
                fullDescription
                threats {
                  id
                  order
                  refId
                  description
                  threatLevel
                }
                vulnerabilities {
                  id
                  order
                  refId
                  description
                  vulnerabilityLevel
                }
              }
            }
            `

        const queryByControl = `
            query{
              control(id: ${id}) {
                id
                order
                refId
                description
                category
                controlNumber
                topic
                assessments {
                  executionDate
                  maturity
                  overallConclusion
                }    
                risks {
                  id
                  order
                  refId
                  assessments {
                    id
                    netChance
                    netImpact
                    executionDate
                  }
                  description
                  fullDescription
                  threats {
                    id
                    refId
                    order
                    description
                    threatLevel
                  }
                  vulnerabilities {
                    id
                    order
                    refId
                    description
                    vulnerabilityLevel
                  }      
                }
              }
            }
            `
        // get client config tolerance data
        const getClientConfig = async () => {
            const clientConfig = `
                query{
                    organisationconfig(orgId:${organisationId}){
                        ${riskQueries.CLIENT_CONFIGURATION_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(clientConfig)
            } catch {
                throw Error('Error while retrieving client config data')
            }
            if (result) {
                const clientConfigResult = result.data.organisationconfig
                clientConfigData.value = clientConfigResult
            }
        }

        // display all records in the list
        const setShowMore = (entity: string) => {
            // set each entity array to original array
            switch (entity) {
                case 'threat':
                    threats.value = originalThreats.value
                    break
                case 'vulnerability':
                    vulnerabilities.value = originalVulnerabilities.value
                    break
                case 'risk':
                    risks.value = originalRisks.value
                    break
                case 'control':
                    controls.value = originalControls.value
                    break
                default:
                    'Entity not found'
                    break
            }
        }

        // display first 3 records in the list
        const setShowLess = (entity = '') => {
            // splice each entity array from 0 to 3 (3 records)
            switch (entity) {
                case 'threat':
                    threats.value = threats.value.slice(0, 3)
                    break
                case 'vulnerability':
                    vulnerabilities.value = vulnerabilities.value.slice(0, 3)
                    break
                case 'risk':
                    risks.value = risks.value.slice(0, 3)
                    break
                case 'control':
                    controls.value = controls.value.slice(0, 3)
                    break
                default:
                    // initially limiting to 3 records
                    threats.value = threats.value.slice(0, 3)
                    vulnerabilities.value = vulnerabilities.value.slice(0, 3)
                    risks.value = risks.value.slice(0, 3)
                    controls.value = controls.value.slice(0, 3)
            }
        }

        // remove duplicates from the list and sort by id
        const sortAndRemoveDuplicates = (list) => {
            const uniqueList = list.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
            const sortedList = uniqueList.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
            return sortedList
        }
        // updating threats array with additional fields
        const toThreatTableFormat = (tempResultData) => {
            threats.value = []
            tempResultData.map((threat) => {
                let temp = {
                    no: '',
                }
                // creating a copy of threat object
                temp = Object.create(threat)
                // creating no
                temp.no = utils.padLeft('000', threat.order)
                threats.value.push(temp)
            })
        }

        // updating vulnerabilities array with additional fields
        const toVulTableFormat = (tempResultData) => {
            vulnerabilities.value = []
            tempResultData.map((vulnerability) => {
                let temp = {
                    no: '',
                }
                // creating a copy of vulnerability object
                temp = Object.create(vulnerability)
                // creating no
                temp.no = utils.padLeft('000', vulnerability.order)
                vulnerabilities.value.push(temp)
            })
        }

        // Add fields to risk response data for use in the table
        const toRiskTableFormat = (riskResponse: Array<RiskDTO>) => {
            const assessmentsNetRisk = (assessments: Array<RiskAssessmentDTO>): {
                netRisk: number | '-'; 
                netRiskStyleClass: string;
                latestAssessment: RiskAssessmentDTO | null;
            } =>  {
                if (assessments.length) {
                    // There are assessments, compute the net risk from the first assessment
                    // TODO: Check if this is always the right assessment, in which order are they
                    // returned from the back-end?
                    const latestAssessment = assessments[0];
                    const netRisk = latestAssessment.netChance * latestAssessment.netImpact;
                    const netRiskStyleClass = utils.netRiskStyleClass(netRisk, clientConfigData.value);
                    return {
                        netRisk,
                        netRiskStyleClass,
                        latestAssessment
                    }
                }
                // No assessments, so return the default values
                return {
                    netRisk: '-',
                    netRiskStyleClass: utils.netRiskStyleClass(0, clientConfigData.value),
                    latestAssessment: null
                }
            }

            // Sort the risk assessments by execution date
            const riskData = riskResponse.map((risk) => ({
                    ...risk,
                    assessments: utils.sortedRiskAssessments(risk.assessments)
                })
            )

            // Extend the risk response data with assessment info and risk number
            risks.value = riskData.map((risk) => ({
                    no: utils.padLeft('000', risk.order),
                    ...risk,
                    ...assessmentsNetRisk(risk.assessments)
                })
            )
        }

        const getHeatmapCellRisks = (netChance: number, netImpact: number): Array<RiskDTO> => {
            return originalRisks.value.filter(risk => (
                risk.latestAssessment?.netChance === netChance 
                && risk.latestAssessment?.netImpact === netImpact)
            )
        }

        // updating controls array with additional fields
        const controltoTableFormat = (controlsData: Array<ControlDTO>) => {
            controls.value = controlsData.map(
                (control) => ({
                    ...control,
                    controlNumber: control.controlNumber.replace(/\s+/g, ''),
                    no: utils.padLeft('000', control.order),
                    id: control.id,
                    maturity: utils.getActiveAssessment(control)?.maturity ?? '-'
                })
            )
        }

        const getData = async () => {
            threats.value = []
            vulnerabilities.value = []
            risks.value = []
            controls.value = []
            await getClientConfig()
            if (props.originType === 'threat') {
                let result
                try {
                    result = await apolloClient.getGraphqlData(queryByThreat)
                } catch {
                    throw Error('Error while retrieving threats')
                }

                if (result) {
                    toThreatTableFormat([result.data.threat])
                    const risks = result.data.threat.risks
                    toRiskTableFormat(risks)
                    const vulnerabilities = []
                    const controls = []
                    for (const risk of risks) {
                        vulnerabilities.push(...risk.vulnerabilities)
                        controls.push(...risk.controls)
                    }
                    toVulTableFormat(vulnerabilities)
                    controltoTableFormat(controls)
                }
            } else if (props.originType === 'vulnerability') {
                let result
                try {
                    result = await apolloClient.getGraphqlData(queryByVulnerability)
                } catch {
                    throw Error('Error while retrieving vulnerability')
                }
                if (result) {
                    toVulTableFormat([result.data.vulnerability])
                    const risks = result.data.vulnerability.risks
                    toRiskTableFormat(risks)
                    const threats = []
                    const controls = []
                    for (const risk of risks) {
                        threats.push(...risk.threats)
                        controls.push(...risk.controls)
                    }
                    toThreatTableFormat(threats)
                    controltoTableFormat(controls)
                }
            } else if (props.originType === 'risk') {
                let result
                try {
                    result = await apolloClient.getGraphqlData(queryByRisk)
                } catch {
                    throw Error('Error while retrieving risk')
                }

                if (result) {
                    toRiskTableFormat([result.data.risk])
                    const vulnerabilities = result.data.risk.vulnerabilities
                    toVulTableFormat(vulnerabilities)
                    const threats = result.data.risk.threats
                    toThreatTableFormat(threats)
                    const controls = result.data.risk.controls
                    controltoTableFormat(controls)
                }
            } else if (props.originType === 'control') {
                let result
                try {
                    result = await apolloClient.getGraphqlData(queryByControl)
                } catch {
                    throw Error('Error while retrieving control')
                }

                if (result) {
                    controltoTableFormat([result.data.control])
                    const risks = result.data.control.risks
                    toRiskTableFormat(risks)
                    const threats = []
                    const vulnerabilities = []
                    for (const risk of result.data.control.risks) {
                        vulnerabilities.push(...risk.vulnerabilities)
                        threats.push(...risk.threats)
                    }
                    toVulTableFormat(vulnerabilities)
                    toThreatTableFormat(threats)
                }
            }

            controls.value =
                controls.value.length !== 0 ? sortAndRemoveDuplicates(controls.value) : []
            risks.value = risks.value.length !== 0 ? sortAndRemoveDuplicates(risks.value) : []
            threats.value = threats.value.length !== 0 ? sortAndRemoveDuplicates(threats.value) : []
            vulnerabilities.value =
                vulnerabilities.value.length !== 0
                    ? sortAndRemoveDuplicates(vulnerabilities.value)
                    : []

            for (const risk of risks.value) {
                if (risk.latestAssessment) {
                    riskScores.value.push(`${risk.latestAssessment.netChance}${risk.latestAssessment.netImpact}`)
                }
            }
            // store original list
            originalThreats.value = threats.value
            originalVulnerabilities.value = vulnerabilities.value
            originalRisks.value = risks.value
            originalControls.value = controls.value

            // update lists to receive 3 records initially
            setShowLess()
        }

        //  return colors based on client config risk rolerence level
        const getColor = (netChance: number, netImpact: number): string => {
            const riskScore = netChance * netImpact
            if (
                clientConfigData.value &&
                clientConfigData.value.riskToleranceGreen &&
                clientConfigData.value.riskToleranceOrange &&
                clientConfigData.value.riskToleranceRed
            ) {
                if (riskScore <= clientConfigData.value.riskToleranceGreen) {
                    return 'green'
                } else if (riskScore <= clientConfigData.value.riskToleranceOrange) {
                    return 'orange'
                } else if (riskScore <= clientConfigData.value.riskToleranceRed) {
                    return 'red'
                }
            } else {
                // default values
                if (riskScore <= 9) {
                    return 'green'
                } else if (riskScore <= 12) {
                    return 'orange'
                } else if (riskScore <= 25) {
                    return 'red'
                }
            }
        }

        // set table headers
        const setTableHeaders = () => {
            // threat table headers
            threatTableHeaders.value = [
                {
                    header: t('THREATS_TABLE_COLUMN_NO', language.value),
                    sort: true,
                    fieldName: 'refId',
                    style: 'width: 150px;',
                    bodyStyle: 'white-space: nowrap;',
                },
                {
                    header: t('THREATS_TABLE_COLUMN_DESCRIPTION', language.value),
                    sort: true,
                    fieldName: 'description',
                },
                {
                    header: t('THREATS_TABLE_COLUMN_IMPACT_THREAT_LEVEL', language.value),
                    sort: true,
                    fieldName: 'threatLevel', // required for sorting
                    level: true,
                    levelType: 'threatLevel',
                    style: 'min-width: 300px; width: 30%;',
                },
            ]

            // vulnerability table headers
            vultableHeaders.value = [
                {
                    header: t('VULNERABILITIES_TABLE_COLUMN_NO', language),
                    sort: true,
                    fieldName: 'refId',
                    style: 'width: 150px;',
                    bodyStyle: 'white-space: nowrap;',
                },
                {
                    header: t('VULNERABILITIES_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'description',
                },
                {
                    header: t('VULNERABILITIES_TABLE_COLUMN_VULNERABILITY_LEVEL', language),
                    sort: true,
                    fieldName: 'vulnerabilityLevel',
                    level: true,
                    levelType: 'vulnerabilityLevel',
                    style: 'min-width: 300px; width: 30%;',
                },
            ]

            // control table headers
            controltableHeaders.value = [
                {
                    header: t('CONTROLS_TABLE_COLUMN_NO', language),
                    sort: true,
                    fieldName: 'controlNumber',
                    style: 'width: 150px;',
                    bodyStyle: 'white-space: nowrap;',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_CATEGORY', language),
                    fieldName: 'category',
                    sort: true,
                    style: 'width: 25%;',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    limit: 250,
                    fieldName: 'topic',
                },
                {
                    header: t('CONTROLS_TABLE_MATURITY_LEVEL', language),
                    fieldName: 'maturity',
                    sort: true,
                    sortField: 'maturity',
                    style: 'width: 300px',
                },
            ]
        }
        // since tab is pointed to risk tab by default, this should render imediately when the component creates.
        const setRiskTableHeaders = () => {
            // risk table headers
            risktableHeaders.value = [
                {
                    header: t('RISKS_TABLE_COLUMN_NO', language),
                    sort: true,
                    fieldName: 'refId',
                    style: 'width: 150px;',
                    bodyStyle: 'white-space: nowrap;',
                },
                {
                    header: t('RISKS_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'description',
                    style: 'width: 25%;',
                },
                {
                    header: t('RISKS_TABLE_COLUMN_FULL_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'fullDescription',
                },
                {
                    header: t('RISKS_TABLE_COLUMN_NET_RISK', language),
                    sort: true,
                    fieldName: 'netRisk',
                    bodyStyle: 'text-align: center',
                    style: 'width: 300px;',
                },
            ]
        }

        // navigate to vulnerability and open details popup
        const openVulnerabilityDetailsPopup = async (id) => {
            if (props.originType !== 'vulnerability') {
                router.push({
                    name: 'Vulnerabilities',
                    params: { idfilter: id },
                })
            }
        }

        // navigate to threats and open details popup
        const openThreatDetailsPopup = async (id) => {
            if (props.originType !== 'threat') {
                router.push({
                    name: 'Threats',
                    params: { idfilter: id },
                })
            }
        }

        // navigate to risks and open details popup
        const openRiskDetailsPopup = async (id) => {
            if (props.originType !== 'risk') {
                router.push({
                    name: 'Risks',
                    query: { idfilter: id },
                })
            } else {
                // active details tab
                emit('active-tab-index')
            }
        }

        // navigate to controls and open details popup
        const openControlDetailsPopup = async (id) => {
            if (props.originType !== 'control') {
                router.push({ name: 'Controls', query: { idfilter: id } })
            }
        }

        onMounted(async () => {
            // Get data
            await getData()
            setTableHeaders()
            active.value = 2
        })

        // watch for language change from store and update table headers
        watch(
            language,
            () => {
                setRiskTableHeaders()
            },
            {
                immediate: true,
            }
        )

        return {
            language,
            t,
            threats,
            vulnerabilities,
            risks,
            riskScores,
            controls,
            getSubTitle,
            netScoreOp,
            toggleNetScore,
            riskdata,
            clientConfigData,
            utils,
            getColor,
            netScoreData,
            threatTableHeaders,
            vultableHeaders,
            risktableHeaders,
            controltableHeaders,
            active,
            openVulnerabilityDetailsPopup,
            openThreatDetailsPopup,
            openControlDetailsPopup,
            openRiskDetailsPopup,
            originalThreats,
            originalVulnerabilities,
            originalRisks,
            originalControls,
            showMore,
            setShowMore,
            setShowLess,
            infomationOpHeatmapTitle,
            toggleInformation,
            getHeatmapCellRisks
        }
    },
})
