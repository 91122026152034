




































import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import { UserRole } from '@/shared/enum/general-enum'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import Table from '@/shared/components/Table.vue'
import controlQueries from '@/shared/queries/controlQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import ConnectedControlDetails from './ConnectedControlDetails.vue'
import { ControlDTO } from '@/dto/backend-response/controlsDTO'

export default defineComponent({
    props: {
        controlRefList: {
            type: Array as () => Array<ControlDTO>,
        },
    },
    components: {
        Table,
        ConnectedControlDetails,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const visibleControlDetails = ref(false)
        const tableCmp = ref(null)
        const controlDetails = ref(null)
        const controlList = ref([])
        const loading = ref(false)

        // format control reference list fields
        const toTableFormat = (controls: Array<ControlDTO>) => {
            controls.map((control) => {
                let ctrl = {
                    no: 0,
                    controlNumber: control.controlNumber.replace(/\s+/g, ''),
                    norm: '',
                }
                // creating a copy of control object
                ctrl = Object.create(control)
                ctrl.no = utils.padLeft('000', control.order)
                ctrl.norm = control.originNorm && control.originNorm.name
                controlList.value.push(ctrl)
            })
        }

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('CONTROLS_TABLE_COLUMN_NORM', language.value),
                    sort: true,
                    style: 'min-width: 200px;',
                    fieldName: 'norm',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_NO', language.value),
                    sort: true,
                    fieldName: 'controlNumber',
                    style: 'width: 200px;',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_TOPIC', language.value),
                    sort: true,
                    limit: 250,
                    fieldName: 'topic',
                },
            ]
        }

        // format control details
        const getFormatedControlDetails = () => {
            // set alert color and info
            controlDetails.value.color = utils.getAlertInfo('', controlDetails.value).color
            controlDetails.value.info = utils.getAlertInfo('', controlDetails.value).info
            controlDetails.value.infoKey = utils.getAlertInfo('', controlDetails.value).infoKey

            controlDetails.value.no = utils.padLeft('000', controlDetails.value.order)
            controlDetails.value.lastRecordNo =
                tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
            controlDetails.value.firstRecordNo =
                tableCmp.value && tableCmp.value.copyOfTableData[0].no
        }

        // get control details for id
        const getControl = async (controlId: number) => {
            controlDetails.value = null
            const getControlDetailQuery = `
                query{
                    control(id:${controlId}){
                        ${controlQueries.CONTROL_LIST_CONTROL_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getControlDetailQuery)
            } catch (err) {
                throw Error('Error while retrieving control details')
            }

            if (result) {
                const controlsDetailsResult = result.data.control
                controlDetails.value = controlsDetailsResult
                getFormatedControlDetails()
            }
        }

        // go to control record
        const goToRecord = async (params: { type: string; no: string }) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `controlList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // use the id of the record to get next/previous details
                await getControl(record.id)
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    const thirdSidebar = document.querySelector('.third-sidebar')
                    //close control details sidebar if it's open and there is no third sidebar
                    if (visibleControlDetails.value && !thirdSidebar) {
                        visibleControlDetails.value = false
                    }
                    //close assessment sidebar if it's open
                    if (visibleControlDetails.value) {
                        visibleControlDetails.value = false
                    }
                }
            })
        }

        // open selected control details popup
        const openControlDetailsPopup = async (id: number) => {
            visibleControlDetails.value = true
            getControl(id)
        }

        onMounted(() => {
            closeSidebarEsc()
            setTableHeaders()
        })

        watch(
            () => props.controlRefList,
            () => {
                if (props.controlRefList) {
                    toTableFormat(props.controlRefList)
                }
            },
            {
                immediate: true,
            }
        )

        return {
            t,
            language,
            UserRole,
            tableHeaders,
            visibleControlDetails,
            openControlDetailsPopup,
            tableCmp,
            controlDetails,
            goToRecord,
            controlList,
            loading,
        }
    },
})
