






















































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, onMounted, ref, watch, Ref, inject } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import ControlSection from '@/components/controls/ControlSection.vue'
import ControlAssessmentSection from '@/shared/components/assessments/AssessmentSection.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import ControlAssessmentApproval from '@/shared/components/assessments/AssessmentApproval.vue'
import utils from '@/shared/mixins/utils'
import controlQueries from '@/shared/queries/controlQueries'
import { Status, UserRole } from '@/shared/enum/general-enum'
import generalData from '@/assets/data/general-data.json'
import variables from '@/shared/variables'
import Confirmation from '@/shared/components/Confirmation.vue'
import ActionPlan from '@/components/actionplan/ActionPlan.vue'
import { Effectiveness } from '@/shared/enum/asset-control-assessment-enum'
import { Route } from 'vue-router'
import type { ControlAssessmentDTO } from '@/dto/backend-response/controlAssessmentsDTO'

const fetchControlAssessment = async (
        id: number
    ): Promise<ControlAssessmentDTO> => {
        const getControlDetailQuery = `
            query{
                controlAssessment(id:${id})
                {
                    ${controlQueries.CONTROL_ASSESSMENT_DETAILS}
                }
            }
        `
        return apolloClient.getGraphqlData(
            getControlDetailQuery
        ).then(
            response => response.data.controlAssessment
        )
    }

export default defineComponent({
    components: {
        ControlSection,
        ControlAssessmentSection,
        ControlAssessmentApproval,
        Confirmation,
        ActionPlan,
    },
    props: {
        controlId: {
            type: Number,
        },
        // Make previous,next buttons available if the component is accessed via a list
        accessedFrom: {
            type: String,
        },
        accessType: {
            type: String,
        },
        //TODO: #803
        lastRecordNo: {},
        //TODO: #803
        recordNo: {},
        controlList: {
            type: Array,
        },
        controlAssessmentId: {
            type: Number,
        },
        //TODO: #803
        firstRecordNo: {},
        newAssessmentSourceId: {
            type: Number,
            default: null,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const ControlAssessmentSection = ref(null)
        const control = ref([])
        // eslint-disable-next-line
        const controlDetails: any = ref(null)
        // eslint-disable-next-line
        const controlAssessmentDetails: any = ref(null)
        // The assessment that is used as a source for pre-filling new assessment
        const newControlAssessmentSource: Ref<ControlAssessmentDTO> = ref(null)

        const editable = ref(false)
        const toast = useToast()
        const enableApproval = ref(false)
        const controlAssessmentApproval = ref(null)
        const approvalContent = ref(null)
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const displayApproveConfirmation = ref(false)
        const displaydeclinedConfirmation = ref(false)
        const displaySubmitConfirmation = ref(false)
        const nameInitals = ref('')
        const displayActionplan = ref(false)
        const disable = ref(false)
        const originComponents = ref({
            controlAssessment: 'ControlAssessments',
            controlList: 'Controls',
        })
        const routedFrom = ref('')
        const loading = ref(false)
        const orderNo = ref(null)
        const controlAssessmentInfobox = ref('')
        const displayDeleteConfirmation = ref(false)

        const VIEW_VALIDATION_MODE: Ref<boolean> = inject('VIEW_VALIDATION_MODE', ref(false))

        const goBackToControlList = () => {
            router.back()
        }

        // button handle logically
        const buttonEnable = () => {
            if (controlAssessmentDetails.value) {
                editable.value = false
            } else {
                editable.value = true
            }
        }

        // get info box text based on navigated menu option
        const getControlAssessmentInfoBoxText = (route: Route) => {
            controlAssessmentInfobox.value = utils.getInfoTextTranslationKeyFor(
                'control-assessment', 
                route.name
            )
        }

        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.recordNo, type })
        }

        // set assessment configurations based on response data
        const setAssessmentConfiguration = () => {
            // disable approval section to avoid approving their own action plan
            const username =
                controlAssessmentDetails.value.executedBy &&
                controlAssessmentDetails.value.executedBy.username
            disable.value = utils.disableApprovalSection(username)

            // set name with initials to display in avatar
            const fullNameOwner =
                (controlAssessmentDetails.value.executedBy &&
                    controlAssessmentDetails.value.executedBy.firstName) +
                ' ' +
                (controlAssessmentDetails.value.executedBy &&
                    controlAssessmentDetails.value.executedBy.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)

            const alertsMenuType: string = VIEW_VALIDATION_MODE.value ? 'menu-validation' : 'not-validation-mode'
            controlAssessmentDetails.value.color = utils.getAlertInfo(
                alertsMenuType,
                controlAssessmentDetails.value
            ).color
            controlAssessmentDetails.value.info = utils.getAlertInfo(
                alertsMenuType,
                controlAssessmentDetails.value
            ).info
            controlAssessmentDetails.value.infoKey = utils.getAlertInfo(
                alertsMenuType,
                controlAssessmentDetails.value
            ).infoKey
        }

        // get control details for the selected control
        const getControlAssessmentDetails = async () => {
            approvalContent.value = null
            controlDetails.value = null
            enableApproval.value = false
            loading.value = true

            const assessmentId = props.controlAssessmentId ?? controlAssessmentDetails.value?.id
            fetchControlAssessment(assessmentId)
                .then(
                    async (assessment) => {
                        controlAssessmentDetails.value = assessment
                        // set order no
                        orderNo.value = utils.padLeft('000', assessment.order)
                        // set control details
                        controlDetails.value = assessment.control

                        buttonEnable()
                        if (controlAssessmentDetails.value) {
                            setAssessmentConfiguration()
                        }
                        // check for approval content available
                        if (
                            controlAssessmentDetails.value &&
                            controlAssessmentDetails.value.validationBy &&
                            controlAssessmentDetails.value.validationBy.id
                        ) {
                            // set approval content
                            approvalContent.value = {
                                validationBy: controlAssessmentDetails.value.validationBy,
                                validationDate: controlAssessmentDetails.value.validationDate,
                                validationRemark: controlAssessmentDetails.value.validationRemark,
                            }
                        }

                        // empty approval content when assessment state is in submit state or in progress state
                        if (
                            controlAssessmentDetails.value &&
                            (controlAssessmentDetails.value.state === Status.submitted ||
                                controlAssessmentDetails.value.state === Status.inprogress)
                        ) {
                            approvalContent.value = null
                        }
                    }
                )
                .catch(
                    async () => {
                        throw Error('Error while retrieving control assessment details')
                    }
                )
                .finally(
                    () => loading.value = false
                )
        }

        const reuseControlAssessment = async (id: number) => {
            approvalContent.value = null
            controlDetails.value = null
            enableApproval.value = false
            loading.value = true
            fetchControlAssessment(id)
                .then(
                    (assessment) => {
                        newControlAssessmentSource.value = assessment
                        editable.value = true
                        controlDetails.value = assessment.control

                    }
                )
                .catch(() => {
                    throw Error("Error while retrieving reused control assessment")
                })
                .finally(() => loading.value = false)
        }

        // update control assessment state
        const updateState = async (status: number): Promise<string> => {
            const inputStatus = {
                controlAssessmentState: status,
            }

            const returnQuery = `controlassessment{
                ${controlQueries.CONTROL_ASSESSMENT_DETAILS}
            }`
            let resultOfStatus
            try {
                resultOfStatus = await utils.updateDocumentState(
                    controlAssessmentDetails.value.id,
                    returnQuery,
                    inputStatus
                )
            } catch (err) {
                return 'error'
            }

            if (resultOfStatus) {
                controlAssessmentDetails.value = resultOfStatus.controlassessment
                setAssessmentConfiguration()
                emit('post-assessment-update')
                return 'success'
            } else return 'error'
        }

        // cancel approval
        const cancelApproval = () => {
            controlAssessmentApproval.value && controlAssessmentApproval.value.cancelForm()
            enableApproval.value = false
            emit('close-assessment')
        }

        //  save control assessment
        const saveControlAssessment = async () => {
            ControlAssessmentSection.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(ControlAssessmentSection.value.errors)) {
                return
            }

            const controlId = controlAssessmentDetails.value
                ? controlAssessmentDetails.value.control.id
                : Number(props.controlId)
            const controlAssessmentForm = ControlAssessmentSection.value.assessmentForm

            //  if design or existence or op selected as `not tested` date fields are null
            if (controlAssessmentForm.DESIGN === Effectiveness.NOT_TESTED) {
                controlAssessmentForm.DESIGN_DATE = null
            }

            if (controlAssessmentForm.EXISTENCE === Effectiveness.NOT_TESTED) {
                controlAssessmentForm.EXISTENCE_DATE = null
            }

            if (controlAssessmentForm.OPERATIONAL_EFFECTIVENESS === Effectiveness.NOT_TESTED) {
                controlAssessmentForm.OP_START_DATE = null
                controlAssessmentForm.OP_END_DATE = null
            }

            const controlAssessmentData = {
                control: controlId,
                opStartDate: controlAssessmentForm.OP_START_DATE,
                opEndDate: controlAssessmentForm.OP_END_DATE,
                executedBy: controlAssessmentForm.EXECUTED_BY,
                design: controlAssessmentForm.DESIGN,
                designSetDate: controlAssessmentForm.DESIGN_DATE,
                designRemark: controlAssessmentForm.DESIGN_REMARK,
                existence: controlAssessmentForm.EXISTENCE,
                existenceSetDate: controlAssessmentForm.EXISTENCE_DATE,
                existenceRemark: controlAssessmentForm.EXISTENCE_REMARK,
                operationalEffectiveness: controlAssessmentForm.OPERATIONAL_EFFECTIVENESS,
                operationalEffectivenessRemark:
                    controlAssessmentForm.OPERATIONAL_EFFECTIVENESS_REMARK,
                overallConclusion:
                    controlAssessmentForm.OVERALL === Effectiveness.EFFECTIVE ? true : false,
                overallConclusionRemark: controlAssessmentForm.OVERALL_CONCLUSION_REMARK,
                maturity: controlAssessmentForm.MATURITY,
                implementationStatus: controlAssessmentForm.IMPLEMENTATION_STATUS,
                maturityAmbition: controlAssessmentForm.MATURITY_AMBITION,
                remainingRiskAccepted:
                    controlAssessmentForm.REMAINING_RISK_ACCEPTED === 1 ? true : false,
                remainingRiskAcceptedRemark: controlAssessmentForm.REMAINING_RISK_ACCEPTED_REMARK,
                documentationUrl: controlAssessmentForm.DOCUMENTATION_URL,
                dateNextAssessment: controlAssessmentForm.DATE_NEXT_ASSESSMENT,
                actionplanRequired: controlAssessmentForm.ACTIONPLAN_REQUIRED === 1 ? true : false,
                actionplanExplanation: controlAssessmentForm.ACTIONPLAN_REMARK,
                executionDate: controlAssessmentForm.EXECUTION_DATE,
                assessmentExplanation: controlAssessmentForm.ASSESSMENT_EXPLANATION,
            }

            // mutation query
            let mutationQuery
            // create
            if (!controlAssessmentDetails.value) {
                mutationQuery = `mutation ($input: CreateUpdateControlAssessmentInput!) {
                createControlAssessment(input: $input) {
                        assessment{
                            ${controlQueries.CONTROL_ASSESSMENT_DETAILS}
                        }
                            status
                            error
                }
              }`
            } else {
                // update
                mutationQuery = `mutation ($input: CreateUpdateControlAssessmentInput!) {
                    updateControlAssessment(id:${controlAssessmentDetails.value.id}, input: $input) {
                            status
                            error
                }
              }`
            }

            const input = controlAssessmentData
            let result
            try {
                // update data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('CONTROL_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving control assessment data')
            }
            // create new
            if (result.data.createControlAssessment && result.data.createControlAssessment.status) {
                controlAssessmentDetails.value = result.data.createControlAssessment.assessment

                // 2- in progress
                const status = await updateState(Status.inprogress)
                buttonEnable()
                if (status === 'success') {
                    toast.success(t('CONTROL_ASSESSMENT_SAVED_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('CONTROL_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
                }
            } else if (
                result.data.updateControlAssessment &&
                result.data.updateControlAssessment.status
            ) {
                //update
                // when resubmitting declined assessment, resetting approval section content and fields
                approvalContent.value = null
                // 2- in progress
                const status = await updateState(Status.inprogress)
                buttonEnable()
                if (status === 'success') {
                    toast.success(t('CONTROL_ASSESSMENT_SAVED_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('CONTROL_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
                }
            } else {
                toast.error(t('CONTROL_ASSESSMENT_SAVED_ERROR_MESSAGE', language.value))
            }
        }

        //  on click of cancel button
        const cancel = () => {
            if (editable.value && controlAssessmentDetails.value) {
                // if the document is in edit mode, it will exit back to view
                editable.value = false
            } else {
                // if you are in create new form, form fields will be clear
                ControlAssessmentSection.value.cancelForm()
            }
            emit('close-assessment')
        }

        // control assessment approve
        const submitApprovalContent = async () => {
            const controlAssessmentApprovalForm =
                controlAssessmentApproval.value.assessmentApprovalForm

            // control assessment approval input object
            const input = {
                validationBy: controlAssessmentApprovalForm.VALIDATION_BY,
                validationDate: controlAssessmentApprovalForm.VALIDATION_DATE,
                validationRemark: controlAssessmentApprovalForm.VALIDATION_REMARK,
            }

            // mutation query

            // approved
            const mutationQuery = `mutation ($input: ControlAssessmentApprovalInput!) {
                    approveControlAssessment(id: ${controlAssessmentDetails.value.id}, input: $input) {
                        assessment{
                            ${controlQueries.CONTROL_ASSESSMENT_APPROVAL_DETAILS}
                        }

                        status
                        error
                    }   
                }`

            // approve data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                return 'error'
            }
            // check for success approval
            if (
                result.data.approveControlAssessment &&
                result.data.approveControlAssessment.status
            ) {
                const controlApproval = result.data.approveControlAssessment.assessment

                // set approval content
                approvalContent.value = {
                    validationBy: controlApproval.validationBy,
                    validationDate: controlApproval.validationDate,
                    validationRemark: controlApproval.validationRemark,
                }

                return 'submitted'
            } else {
                return 'error'
            }
        }

        // on success of approval content submition update the state of assessment as approved
        const approveAssessment = async () => {
            // control assessment
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 4- approved
                const status = await updateState(Status.approved)
                if (status === 'success') {
                    toast.success(t('CONTROL_ASSESSMENT_APPROVE_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('CONTROL_ASSESSMENT_APPROVE_ERROR_MESSAGE', language.value))
                }
            } else {
                toast.error(t('CONTROL_ASSESSMENT_APPROVE_ERROR_MESSAGE', language.value))
            }
        }

        // on success of approval content submition update the state of assessment as declined
        const declineAssessment = async () => {
            // control assessment
            const approval = await submitApprovalContent()
            if (approval === 'submitted') {
                // 5- declined
                const status = await updateState(Status.declined)
                if (status === 'success') {
                    toast.success(t('CONTROL_ASSESSMENT_DECLINE_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('CONTROL_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
                }
            } else {
                toast.error(t('CONTROL_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, Number(state))
            return t(stateVal, language.value)
        }

        // open save confirmation
        const openSaveConfirmation = () => {
            if (controlAssessmentDetails.value) {
                displaySaveConfirmation.value = true
            } else {
                saveControlAssessment()
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            // cancel for edit assessment
            if (controlAssessmentDetails.value) {
                displayCancelConfirmation.value = true
            } else if (enableApproval.value) {
                // cancel for approval section
                cancelApproval()
            } else {
                // for new create entity
                displayCancelConfirmation.value = true
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            saveControlAssessment()
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancel()
        }

        // on success submit confirm
        const successSubmitConfirmation = async () => {
            displaySubmitConfirmation.value = false
            enableApproval.value = true
            const status = await updateState(Status.submitted)
            if (status === 'success') {
                toast.success(t('CONTROL_ASSESSMENT_SUBMIT_SUCCESS_MESSAGE', language.value))
            } else {
                toast.error(t('CONTROL_ASSESSMENT_SUBMIT_ERROR_MESSAGE', language.value))
            }
        }
        // on success approve confirm
        const successApproveConfirmation = () => {
            displayApproveConfirmation.value = false
            controlAssessmentApproval.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(controlAssessmentApproval.value.errors)) {
                return
            }
            approveAssessment()
        }

        // on success decline confirm
        const successDeclinedConfirmation = () => {
            displaydeclinedConfirmation.value = false
            controlAssessmentApproval.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(controlAssessmentApproval.value.errors)) {
                return
            }
            declineAssessment()
        }

        // get control details for the control assessment
        const getControlDetails = async (controlDetailsQuery) => {
            controlDetails.value = null
            const getControlDetailQuery = `
                query{
                   control(id: ${props.controlId}){
                        ${controlDetailsQuery}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getControlDetailQuery)
            } catch {
                throw Error('Error while retrieving control details')
            }

            if (result) {
                controlDetails.value = result.data.control
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close actionplan sidebar if it's open
                    if (displayActionplan.value) {
                        displayActionplan.value = false
                    }
                }
            })
        }

        // update control assessments list and control assessment details post action plan update
        const updateRecords = (isDelete: boolean) => {
            emit('refresh-control')
            // if this function is not called post delete an assessment, get latest assessment data by `getControlAssessmentDetails()`
            if (!isDelete) getControlAssessmentDetails()
            /* if user has routed from control assessments list it should able to refresh assessments list in menu. 
            If user has routed from controls page it should only refresh the assessments in tab (to avoid closing all tabs)*/
            if (routedFrom.value === originComponents.value.controlList) {
                emit('refresh-assessment')
            } else {
                emit('get-assessments')
            }
        }

        // enable submit button based on following conditions
        const isSubmitEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                !enableApproval.value &&
                !approvalContent.value &&
                controlAssessmentDetails.value &&
                (controlAssessmentDetails.value.state === Status.inprogress ||
                    controlAssessmentDetails.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        const isUserValidator = computed(() => {
            return role.value === UserRole.VALIDATOR
        })

        // enable approval buttons based on following conditions
        const isApprovalBtnsEnabled = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                ((enableApproval.value && !editable.value) ||
                    (controlAssessmentDetails.value &&
                        controlAssessmentDetails.value.state === Status.submitted) ||
                    enableApproval.value) &&
                roles.includes(role.value) &&
                !approvalContent.value
            )
        })

        // display approval content based on following conditions
        const displayApprovalContent = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                approvalContent.value ||
                (((enableApproval.value && !editable.value) ||
                    (controlAssessmentDetails.value &&
                        controlAssessmentDetails.value.state === Status.submitted)) &&
                    roles.includes(role.value))
            )
        })

        // display edit button based on following conditions
        const isEditEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                controlAssessmentDetails.value &&
                (controlAssessmentDetails.value.state === Status.inprogress ||
                    controlAssessmentDetails.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        // display create actionplan button based on following conditions
        const isCreateAPBtnEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                controlAssessmentDetails.value &&
                controlAssessmentDetails.value.actionplanRequired &&
                roles.includes(role.value) &&
                controlAssessmentDetails.value.actionPlans &&
                controlAssessmentDetails.value.actionPlans.length === 0
            )
        })

        // display view actionplan button based on following conditions
        const isViewAPBtnEnabled = computed(() => {
            return (
                !editable.value &&
                controlAssessmentDetails.value &&
                controlAssessmentDetails.value.actionplanRequired &&
                controlAssessmentDetails.value.actionPlans &&
                controlAssessmentDetails.value.actionPlans.length > 0
            )
        })

        // delete control assessment
        const deleteControlAssessment = async () => {
            const deleteQuery = `
                    mutation{
                        deleteControlAssessment(id: ${controlAssessmentDetails.value.id}){
                            status
                            error
                        }
                    }
                    `
            // approve data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(deleteQuery, null)
            } catch (err) {
                displayDeleteConfirmation.value = false
                toast.error(t('CONTROL_ASSESSMENT_DELETE_FAIL_MESSAGE', language.value))
                throw Error('Error while deleting control assessment')
            }

            if (result.data.deleteControlAssessment.status) {
                displayDeleteConfirmation.value = false
                toast.success(t('CONTROL_ASSESSMENT_DELETE_SUCCESS_MESSAGE', language.value))
                emit('close-assessment')
                updateRecords(true)
            } else toast.error(t('CONTROL_ASSESSMENT_DELETE_FAIL_MESSAGE', language.value))
        }

        const deleteAssessment = () => {
            deleteControlAssessment()
        }

        //  watch for controlAssessmentId ID changes
        watch(
            [
                () => props.controlAssessmentId,
                () => props.newAssessmentSourceId
            ],
            ([assessmentId, newAssessmentSourceId]) => {
                if (assessmentId) {
                    getControlAssessmentDetails()
                } else if (newAssessmentSourceId) {
                    reuseControlAssessment(newAssessmentSourceId)
                } else {
                    /* since assessment is not available, get control details separately using props.controlId.
                        When the assessment is available, can fetch control details from assessment data itself. This will be only required
                        when user creates a new control assessment */
                    getControlDetails(controlQueries.CONTROL_DETAILS)
                }
            },
            { immediate: true }
        )
        
        onMounted(() => {
            closeSidebarEsc()
        })

        //  watch route path for decide which type of component we are on.
        watch(
            () => router.app.$route,
            () => {
                routedFrom.value = router.app.$route.name
                getControlAssessmentInfoBoxText(router.app.$route)
            },
            {
                immediate: true,
            }
        )

        return {
            t,
            language,
            ControlAssessmentSection,
            toast,
            control,
            controlDetails,
            controlAssessmentDetails,
            editable,
            goBackToControlList,
            getControlAssessmentDetails,
            cancel,
            saveControlAssessment,
            enableApproval,
            approveAssessment,
            controlAssessmentApproval,
            approvalContent,
            cancelApproval,
            updateState,
            infomationOp,
            toggleInformation,
            declineAssessment,
            Status,
            convertIdToNames,
            utils,
            variables,
            role,
            disable,
            openSaveConfirmation,
            openCancelConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            displayCancelConfirmation,
            displaySaveConfirmation,
            nameInitals,
            displayApproveConfirmation,
            displaydeclinedConfirmation,
            successApproveConfirmation,
            successDeclinedConfirmation,
            displaySubmitConfirmation,
            successSubmitConfirmation,
            displayActionplan,
            originComponents,
            routedFrom,
            loading,
            updateRecords,
            UserRole,
            goToRecord,
            orderNo,
            controlAssessmentInfobox,
            isSubmitEnabled,
            isApprovalBtnsEnabled,
            isEditEnabled,
            displayApprovalContent,
            isCreateAPBtnEnabled,
            isViewAPBtnEnabled,
            displayDeleteConfirmation,
            deleteAssessment,
            newControlAssessmentSource,
            VIEW_VALIDATION_MODE,
            isUserValidator
        }
    },
})
