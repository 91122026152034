import { render, staticRenderFns } from "./ControlDetailsSection.vue?vue&type=template&id=be0845ce&scoped=true&"
import script from "./ControlDetailsSection.vue?vue&type=script&lang=ts&"
export * from "./ControlDetailsSection.vue?vue&type=script&lang=ts&"
import style0 from "./ControlDetailsSection.vue?vue&type=style&index=0&id=be0845ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be0845ce",
  null
  
)

export default component.exports