




















































































































































































import {
    computed,
    defineComponent,
    onMounted,
    provide,
    Ref,
    ref,
    watch,
} from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { useActions, useState } from '@/shared/mixins/helpers'
import { ControlDTO, ControlsResponseDTO } from '@/dto/backend-response/controlsDTO'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import BackendTable from '@/shared/components/table/BackendTable.vue'
import utils from '@/shared/mixins/utils'
import controlQueries from '@/shared/queries/controlQueries'
import variables from '@/shared/variables'
import ControlOperations from '@/components/controls/ControlOperations.vue'
import { useToast } from 'vue-toastification/composition'
import CreateControl from '@/components/controls/CreateControl.vue'
import controlsData from '@/assets/data/controls-data.json'
import controlAssessmentsData from '@/assets/data/control-assessment-data.json'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import BackendTableFilters from '@/shared/components/BackendTableFilters.vue'
import { MaturityLevel } from '@/shared/enum/control-enum'
import Confirmation from '@/shared/components/Confirmation.vue'
import { ControlFormDTO } from '@/dto/forms/controlFormDTO'
import * as Sentry from '@sentry/vue'
import { SortOrderDTO } from '@/dto/sortOrderDTO'
import { exportTable } from '@/shared/services/TableExport'

const toTableFormat = (controls: Array<ControlDTO>, translate: (key: string) => string) => {
    return controls.map((control) => {
        const implementationStatus =
            utils.getActiveAssessment(control)?.implementationStatus ?? 'NO_STATUS'
        const implementationStatusTranslationKey =
            controlAssessmentsData.IMPLEMENTATION_STATUS.find(
                (option) => option.value === implementationStatus
            ).labelKey
        return {
            ...control,
            ...utils.getAlertInfo('', control),
            no: control.id,
            fowner: control.owner.firstName + ' ' + control.owner.lastName,
            id: control.id,
            norm: control.originNorm && control.originNorm.name,
            maturity: utils.getActiveAssessment(control)?.maturity ?? '-',
            implementationStatus: translate(implementationStatusTranslationKey),
        }
    })
}

const fetchControls = async (variables: {
    perPage: number;
    pageNum: number;
    controlFilters: { [filter: string]: unknown };
    isOpenItem: boolean;
    bySelectedPriorityCategories: boolean;
}): Promise<ControlsResponseDTO> => {
    const getControlQuery = `
        query controls($perPage:Int, $pageNum:Int, $controlFilters:ControlFilterOptionsInput, $isOpenItem:Boolean, $bySelectedPriorityCategories:Boolean){
            controls(perPage:$perPage, pageNum:$pageNum, controlFilters:$controlFilters, isOpenItem:$isOpenItem, bySelectedPriorityCategories:$bySelectedPriorityCategories){
                pageInfo{
                    totalPages
                    totalCount
                }
                items{
                    ${controlQueries.CONTROL_LIST}
                }
            }
        }
    `
    const result = await apolloClient.getGraphqlData(getControlQuery, variables)
    return result.data.controls
}

export default defineComponent({
    name: 'Controls',
    components: {
        BackendTable,
        CreateControl,
        ControlOperations,
        BackendTableFilters,
        Confirmation,
    },
    props: {
        idfilter: {
            type: String,
        },
        loggedInUser: {
            type: String,
        },
        category: {
            type: String,
        },
        norm: {
            type: String,
        },
        maturity: {
            type: String,
        },
        alertColor: {
            type: Array,
        },
    },
    setup(props) {
        const controlList: Ref<ReturnType<typeof toTableFormat>> = ref([])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const tableActiveRow: Ref<number> = ref(null)
        const expandedHeader: Ref<TranslateResult> = ref('')
        const loading = ref(false)
        const tableCmp = ref(null)
        const { role } = useState(['role'])
        const { users } = useState(['users'])
        const visibleControlDetails = ref(false)
        const controlDetails = ref(null)
        const toast = useToast()
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { menuOpen } = useState(['menuOpen'])
        const displayCreateControl = ref(false)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const controlFilters = ref({
            category: [],
            norm: [],
            owner: [],
            alertColor: [],
            maturity: [],
            applicable: [],
        })
        const totalPages = ref(0)
        const filterOptions = ref({
            owner: [
                {
                    value: null,
                    key: 0,
                },
            ],
            norm: [],
            category: [],
            alertColor: [],
            maturity: [],
            applicable: [],
        })
        const openItem = ref(props.loggedInUser ? true : false)
        const viewFilterSection = ref(false)
        const displayFilteredTitle = ref(false)
        const displayPriorityTooggle = ref(false)
        const controlCategories = ref({})
        const clientConfigData = ref(null)
        const controlDropdowns = ref({
            controlTypes: [],
            cyberSecurityConcepts: [],
            operationalCapabilities: [],
            securityDomains: [],
            owner: [],
        })
        const { selectedNorms } = useState(['selectedNorms'])
        const BEvalidationError = ref('')

        // providers
        provide('controlDropdowns', controlDropdowns)
        provide('controlCategories', controlCategories)
        provide('BEvalidationError', BEvalidationError)

        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        // get stored priority filter preference
        const storedDisplayPriorityFilter: boolean = utils.ls.get(
            variables.LOCAL_STORAGE_ITEMS.PRIORITY_FILTER_TOGGLE,
            { decrypt: true }
        )
        /* if the user preference for display priority filter is enabled for the current session, set it as the value of `displayPriorityFilter`. 
        if not `displayPriorityFilter` is set to true by default */
        const displayPriorityFilter: Ref<boolean> = ref(
            storedDisplayPriorityFilter !== null ? storedDisplayPriorityFilter : true
        )
        const infomationOp = ref(null)
        const displayDeleteConfirmation = ref(false)

        const tableSortOrder = ref([
            { sortField: 'norm', direction: 'asc' },
            { sortField: 'controlNumber', direction: 'asc' },
        ]) as Ref<SortOrderDTO>

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        const getControls = async (params: { pageNum: number }) => {
            const pageNum = params.pageNum
            // to enable loading from Control.vue only for the initial call as other calls will be trigger from Table.vue with initial scrolling
            loading.value = true
            // variable params for query

            const filters = utils.getFormattedControlFilters({ ...controlFilters.value })

            const variables = {
                perPage: 50,
                pageNum: params.pageNum,
                controlFilters: {
                    category: filters.category,
                    norm: filters.norm,
                    alertColor: filters.alertColor,
                    owner: filters.owner,
                    searchText: searchValue.value,
                    maturity: filters.maturity,
                    orderBy: tableSortOrder.value,
                    applicable: filters.applicable
                },
                isOpenItem: openItem.value,
                bySelectedPriorityCategories: displayPriorityFilter.value,
            }
            try {
                const result = await fetchControls(variables)
                // Copy array to be able to sort it in place
                const controls = [...result.items]
                // total pages based on perPage value
                totalPages.value = result.pageInfo.totalPages
                // display totale records count as filteredRecordCount. This could change based on client's comments in future.
                filteredRecordCount.value = result.pageInfo.totalCount
                openItem.value = false
                // Append the newly loaded
                controlList.value = [
                    ...controlList.value,
                    ...toTableFormat(controls, (key) => t(key, language.value)),
                ]
                loading.value = false
            } catch (err) {
                loading.value = false
                throw (Error as any)('Error while retrieving controls', { cause: err })
            }
        }

        const setSortOrder = async (newOrder: SortOrderDTO) => {
            tableSortOrder.value = newOrder
            // Reload controls in the table with the new order
            controlList.value = []
            await getControls({ pageNum: 1 })
        }

        // format risk details
        const getFormatedControlDetails = () => {
            // set alert color and info
            controlDetails.value.color = utils.getAlertInfo('', controlDetails.value).color
            controlDetails.value.info = utils.getAlertInfo('', controlDetails.value).info
            controlDetails.value.infoKey = utils.getAlertInfo('', controlDetails.value).infoKey

            controlDetails.value.no = controlDetails.value.id
            controlDetails.value.lastRecordNo =
                tableCmp.value && utils.getLastRecord(controlList.value)
            controlDetails.value.firstRecordNo = tableCmp.value && controlList.value?.[0]?.no
        }

        // get control details for id
        /* `controlDetails.value = null` has to take out from `getControl(controlId)` function, in order to avoid component
        recreating in certain places as per the function requirement.
         */
        const getControl = async (controlId: number) => {
            const getControlDetailQuery = `
                query{
                    control(id:${controlId}){
                        ${controlQueries.CONTROL_LIST_CONTROL_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getControlDetailQuery)
            } catch (err) {
                Sentry.captureException(err)
                throw Error('Error while retrieving control details')
            }

            if (result) {
                const controlsDetailsResult = result.data.control
                controlDetails.value = controlsDetailsResult
                getFormatedControlDetails()
            }
        }

        const storyByFilterActive = (dataSet, field) => {
            return dataSet.sort((x, y) => {
                // true values first
                return x[field] === y[field] ? 0 : x[field] ? -1 : 1
            })
        }

        // get dropdown values for filter section
        const getControlFilterOptions = async () => {
            // empty `filterOptions` initially
            filterOptions.value.owner = []
            filterOptions.value.norm = []
            filterOptions.value.category = []
            filterOptions.value.alertColor = []
            filterOptions.value.maturity = []
            filterOptions.value.applicable = []

            loading.value = true
            // control filter option arguements
            const controlVariables = {
                args: {
                    option: 'CONTROLS',
                    // selected filters (selected category filters)
                    controls: utils.getFormattedControlFilters({ ...controlFilters.value })
                },
            }

            const options = await utils.getFilterOptions(
                'controls',
                controlQueries.FILTER_OPTIONS,
                controlVariables
            )
            loading.value = false
            if (options.result) {
                const controlOptions = options.result.controls
                if (controlOptions) {
                    // set owner dropdown values
                    controlOptions.owner.map((owner) => {
                        // if there are no owner assigned(when user is default) display 'No owner'
                        filterOptions.value.owner.push({
                            key: owner.id,
                            value:
                                owner.username === variables.DEFAULT_USERNAME
                                    ? t('NO_OWNER', language.value)
                                    : owner.firstName + ' ' + owner.lastName,
                        })
                    })
                    // sort by value of owner list
                    filterOptions.value.owner.sort((a, b) => (a.value > b.value ? 1 : -1))
                    // display No_OWNER value in last of the array
                    utils.updateElementPositionOfArray(
                        filterOptions.value.owner,
                        t('NO_OWNER', language.value),
                        filterOptions.value.owner.length - 1
                    )

                    // set norm dropdown values
                    controlOptions.norm.map((norm) => {
                        filterOptions.value.norm.push({
                            key: norm.id,
                            value: norm.name,
                            filterActive: norm.filterActive,
                        })
                    })

                    // display `filterActive` values top in the list
                    storyByFilterActive(filterOptions.value.norm, 'filterActive')

                    // set category dropdown values
                    controlOptions.category.map((category) => {
                        if (category)
                            filterOptions.value.category.push({
                                key: category,
                                value: category,
                            })
                    })

                    filterOptions.value.category = utils.naturalCompare(
                        filterOptions.value.category,
                        'value'
                    )

                    // set alert color dropdown values
                    controlOptions.alertColor.map((alert) => {
                        filterOptions.value.alertColor.push({
                            key: alert.color,
                            value: t(alert.color, language.value),
                        })
                    })
                    // set maturity dropdown values
                    controlOptions.maturityLevel.map((maturity) => {
                        filterOptions.value.maturity.push({
                            key: maturity.score,
                            value: t(MaturityLevel[maturity.refName], language.value),
                        })
                    })

                    filterOptions.value.applicable = [
                        { key: true, value: 'Yes' },
                        { key: false, value: 'No' }
                    ]
                }
            }
        }

        // set default filters for category based on priority wizard
        const setDefaultPrioCategoryFilters = () => {
            // if priority categories are selected from client config, set them default
            const selectedPriorityCategories =
                clientConfigData.value && clientConfigData.value.selectedPriorityCategories
            /* if priority filter toggle is true and display priority filter toggle is true, 
            set priority filter categories(selected ones from cc page)*/
            if (displayPriorityFilter.value && displayPriorityTooggle.value)
                controlFilters.value.category = selectedPriorityCategories.map(
                    (category) => category.name
                )
        }

        // set filter values which saved in local storage
        const setLocalStorageFilters = () => {
            controlsData.CONTROLS_FILTERS.map((val) => {
                // get filter values for each fields(`'control' + val.fieldName` will be the unique name to save in localStorage)
                const savedFilters: string = utils.ls.get('control' + val.fieldName, {
                    decrypt: true,
                })
                // set them as default filter
                if (savedFilters && JSON.parse(savedFilters).length > 0) {
                    // if the stored norm is not available on selectedNorms, remove default set
                    if (val.fieldName === 'norm') {
                        const selectedNormIds = selectedNorms.value.map((norm) => norm.id)
                        // check if selected norms have saved norms
                        if (selectedNormIds.some((r) => JSON.parse(savedFilters).indexOf(r) >= 0)) {
                            controlFilters.value[val.fieldName] = JSON.parse(savedFilters)
                        }
                    } else {
                        controlFilters.value[val.fieldName] = JSON.parse(savedFilters)
                    }
                }
            })
        }

        // set default filters for controls
        const setDefaultFilters = () => {
            // alert color from dashboard open items
            if (props.alertColor && props.alertColor.length > 0) {
                controlFilters.value.alertColor = props.alertColor
            }
            // getting norm from radar/bar chart in dashboard (control maturity level)
            if (props.norm) {
                controlFilters.value.norm = [Number(props.norm)]
            } else {
                /* only norm is storing in the localstorage atm. Hence this function can be called here to avoid replacing
                selected norm when navigating from radar chart. Later this will be moved to a common place when we have
                persistent filters for all the fields */
                // when user navigate from open items(`props.alertColor` has a value), ignore local storage default values
                if (!props.alertColor) setLocalStorageFilters()
            }
            // navigating from bar chart in dashboard
            if (props.maturity) {
                controlFilters.value.maturity = [Number(props.maturity)]
            }
            // navigating from radar chart in dashboard
            if (props.category) {
                controlFilters.value.category.push(props.category)
            }
            // navigating from open items in dashboard
            if (props.loggedInUser) {
                const user = +users.value.find((user) => user.fname === props.loggedInUser).id || props.loggedInUser        
                controlFilters.value.owner.push(user)
            }

            // #624 (turn off priority category filter wizard when coming from dashboard on control page)
            /*(turn off priority category filter wizard when coming from open items on control page) 
                (https://gitlab.com/stekz/perium/frontend/-/issues/674) */
            if (props.category || props.maturity || props.loggedInUser) {
                displayPriorityFilter.value = false
            } else {
                // set default filter for priority category wizard
                setDefaultPrioCategoryFilters()
            }
        }

        // set reset filters for controls
        const resetDefaultFilter = async () => {
            controlFilters.value = {
                category: [],
                norm: [],
                owner: [],
                alertColor: [],
                maturity: [],
                applicable: []
            }

            setDefaultFilters()

            // if there are control filters available by default, view filter section
            Object.keys(controlFilters.value).forEach((key) => {
                if (controlFilters.value[key].length > 0) {
                    viewFilterSection.value = true
                    return
                }
            })

            // controlList.value = []
            // await getControls({ pageNum: 1 })
        }
        const applyFilter = async (params) => {
            controlFilters.value = {
                category: [],
                norm: [],
                owner: [],
                alertColor: [],
                maturity: [],
                applicable: []
            }

            // selected filter values with fields
            const selectedFilters = params
            // store selected controls to `controlFilters`
            Object.keys(selectedFilters).forEach((key) => {
                if (key) controlFilters.value[key] = selectedFilters[key]
            })
            const selectedPriorityCategories =
                clientConfigData.value && clientConfigData.value.selectedPriorityCategories
            // When category selection gets unequel with `selectedPriorityCategories`, switch off the priority category filter
            if (controlFilters.value.category.length !== selectedPriorityCategories.length)
                displayPriorityFilter.value = false
            // make controlList empty before call for all controls. This cannot be placed inside getControls function as it's using for infinity scrolling
            controlList.value = []
            await getControls({ pageNum: 1 })
            // get filtered notm list by selected categories
            await getControlFilterOptions()
        }

        // search function
        const search = async () => {
            controlList.value = []
            searchValue.value !== ''
                ? (displayFilteredTitle.value = true)
                : (displayFilteredTitle.value = false)
            await getControls({ pageNum: 1 })
        }

        // set table headers
        const setTableHeaders = (language: string) => {
            expandedHeader.value = t('CONTROLS_TABLE_COLUMN_DESCRIPTION', language)

            tableHeaders.value = [
                {
                    header: t('CONTROLS_TABLE_COLUMN_NO', language),
                    naturalCompare: true,
                    fieldName: 'controlNumber',
                    style: 'min-width: 200px;',
                    alert: true,
                    sortField: 'controlNumber',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_NORM', language),
                    sort: true,
                    style: 'width: 200px;',
                    fieldName: 'norm',
                    sortField: 'norm',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_TOPIC', language),
                    sort: true,
                    limit: 250,
                    fieldName: 'topic',
                    sortField: 'topic',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    limit: 200,
                    fieldName: 'description',
                    sortField: 'description',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_CATEGORY', language),
                    fieldName: 'category',
                    sortField: 'category',
                    naturalCompare: true,
                },
                {
                    header: t('CONTROLS_TABLE_MATURITY_LEVEL', language),
                    fieldName: 'maturity',
                    sort: true,
                    sortField: 'maturity',
                },
                {
                    header: t('CONTROLS_TABLE_IMPLEMENTATION_STATUS', language),
                    fieldName: 'implementationStatus',
                    sort: true,
                    sortField: 'implementationStatus',
                },
                {
                    header: t('CONTROLS_TABLE_COLUMN_APPLICABLE', language),
                    fieldName: 'applicable',
                },
            ]
        }

        const openControlDetailsPopup = async (id: number) => {
            // Mark this entry as active in the table
            tableActiveRow.value = controlList.value.find((control) => control.id === id)?.no
            visibleControlDetails.value = true
            controlDetails.value = null
            await getControl(id)
        }

        // Go to control record by no, or to the previous or next record
        const goToRecord = async (params: { type: string; no: number }) => {
            let index = controlList.value.findIndex((control) => control.no === params.no)
            if (params.type === 'next' && index < controlList.value.length - 1) {
                // Increment index
                index = index + 1
            } else if (params.type === 'previous' && index > 0) {
                // Decrement index
                index = index - 1
            } else {
                // Don't change the index
            }

            const record = controlList.value[index]
            if (record) {
                // Load the new record into the details sidebar
                openControlDetailsPopup(record.id)
            }
        }

        // update list after assessment is updated
        const updateListPostAssessment = async (params: { type: string; no: number }) => {
            // Refresh the controls in the table
            controlList.value = []
            await getControls({ pageNum: 1 })
            if (tableCmp.value) {
                const record = controlList.value.find((control) => control.no === params.no)
                if (record) {
                    // Refresh the control in the details view
                await getControl(record.id)
                }               
            }
        }

        // get categories and sub categories
        const getCategoriesAndSubCategories = async () => {
            const clientConfig = `
                query{
                    categories(type: "control"){
                        ${controlQueries.CATEGORIES_SUBCATEGORIES}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(clientConfig)
            } catch (err) {
                throw Error('Error while retrieving categories')
            }
            if (result) {
                controlCategories.value = result.data.categories
            }
        }

        // control input for the entities which are created by organisation
        const saveControlInputForOtherUsers = (controlsFormData: ControlFormDTO) => {
            // other user form data
            return {
                owner: controlsFormData.OWNER,
                keyControl: controlsFormData.KEY_CONTROL == 1 ? true : false,
                process: controlsFormData.PROCESS,
                validFrom: controlsFormData.VALID_FROM,
                validThru: controlsFormData.VALID_THRU,
                executionIntervalUnit: controlsFormData.EXECUTION_INTERVAL_UNIT,
                executionIntervalValue: Number(controlsFormData.EXECUTION_INTERVAL_VALUE),
                assessmentIntervalUnit: controlsFormData.ASSESSMENT_INTERVAL_UNIT,
                assessmentIntervalValue: Number(controlsFormData.ASSESSMENT_INTERVAL_VALUE),
                executionType:
                    controlsFormData.EXECUTION_TYPE === 0 ? null : controlsFormData.EXECUTION_TYPE,
                executionGuide: controlsFormData.EXECUTION_GUIDE,
                controlExplanation: controlsFormData.CONTROL_EXPLANATION,
                applicable: controlsFormData.APPLICABLE == 1 ? true : false,
                applicableReason: controlsFormData.APPLICABLE_REMARK,
                assets: controlsFormData.ASSETS,
                documentationUrl: controlsFormData.DOCUMENTATION_URL,
            }
        }

        // control input for the entities which are created by user
        const getAllControlInput = (controlsFormData: ControlFormDTO) => {
            const category =
                controlsFormData.CATEGORY === t('OTHER', language.value)
                    ? controlsFormData.CATEGORY_TEXT
                    : controlsFormData.CATEGORY
            const subCategory =
                controlsFormData.SUB_CATEGORY === t('OTHER', language.value)
                    ? controlsFormData.SUB_CATEGORY_TEXT
                    : controlsFormData.SUB_CATEGORY
            return {
                originNorm: controlsFormData.NORM,
                controlNumber: controlsFormData.CONTROL_NUMBER,
                controlTypes: controlsFormData.CONTROL_TYPE,
                subCategory: subCategory,
                category: category,
                topic: controlsFormData.TOPIC,
                description: controlsFormData.DESCRIPTION,
                objective: controlsFormData.OBJECTUVE,
                consideration: controlsFormData.CONSIDERATION,
                impactIntegrity: controlsFormData.BIV.I === YesNo.YES ? true : false,
                impactAvailability: controlsFormData.BIV.B === YesNo.YES ? true : false,
                impactConfidentiality: controlsFormData.BIV.V === YesNo.YES ? true : false,
                implementationGuide: controlsFormData.IMPLEMENTATION_GUIDE,
                implementationGuideAdditionalInformation: controlsFormData.ADDITIONAL_INFORMATION,
                owner: controlsFormData.OWNER,
                keyControl: controlsFormData.KEY_CONTROL == 1 ? true : false,
                process: controlsFormData.PROCESS,
                validFrom: controlsFormData.VALID_FROM,
                validThru: controlsFormData.VALID_THRU,
                executionIntervalUnit: controlsFormData.EXECUTION_INTERVAL_UNIT,
                executionIntervalValue: Number(controlsFormData.EXECUTION_INTERVAL_VALUE),
                assessmentIntervalUnit: controlsFormData.ASSESSMENT_INTERVAL_UNIT,
                assessmentIntervalValue: Number(controlsFormData.ASSESSMENT_INTERVAL_VALUE),
                executionType: controlsFormData.EXECUTION_TYPE,
                executionGuide: controlsFormData.EXECUTION_GUIDE,
                controlExplanation: controlsFormData.CONTROL_EXPLANATION,
                applicable: controlsFormData.APPLICABLE == 1 ? true : false,
                applicableReason: controlsFormData.APPLICABLE_REMARK,
                risks: controlsFormData.RISKS,
                assets: controlsFormData.ASSETS,
                documentationUrl: controlsFormData.DOCUMENTATION_URL,
                cyberSecurityConcepts: controlsFormData.CYBER_SECURITY_CONCEPTS,
                operationalCapabilities: controlsFormData.OPERATIONAL_CAPABILITIES,
                securityDomains: controlsFormData.SECURITY_DOMAIN,
            }
        }

        // control input for super admin
        const getMasterControlInput = (controlsFormData: ControlFormDTO) => {
            const category =
                controlsFormData.CATEGORY === t('OTHER', language.value)
                    ? controlsFormData.CATEGORY_TEXT.trim()
                    : controlsFormData.CATEGORY
            const subCategory =
                controlsFormData.SUB_CATEGORY === t('OTHER', language.value)
                    ? controlsFormData.SUB_CATEGORY_TEXT.trim()
                    : controlsFormData.SUB_CATEGORY
            return {
                controlNumber: controlsFormData.CONTROL_NUMBER,
                controlTypes: controlsFormData.CONTROL_TYPE,
                subCategory: subCategory,
                category: category,
                topic: controlsFormData.TOPIC,
                description: controlsFormData.DESCRIPTION,
                objective: controlsFormData.OBJECTUVE,
                consideration: controlsFormData.CONSIDERATION,
                impactIntegrity: controlsFormData.BIV.I === YesNo.YES ? true : false,
                impactAvailability: controlsFormData.BIV.B === YesNo.YES ? true : false,
                impactConfidentiality: controlsFormData.BIV.V === YesNo.YES ? true : false,
                implementationGuide: controlsFormData.IMPLEMENTATION_GUIDE,
                implementationGuideAdditionalInformation: controlsFormData.ADDITIONAL_INFORMATION,
                cyberSecurityConcepts: controlsFormData.CYBER_SECURITY_CONCEPTS,
                operationalCapabilities: controlsFormData.OPERATIONAL_CAPABILITIES,
                securityDomains: controlsFormData.SECURITY_DOMAIN,
            }
        }
        //  save control details
        const saveControlDetails = async (params: {
            id: number;
            formData;
            createdBy;
            type;
            no;
        }) => {
            const controlsFormData = params.formData

            let mutationQuery
            let input
            BEvalidationError.value = ''

            if (role.value === UserRole.SUPER_ADMIN) {
                // mutation query for super admin
                mutationQuery = `mutation ($input: UpdateMasterControlInput!) {
                        updateMasterControl(id: ${params.id}, input: $input) {
                            status
                            error
                            errorCode{
                                value
                            }
                        }
                    }`
                input = getMasterControlInput(controlsFormData)
            } else {
                // mutation query for other users
                mutationQuery = `mutation ($input: ControlInput!) {
                    updateControl(id:${params.id}, input: $input) {
                            status
                            error
                            errorCode{
                                value
                            }
                        }
                    }`

                if (params.createdBy) {
                    input = getAllControlInput(controlsFormData)
                } else {
                    input = saveControlInputForOtherUsers(controlsFormData)
                }
            }
            let result
            try {
                // update data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                // control save error
                toast.error(t('CONTROL_DETAILS_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving a control')
            }
            // update control success
            if (result.data.updateControl && result.data.updateControl.status) {
                /* empty array before feed records to controlList. This cannot place inside getControls 
                    function as we use infinity scrolling */
                controlList.value = []
                await getControlFilterOptions()
                await getControls({ pageNum: 1 })
                // get latest categories and sub categories
                await getCategoriesAndSubCategories()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.type === 'save-next') {
                    await goToRecord({ no: params.no, type: 'next' })
                } else {
                    controlDetails.value = null
                    await getControl(params.id)
                }
                toast.success(t('CONTROL_DETAILS_SAVED_SUCCESS_MESSAGE', language.value))
            } else if (
                //  update super admin success
                result.data.updateMasterControl &&
                result.data.updateMasterControl.status
            ) {
                /* empty array before feed records to controlList. This cannot place inside getControls 
                    function as we use infinity scrolling */
                controlList.value = []
                await getControlFilterOptions()
                await getControls({ pageNum: 1 })
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.type === 'save-next') {
                    await goToRecord({ no: params.no, type: 'next' })
                } else {
                    controlDetails.value = null
                    await getControl(params.id)
                }
                toast.success(t('CONTROL_DETAILS_SAVED_SUCCESS_MESSAGE', language.value))
            } else {
                // control BE validation error check
                if (
                    result.data.updateMasterControl?.errorCode ||
                    result.data.updateControl?.errorCode
                ) {
                    const error =
                        result.data.updateMasterControl?.errorCode?.value ||
                        result.data.updateControl?.errorCode?.value
                    BEvalidationError.value = t(utils.getErrorMessage(error), language.value)
                } else {
                    // control save error
                    toast.error(t('CONTROL_DETAILS_SAVED_ERROR_MESSAGE', language.value))
                }
            }
        }

        // create new control
        const saveNewControl = async (params: { formData }) => {
            const controlsFormData = params.formData
            BEvalidationError.value = ''

            const mutationQuery = `mutation ($input: ControlInput!) {
                        createControl(input: $input) {
                            control{
                                id
                            }
                            status
                            error
                            errorCode{
                                value
                            }
                        }
                    }`

            const input = getAllControlInput(controlsFormData)

            // create control api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('CONTROL_DETAILS_SAVED_ERROR_MESSAGE', language.value))
                throw Error('Error while saving a new control')
            }
            if (result.data.createControl && result.data.createControl.status) {
                toast.success(t('CONTROL_DETAILS_SAVED_SUCCESS_MESSAGE', language.value))
                /* empty array before feed records to controlList. This cannot place inside getControls 
                    function as we use infinity scrolling */
                controlList.value = []
                await getControlFilterOptions()
                await getControls({ pageNum: 1 })
                displayCreateControl.value = false
            } else {
                if (result.data.createControl?.errorCode) {
                    const error = result.data.createControl?.errorCode?.value
                    BEvalidationError.value = t(utils.getErrorMessage(error), language.value)
                } else {
                    // control save error
                    toast.error(t('CONTROL_DETAILS_SAVED_ERROR_MESSAGE', language.value))
                }
            }
            // get latest categories and sub categories
            getCategoriesAndSubCategories()
        }
        // enable filter section
        const enableFilter = () => {
            viewFilterSection.value = !viewFilterSection.value
        }
        // set table height for frontend active filter
        const openFilterSection = async () => {
            // enabling filter section should stay in a separate function as cannot use await there directly
            enableFilter()
            const filterSectionEle: HTMLElement = document.querySelector('.filter-section-backend')

            // get custom table component
            const node: HTMLElement = document.querySelector('.custom-table')
            // get the height of filter section
            if (filterSectionEle) {
                const height = filterSectionEle.offsetHeight
                // 140px is a constant height of top section
                const uppersectionHeight = height + 140
                // if filter section is opened, set height manually
                if (viewFilterSection.value) {
                    if (node) {
                        node.style.height = 'calc(100vh - ' + String(uppersectionHeight) + 'px)'
                    }
                } else {
                    if (node) {
                        node.style.height = '86vh'
                    }
                }
            } else {
                if (node) {
                    // when the filter section is closed, set default height
                    node.style.height = '86vh'
                }
            }
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // set searched records count
        const searchCount = (count) => {
            filteredRecordCount.value = count
        }

        // check whether any filter options are available for any fields
        const checkOptiopnsAvailable = computed(() => {
            const optionsAvailable = Object.keys(filterOptions.value).map((key) =>
                filterOptions.value[key].length > 0 ? true : false
            )
            return optionsAvailable.includes(true)
        })

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                const seondSidebar = document.querySelector('.second-sidebar')
                if (event.key === 'Escape') {
                    //close view details entity if it's open
                    if (visibleControlDetails.value && !seondSidebar) {
                        visibleControlDetails.value = false
                    }
                    // close create new entity if it's open
                    if (displayCreateControl.value) {
                        displayCreateControl.value = false
                    }
                }
            })
        }
        // get priority filter toggle active status and selected priority categories from client configurations
        const getClientConfigData = async () => {
            loading.value = true
            const getControlDetailQuery = `
                query{
                    organisationconfig(orgId:${organisationId}){
                        ${controlQueries.CONTROL_LIST_CC_DATA}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getControlDetailQuery)
                loading.value = false
            } catch {
                loading.value = false
                throw Error('Error while retrieving client config data')
            }

            if (result) {
                const clientConfigResult = result.data.organisationconfig
                clientConfigData.value = clientConfigResult
                displayPriorityTooggle.value = clientConfigData.value.priorityCategoryActivated
            }
        }

        // priority filter toggle change handler
        const handleDisplayPriorityFilter = async () => {
            controlFilters.value = {
                category: [],
                norm: [],
                owner: [],
                alertColor: [],
                maturity: [],
                applicable: []
            }
            if (clientConfigData.value) {
                // set priority category filter values
                setDefaultPrioCategoryFilters()
                controlList.value = []
                await getControls({ pageNum: 1 })
                getControlFilterOptions()
            }

            // store display priority filter choice(toggle value) in local storage
            utils.ls.set(
                variables.LOCAL_STORAGE_ITEMS.PRIORITY_FILTER_TOGGLE,
                displayPriorityFilter.value
            )
        }

        // delete control
        const deleteControl = async () => {
            const deleteQuery = `
                    mutation{
                        deleteControl(id: ${controlDetails.value.id}){
                            status
                            error
                        }
                    }
                    `
            // approve data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(deleteQuery, null)
            } catch (err) {
                displayDeleteConfirmation.value = false
                toast.error(t('CONTROL_DETAILS_DELETE_FAIL_MESSAGE', language.value))
                throw Error('Error while deleting control')
            }

            if (result.data.deleteControl.status) {
                displayDeleteConfirmation.value = false
                visibleControlDetails.value = false
                /* empty array before feed records to controlList. This cannot place inside getControls 
                    function as we use infinity scrolling */
                controlList.value = []
                await getControlFilterOptions()
                await getControls({ pageNum: 1 })
                toast.success(t('CONTROL_DETAILS_DELETE_SUCCESS_MESSAGE', language.value))
            } else toast.error(t('CONTROL_DETAILS_DELETE_FAIL_MESSAGE', language.value))
        }

        const getControlDropdowns = async () => {
            let result
            try {
                result = await apolloClient.apolloClient.query({
                    query: controlQueries.CONTROL_DROPDOWNS,
                    fetchPolicy: 'network-only',
                })
            } catch (err) {
                throw Error('Error while retrieving control dropdowns')
            }
            if (result && result.data.controlValues) {
                const ctrDropdowns = result.data.controlValues
                controlDropdowns.value.controlTypes = ctrDropdowns.controlTypes
                controlDropdowns.value.cyberSecurityConcepts = ctrDropdowns.cyberSecurityConcepts
                controlDropdowns.value.operationalCapabilities =
                    ctrDropdowns.operationalCapabilities
                controlDropdowns.value.securityDomains = ctrDropdowns.securityDomains
                controlDropdowns.value.owner = result.data.allowedFieldValues.values.owner
            }
        }

        const exportAllControls = async () => {
            try {
                loading.value = true
                const unfilteredControls = await fetchControls({
                    perPage: 1000000,
                    pageNum: 1,
                    bySelectedPriorityCategories: false, // Disable priority filter
                    isOpenItem: false, // Disable open items filter,
                    controlFilters: {},
                })
                const formattedControls = toTableFormat(unfilteredControls.items, (key) =>
                    t(key, language.value)
                )
                const date = new Date().toLocaleDateString('nl')
                const fileName = `perium-controls-${date}`
                await exportTable(
                    fileName,
                    [
                        ...tableHeaders.value,
                        { header: 'Alert Color', fieldName: 'alertColorName' },
                        { header: 'Alert Message', fieldName: 'alert' },
                    ],
                    formattedControls.map((row) => ({
                        ...row,
                        applicable: t(row.applicable ? 'YES' : 'NO'),
                        // Add field with translated alert message
                        alert: t(row.infoKey, language),
                    }))
                )
                toast.success(t('CONTROLS_EXPORT_SUCCESS_TOAST'))
            } catch (err) {
                toast.error(t('CONTROLS_EXPORT_FAILED_TOAST'))
                // Re-throw error for Sentry
                // Cast Error to any because old typescript does not know about
                // the options parameter. Can be removed one typescipt is updated.
                throw (Error as any)('Error while exporting controls', {
                    cause: err,
                })
            } finally {
                loading.value = false
            }
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, (newValue: string) => {
            setTableHeaders(newValue)
        })

        watch(
            () => [props.norm, props.category, props.loggedInUser],
            async () => {
                resetDefaultFilter()
                controlList.value = []
                await getControls({ pageNum: 1 })
            }
        )

        // handling control query call based on default filters
        const initialControlLoad = async () => {
            /* `getControlFilterOptions()` should call first to get all dropdown values and set by default values.
            Next `getControlFilterOptions()` should call after setting default values to get filtered norms list by categories*/
            // `setDefaultFilters()` has to set here to find default category filters. Based on that priority filter will be turn on/off

            await getClientConfigData()
            setDefaultFilters()
            /* Call get controls first before setting filters since backend has introduced `bySelectedPriorityCategories` property.
            Hence getControls can be called without setting filters as it does from BE */
            if (displayPriorityFilter.value && displayPriorityTooggle.value) {
                controlList.value = []
                await getControls({ pageNum: 1 })
                // set default filters once after filter options are received
                await getControlFilterOptions()
                resetDefaultFilter()
            } else {
                /* When user navigate from other pages such as open items or radar chart, priority category filter will be turned off.
                Hence we need to first receive filter options and set default filters. Then send them along with getcontrols api */
                await getControlFilterOptions()
                resetDefaultFilter()
                controlList.value = []
                await getControls({ pageNum: 1 })
                await getControlFilterOptions()
            }
            getCategoriesAndSubCategories()
            getControlDropdowns()
        }

        onMounted(async () => {
            closeSidebarEsc()

            await initialControlLoad()

            // when navigating from heatmap, open selected row details
            if (props.idfilter) {
                const idfilter = Number(props.idfilter)
                // make active row if idfilter value is available within initial controlList
                if (controlList.value.find((val) => val.id === idfilter)) {
                    openControlDetailsPopup(Number(props.idfilter))
                }
            }
            setTableHeaders(language.value)
        })

        return {
            controlList,
            tableHeaders,
            tableActiveRow,
            language,
            t,
            loading,
            tableCmp,
            variables,
            role,
            visibleControlDetails,
            openControlDetailsPopup,
            controlDetails,
            goToRecord,
            saveControlDetails,
            SET_LEFT_MENU_SIDEBAR,
            SET_OPEN_MENU,
            utils,
            menuOpen,
            displayCreateControl,
            getControls,
            saveNewControl,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            searchValue,
            searchCount,
            controlsData,
            UserRole,
            totalPages,
            updateListPostAssessment,
            filterOptions,
            applyFilter,
            search,
            checkOptiopnsAvailable,
            openFilterSection,
            controlFilters,
            displayFilteredTitle,
            displayPriorityFilter,
            handleDisplayPriorityFilter,
            displayPriorityTooggle,
            infomationOp,
            toggleInformation,
            controlCategories,
            displayDeleteConfirmation,
            deleteControl,
            tableSortOrder,
            setSortOrder,
            exportAllControls,
            BEvalidationError,
        }
    },
})
