import { gql } from "@apollo/client/core"

const RISK_FIELD_OPTIONS = gql(`
query {
allowedFieldValues {
    status
    values {
        riskType {
            label
            value
        }
        owner {
            label
            value
            inactive
        }
        threat {
            label
            value
            number
        }
        control {
            source
            number
            label
            value
        }
        vulnerability {
            label
            value
            number
        }
    }
    }
}
`)
// risk data query
const RISK_DETAILS = `
        id
        refId
        order
        description
        fullDescription
    `
// risk assessment query
const RISK_ASSESSMENT_DETAILS = `
    id
    alertColor{
        color
        info
        infoKey
    }
    ambitionChance
    ambitionImpact
    approvalDate
    approvalRemark
    approvedBy{
        username
        firstName
        lastName
        id
    }
    documentationUrl
    executedBy{
        id
        username
        firstName
        lastName
    }
    executionDate
    grossChance
    grossImpact
    lastModified
    netChance
    netImpact
    nextRsaDate
    refId
    risk{
        ${RISK_DETAILS}
    }
    riskAssessmentExplanation
    riskResponse
    riskStratergy
    state
    validationAlertColor{
        color
        info
        infoKey
    }
    
    `
// risk approval content query
const RISK_ASSESSMENT_APPROVE_DETAILS = `
        id
        state
        approvedBy{
            username
            firstName
            lastName
            id
        }
        approvalDate
        approvalRemark

    `

//  get risk assessments list
const RISK_ASSESSMENTS_LIST = `
        id
        alertColor{
            color
            info
            infoKey
        }
        approvalDate
        approvedBy {
            username
            firstName
            lastName
        }
        executedBy {
            username
            firstName
            lastName
        }
        executionDate
        netChance
        netImpact
        nextRsaDate
        order
        refId
        risk {
            id
            description
            fullDescription
        }
        state
        validationAlertColor{
            color
            info
            infoKey
        }
        isLatest
    `

const RISK_LIST = `
    id
    refId
    order
    alertColor{
        color
        info
        infoKey
    }
    description  
    fullDescription 
    riskType
    riskTypeLabel
    owner {
        id
        firstName
        lastName
        username
    }
    assessments{
        id
        netChance
        netImpact
        nextRsaDate
        executionDate
    }
    `

const RISK_ASSESSMENTS_TAB_LIST = `
    id
    order
    refId
    executedBy {
        username
        firstName
        lastName
    }
    netChance
    netImpact
    nextRsaDate
    state
    executionDate
`

const RISK_FOLDOUT = `
    id
    refId
    order
    alertColor{
        color
        info
        infoKey
    }
    description  
    fullDescription
    riskType
    riskTypeLabel
    riskTypeOther
    owner {
        id
        firstName
        lastName
        username
    }
    assessments{
        id
        netChance
        netImpact
        nextRsaDate
        executionDate
    }
    created_by{
        id
        username
    }
    impactConfidentiality
    impactIntegrity
    impactAvailability
    riskExplanation
    threats{
        id
        description
    }
    vulnerabilities{
        id
        description
    }
    controls{
        id
        controlNumber
        category
    }
    lastModified
`

const CLIENT_CONFIGURATION_DETAILS = `
    riskToleranceGreen
    riskToleranceOrange
    riskToleranceRed
    selectedNorms{
        normType
    }
    `

export default {
    RISK_ASSESSMENT_DETAILS,
    RISK_DETAILS,
    RISK_ASSESSMENT_APPROVE_DETAILS,
    RISK_ASSESSMENTS_LIST,
    RISK_LIST,
    CLIENT_CONFIGURATION_DETAILS,
    RISK_FOLDOUT,
    RISK_FIELD_OPTIONS,
    RISK_ASSESSMENTS_TAB_LIST
}
