




























































































































































































































































































































































































































































































































































































































import { computed, defineComponent, inject, PropType, reactive, ref, UnwrapRef, watch } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import {  UserRole, YesNo } from '@/shared/enum/general-enum'
import moment from 'moment'
import generalData from '@/assets/data/general-data.json'
import { TaskDropdownValuesDTO, TaskDTO, TaskFormDTO } from '@/dto/backend-response/tasksDTO'
import Confirmation from '@/shared/components/Confirmation.vue'
import { TaskStatus, TaskStrategies } from '@/shared/enum/tasks-enum'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'
import TASK_DATA from '@/assets/data/task-data.json'
import { UserDTO } from '@/dto/backend-response/usersDTO'
import {addIntervalToDate, dateWithDayGranularity} from '@/shared/utils/date'


// Initialize form data for creating a new task
const newTaskFormData = (loggedInUser: UserDTO, activeRole: UserRole): TaskFormDTO => ({
    DESCRIPTION: '',
    FULL_DESCRIPTION: '',
    TYPE: '',
    FREQUENCY_INTERVAL_UNIT: null,
    FREQUENCY_INTERVAL_VALUE: 0,
    PRIORITY: '',
    // When initialized by an employee, automatically set the owner
    // and executor to the current user.
    OWNER: (activeRole === UserRole.EMPLOYEE) ? loggedInUser.id : 0,
    EXECUTED_BY: (activeRole === UserRole.EMPLOYEE) ? loggedInUser.id : 0,
    START_DATE: dateWithDayGranularity(new Date()),
    END_DATE: dateWithDayGranularity(new Date()),
    TASK_COMPLETED: YesNo.NO,
    DOCUMENT_LINK: '',
    NOTES: '',
    TASK_STRATERGY: TaskStrategies.PERIODICAL,
})

// Populate form data with details from an existing task
const initializeUpdateForm = (taskDetails: TaskDTO): TaskFormDTO => {
    const formData: TaskFormDTO = {
        DESCRIPTION: taskDetails.description,
        FULL_DESCRIPTION: taskDetails.fullDescription,
        TYPE: taskDetails.taskType?.key,
        FREQUENCY_INTERVAL_UNIT: taskDetails.frequencyUnit?.key,
        FREQUENCY_INTERVAL_VALUE: taskDetails.frequencyValue,
        PRIORITY: taskDetails.taskPriority?.key,
        OWNER: (taskDetails.owner.username === variables.DEFAULT_USERNAME) 
            ? 0 
            : taskDetails.owner.id,
        EXECUTED_BY: taskDetails.executedBy?.id,
        START_DATE: dateWithDayGranularity(
            taskDetails.startDate 
            ? moment(taskDetails.startDate).toDate() 
            : new Date()
        ),
        END_DATE: dateWithDayGranularity(
            taskDetails.endDate 
            ? moment(taskDetails.endDate).toDate() 
            : new Date()
        ),
        TASK_COMPLETED: taskDetails.taskCompleted ? YesNo.YES : YesNo.NO,
        DOCUMENT_LINK: taskDetails.documentLink,
        NOTES: taskDetails.notes,
        TASK_STRATERGY: taskDetails.repeatStrategy?.key,
    }
    return formData;
}

const copyTaskFormData = (loggedInUser: UserDTO, activeRole: UserRole, sourceTask: TaskDTO): TaskFormDTO => {
    const formData = initializeUpdateForm(sourceTask)
    formData.START_DATE = dateWithDayGranularity(new Date())
    formData.EXECUTED_BY = loggedInUser.id
    formData.OWNER = loggedInUser.id
    formData.TASK_COMPLETED = YesNo.NO
    if (sourceTask.status.key !== TaskStatus.TEMPLATE) {
        // We are creating the next execution of a repeating task
        if (sourceTask.owner) {
            // keep task owner the same even if someone else is creating the copy
            formData.OWNER = sourceTask.owner.id
        }
        if (sourceTask.endDate) {
            // Set the new start date to the end date of the last occurence
            formData.START_DATE = dateWithDayGranularity(
                moment(sourceTask.endDate).toDate())
        }
    }
    formData.END_DATE = addIntervalToDate(
        formData.START_DATE,
        sourceTask.frequencyUnit.key,
        sourceTask.frequencyValue
    )
    formData.NOTES = ''
    return formData;
}

export default defineComponent({
    props: {
        entityDetails: {
            type: Object as PropType<TaskDTO & {no?: string}>,
        },
        superAdminEdit: {
            type: Boolean,
        },
        formEditable: {
            type: Boolean,
        },
        editFormType: {
            // Don't knwo why we use a data structure like this here
            type: Object as PropType<{COPY?: boolean; UPDATE?: boolean}>,
        },
    },
    components: {
        Confirmation,
        DocumentationLink,
    },
    setup(props, { emit }) {
        const { t, tc } = useI18n()
        const { language } = useState(['language'])
        const { role } = useState(['role'])
        const { users } = useState(['users'])
        const labelInfomationOpDescription = ref(null)
        const labelInfomationOpFullDescription = ref(null)
        const labelInfomationOpDetailsType = ref(null)
        const labelInfomationOpDetailsFrequency = ref(null)
        const labelInfomationOpDetailsPriority = ref(null)
        const labelInfomationOpOwner = ref(null)
        const labelInfomationOpExecutedby = ref(null)
        const labelInfomationOpStartDate = ref(null)
        const labelInfomationOpEndDate = ref(null)
        const labelInfomationOpTaskCompleted = ref(null)
        const labelInfomationOpDocumentLink = ref(null)
        const labelInfomationOpNotes = ref(null)
        // True when the task was created by a user and not as part of a system norm.
        const createdByUser = ref(false)
        const taskFrequencies = ref([])
        const taskPriorities = ref([])
        const taskTypes = ref([])
        const translatableDropdownValues = ref({
            taskFrequencyUnit: '',
            taskType: '',
            taskPriority: '',
            taskStratergy: '',
        })
        // get logged in user info
        const loggedInUser: { user: UserDTO } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })

        // Use Reactive instead of ref to make sure we get reactive updates when
        // any form field is changed.
        const TASK_FORM: UnwrapRef<TaskFormDTO> = reactive(
            newTaskFormData(
                loggedInUser.user,
                role.value,
            )
        )

        const errors = ref({
            DESCRIPTION: { missingValue: false },
            FULL_DESCRIPTION: { missingValue: false },
            TYPE: { missingValue: false },
            FREQUENCY_INTERVAL_UNIT: { missingValue: false },
            FREQUENCY_INTERVAL_VALUE: { missingValue: false },
            PRIORITY: { missingValue: false },
            OWNER: { missingValue: false, expire: false },
            EXECUTED_BY: { expire: false },
            START_DATE: { missingValue: false },
            END_DATE: { missingValue: false, invalidValue: false },
            TASK_STRATERGY: { missingValue: false },
        })
        const employees = ref([])
        const taskCompletedDropdown = ref([])
        const taskRepeatStrategies = ref([])

        //injectors
        const taskDropdownValues: {
            taskRepeatStrategies: TaskDropdownValuesDTO<TaskStrategies>[];
            taskFrequencies: TaskDropdownValuesDTO[];
            taskPriorities: TaskDropdownValuesDTO[];
            taskStatuses: TaskDropdownValuesDTO<TaskStatus>[];
            taskTypes: TaskDropdownValuesDTO[];
        } = inject('taskDropdownValues')

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field: { toggle: (arg0) => void }) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }


        
        const checkInvalidDate = () => {
            if (!TASK_FORM.START_DATE || !TASK_FORM.END_DATE) {
                return
            }
            const startDate = dateWithDayGranularity(TASK_FORM.START_DATE)
            const endDate = dateWithDayGranularity(TASK_FORM.END_DATE)
            const valid = moment(startDate).isSameOrBefore(moment(endDate))
            errors.value.END_DATE.invalidValue = !valid
        }

        //  validating form
        const validateForm = () => {
            errors.value = {
                DESCRIPTION: { missingValue: false },
                FULL_DESCRIPTION: { missingValue: false },
                TYPE: { missingValue: false },
                FREQUENCY_INTERVAL_UNIT: { missingValue: false },
                FREQUENCY_INTERVAL_VALUE: { missingValue: false },
                PRIORITY: { missingValue: false },
                OWNER: { missingValue: false, expire: false },
                EXECUTED_BY: { expire: false },
                START_DATE: { missingValue: false },
                END_DATE: { missingValue: false, invalidValue: false },
                TASK_STRATERGY: { missingValue: false },
            }
            // missingValue checks
            errors.value.DESCRIPTION.missingValue = utils.validateFields(
                TASK_FORM.DESCRIPTION,
                'string'
            )

            errors.value.FULL_DESCRIPTION.missingValue = utils.validateFields(
                TASK_FORM.FULL_DESCRIPTION,
                'string'
            )
            errors.value.TYPE.missingValue = utils.validateFields(TASK_FORM.TYPE, 'string')

            // validate only if the task stratergy is `PERIODICAL`
            if (TASK_FORM.TASK_STRATERGY === TaskStrategies.PERIODICAL) {
                errors.value.FREQUENCY_INTERVAL_VALUE.missingValue = utils.validateFields(
                    TASK_FORM.FREQUENCY_INTERVAL_VALUE,
                    'number'
                )
                errors.value.FREQUENCY_INTERVAL_UNIT.missingValue = utils.validateFields(
                    TASK_FORM.FREQUENCY_INTERVAL_UNIT,
                    'string'
                )
            }

            errors.value.PRIORITY.missingValue = utils.validateFields(
                TASK_FORM.PRIORITY,
                'string'
            )
            // validate only for employee as they are editable and required only when user creates/updates tasks
            if (role.value !== UserRole.SUPER_ADMIN) {
                errors.value.START_DATE.missingValue = utils.validateFields(
                    TASK_FORM.START_DATE,
                    'string'
                )
                errors.value.OWNER.missingValue = utils.validateFields(
                    TASK_FORM.OWNER,
                    'number'
                )
                errors.value.END_DATE.missingValue = utils.validateFields(
                    TASK_FORM.END_DATE,
                    'string'
                )
            }

            /* check whether selected owner is expired or not.
            Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
            In this case, it will ask to select an active user
                */
            const owner = employees.value.find((employee) => employee.id === TASK_FORM.OWNER)
            if (owner) errors.value.OWNER.expire = owner.userExpired

            if (TASK_FORM.EXECUTED_BY) {
                const executedBy = employees.value.find(
                    (employee) => employee.id === TASK_FORM.EXECUTED_BY
                )
                if (executedBy) errors.value.EXECUTED_BY.expire = executedBy.userExpired
            }
            checkInvalidDate()
        }

        // Task end date should be based on the calculation of start date and frequency
        const setTaskEndDate = () => {
            if (TASK_FORM.START_DATE) {
                TASK_FORM.END_DATE = addIntervalToDate(
                    TASK_FORM.START_DATE,
                    TASK_FORM.FREQUENCY_INTERVAL_UNIT,
                    TASK_FORM.FREQUENCY_INTERVAL_VALUE
                )
            }
        }

        // go to record (previous/ next)
        const goToRecord = (type: string) => {
            emit('go-to-record', { no: props.entityDetails.no, type })
        }

        // Called from TaskRegister
        const cancelForm = () => Object.assign(
            TASK_FORM,
            newTaskFormData(
                loggedInUser.user,
                role.value,
            )
        )

        // When task repeat strategy is set to ONCE, clear interval values
        watch(
            () => TASK_FORM.TASK_STRATERGY,
            (strategy: TaskStrategies) => {
                if (strategy === TaskStrategies.ONCE) {
                    TASK_FORM.FREQUENCY_INTERVAL_UNIT = null
                    TASK_FORM.FREQUENCY_INTERVAL_VALUE = 0
                }
            }
        )

        // When start or end date changes, update the date validation error
        watch([
            () => TASK_FORM.START_DATE,
            () => TASK_FORM.END_DATE,
        ],
            checkInvalidDate,
        )

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return props.formEditable || props.superAdminEdit || !props.entityDetails
        })

        // Fields which super admin can edit, will be view under following conditions
        const isSuperAdminViewFieldEnabled = computed(() => {
            return (
                !props.superAdminEdit &&
                !(createdByUser.value && isEditableMode.value) &&
                props.entityDetails
            )
        })

        // Fields which super admin can edit, will be isEditableMode under following conditions
        const isSuperAdminEditFieldEnabled = computed(() => {
            return (
                props.superAdminEdit ||
                (createdByUser.value && isEditableMode.value) ||
                !props.entityDetails
            )
        })

        // Fields which employee can edit, will be view under following conditions
        const isEmployeeViewFieldEnabled = computed(() => {
            return (
                !props.formEditable &&
                props.entityDetails &&
                !(createdByUser.value && props.formEditable)
            )
        })

        // Fields which employee can edit, will be editable under following conditions
        const isEmployeeEditFieldEnabled = computed(() => {
            return (
                props.formEditable ||
                !props.entityDetails ||
                (createdByUser.value && props.formEditable)
            )
        })

        // set translatable values of each dropdowns
        const setTranslatableDropdownValues = () => {
            const taskDetails = props.entityDetails
            const taskType = taskTypes.value.find(
                (type) => type.id === (taskDetails.taskType && taskDetails.taskType.id)
            )
            if (taskType) translatableDropdownValues.value.taskType = taskType.value

            const taskPriority = taskPriorities.value.find(
                (priority) =>
                    priority.id === (taskDetails.taskPriority && taskDetails.taskPriority.id)
            )
            if (taskPriority) translatableDropdownValues.value.taskPriority = taskPriority.value

            const taskStratergy = taskRepeatStrategies.value.find(
                (stratergy) =>
                    stratergy.key === (taskDetails.repeatStrategy && taskDetails.repeatStrategy.key)
            )

            if (taskStratergy) translatableDropdownValues.value.taskStratergy = taskStratergy.value

            if (taskDetails.frequencyUnit)
                translatableDropdownValues.value.taskFrequencyUnit = utils.keyToName(
                    TASK_DATA.INTERVALS,
                    taskDetails.frequencyUnit.key
                )
        }

        const setDropdownValues = () => {
            // set employee list
            employees.value = []
            users.value &&
                users.value.map((user) => {
                    // get user logged in organisation(current org)
                    const currentOrganisation = user.organisations.filter(
                        (org) => org.id === Number(organisationId)
                    )
                    // get users who are available in current organisation with 'Employee' role
                    const filteredUsers =
                        currentOrganisation[0] &&
                        !!currentOrganisation[0].roles.find(
                            (role) => role.name === UserRole.EMPLOYEE
                        ) &&
                        user.userExpired !== true
                    if (filteredUsers) {
                        employees.value.push(user)
                    }
                })

            // feeding values for task completed dropdown
            taskCompletedDropdown.value = []
            generalData.YESNO.map((yesNo) => {
                taskCompletedDropdown.value.push({
                    key: yesNo.KEY,
                    value: t(yesNo.VALUE, language.value),
                })
            })

            // feeding values for task frequency dropdown
            taskFrequencies.value = []

            TASK_DATA.INTERVALS.map((interval) => {
                taskFrequencies.value.push({
                    key: interval.KEY,
                    value: tc(interval.VALUE, 0),
                })
            })

            // feeding values for task priority dropdown
            taskPriorities.value = []
            taskDropdownValues.taskPriorities.map((priority) => {
                taskPriorities.value.push({
                    id: priority.id,
                    key: priority.key,
                    value:
                        language.value === variables.LANGUAGES.DUTCH
                            ? priority.name_nl
                            : priority.name_en,
                })
            })

            // feeding values for task types
            taskTypes.value = []
            taskDropdownValues.taskTypes.map((type) => {
                taskTypes.value.push({
                    id: type.id,
                    key: type.key,
                    value:
                        language.value === variables.LANGUAGES.DUTCH ? type.name_nl : type.name_en,
                })
            })

            taskRepeatStrategies.value = []
            taskDropdownValues.taskRepeatStrategies.map((stratergy) => {
                taskRepeatStrategies.value.push({
                    id: stratergy.id,
                    key: stratergy.key,
                    value:
                        language.value === variables.LANGUAGES.DUTCH
                            ? stratergy.name_nl
                            : stratergy.name_en,
                })
            })
        }
        
        watch(
            [
                () => props.entityDetails,
                () => props.editFormType,
            ],
            (
                [taskDetails, formType]: [TaskDTO?, {COPY?: boolean; UPDATE?: boolean}?]
            ) => {
                // Load dropdown values from backend and initialize dropdown options
                setDropdownValues()
                if (taskDetails) {
                    createdByUser.value = (taskDetails.created_by.username !== variables.DEFAULT_USERNAME)
                    // Set translated values for viewing task
                    setTranslatableDropdownValues()
                    // Initialize the form data
                    const formData: TaskFormDTO = formType?.COPY
                        // We are creating a new task, which is based on provided taskDetails
                        ? copyTaskFormData(
                            loggedInUser.user,
                            role.value,
                            props.entityDetails,
                        )
                        // We are editing an existing task
                        : initializeUpdateForm(
                            taskDetails
                        )
                    // Use Object.assign instead of `TASK_FORM = ...`
                    // because TASK_FORM is a reactive and we cannot assign
                    // directly to it.
                    Object.assign(
                        TASK_FORM,
                        formData
                    )
                } else {
                    // We are creating a new task without a template
                    Object.assign(
                        TASK_FORM,
                        newTaskFormData(
                            loggedInUser.user,
                            role.value,
                        )
                    )
                }
            },
            {
                immediate: true,
                deep: true,
            }
        )


        return {
            t,
            language,
            labelInfomationOpDescription,
            labelInfomationOpFullDescription,
            labelInfomationOpDetailsType,
            labelInfomationOpDetailsFrequency,
            labelInfomationOpOwner,
            labelInfomationOpExecutedby,
            labelInfomationOpStartDate,
            labelInfomationOpEndDate,
            labelInfomationOpTaskCompleted,
            labelInfomationOpDocumentLink,
            labelInfomationOpNotes,
            labelInfomationOpDetailsPriority,
            isSuperAdminViewFieldEnabled,
            TASK_FORM,
            isSuperAdminEditFieldEnabled,
            isEditableMode,
            errors,
            toggleInformation,
            employees,
            moment,
            taskCompletedDropdown,
            isEmployeeViewFieldEnabled,
            isEmployeeEditFieldEnabled,
            goToRecord,
            setTaskEndDate,
            taskFrequencies,
            utils,
            YesNo,
            taskPriorities,
            taskTypes,
            translatableDropdownValues,
            validateForm,
            cancelForm,
            checkInvalidDate,
            taskRepeatStrategies,
            TaskStrategies,
        }
    },
})
