






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, inject, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import moment from 'moment'
import controldetailsDropdownData from '@/assets/data/controls-data.json'
import { TranslateResult } from 'vue-i18n'
import utils from '@/shared/mixins/utils'
import controlQueries from '@/shared/queries/controlQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import generalData from '@/assets/data/general-data.json'
import { YesNo } from '@/shared/enum/general-enum'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'
import InfoIcon from '@/shared/components/InfoIcon.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        editable: {
            type: Boolean,
        },
        superAdminEdit: {
            type: Boolean,
        },
        createdByUser: {
            type: Boolean,
        },
        riskList: {
            type: Array,
        },
        isConnectedControl: {
            type: Boolean,
        },
    },
    components: {
        DocumentationLink,
        InfoIcon,
    },
    setup(props) {
        const { t, tc } = useI18n()
        const { language } = useState(['language'])
        const executionTypeDropdow = ref([])
        const intervalDropDown = ref([])
        const applicableDropdown = ref([])
        const textType = ref(null)
        const BIVComp = ref(null)
        const formData = ref({
            BIV: {
                B: YesNo.NO,
                I: YesNo.NO,
                V: YesNo.NO,
            },
            PROCESS: '',
            VALID_FROM: new Date(),
            VALID_THRU: null,
            EXECUTION_TYPE: null,
            EXECUTION_GUIDE: '',
            CONTROL_EXPLANATION: '',
            EXECUTION_INTERVAL_UNIT: 0,
            EXECUTION_INTERVAL_VALUE: 0,
            ASSESSMENT_INTERVAL_UNIT: 0,
            ASSESSMENT_INTERVAL_VALUE: 0,
            APPLICABLE: 1,
            APPLICABLE_REMARK: '',
            IMPLEMENTATION_GUIDE: '',
            ADDITIONAL_INFORMATION: '',
            CONSIDERATION: '',
            RISKS: [],
            ASSETS: [],
            DOCUMENTATION_URL: '',
            CYBER_SECURITY_CONCEPTS: [],
            OPERATIONAL_CAPABILITIES: [],
            SECURITY_DOMAIN: [],
        })
        const executionTypeName: Ref<TranslateResult> = ref('')
        const exPeriodUnitName: Ref<string> = ref('')
        const exAssessmentUnitName: Ref<string> = ref('')
        const applicableSelect: Ref<TranslateResult> = ref('')

        const labelInfomationOpProcess = ref(null)
        const labelInfomationOpApplicable = ref(null)
        const labelInfomationOpApplicablePeriod = ref(null)
        const labelInfomationOpValidFrom = ref(null)
        const labelInfomationOpValidThru = ref(null)
        const labelInfomationOpConsiderations = ref(null)
        const labelInfomationOpImplementationGuide = ref(null)
        const labelInfomationOpAdditionalInfomation = ref(null)
        const labelInfomationOpExecutionType = ref(null)
        const labelInfomationOpExecutionGuide = ref(null)
        const labelInfomationOpExecutionControlPeriod = ref(null)
        const labelInfomationOpExecutionAssessment = ref(null)
        const labelInfomationOpNote = ref(null)
        const labelInfomationOpimpactConfidentiality = ref(null)
        const labelInfomationOpimpactIntegrity = ref(null)
        const labelInfomationOpimpactAvailability = ref(null)
        const labelInfomationOpDocumentationUrl = ref(null)
        const labelInfomationOpSecurityDomain = ref(null)
        const labelInfomationOpOperationalCap = ref(null)
        const labelInfomationOpCyberSecurity = ref(null)
        const disableValidFromThru = ref(false)
        const BIVdropdown = ref([])
        const assetsList = ref([])
        const errors = ref({
            EXECUTION_INTERVAL_UNIT: { missingValue: false },
            EXECUTION_INTERVAL_VALUE: { missingValue: false },
            ASSESSMENT_INTERVAL_UNIT: { missingValue: false },
            ASSESSMENT_INTERVAL_VALUE: { missingValue: false },
        })

        // injectors
        const controlDropdowns: Ref = inject('controlDropdowns')

        const setDropdownValues = () => {
            // set biv dropdown values
            BIVdropdown.value = []

            generalData.YESNO.map((biv) => {
                BIVdropdown.value.push({
                    key: biv.KEY,
                    value: t(biv.VALUE, language.value),
                })
            })
        }

        // execution type droodown
        const setExecutionTypeDropdownValues = () => {
            executionTypeDropdow.value = [{ id: 0, value: '-' }]

            controldetailsDropdownData.EXECUTION_TYPE.map((execution) => {
                executionTypeDropdow.value.push({
                    id: execution.ID,
                    value: t(execution.VALUE, language.value),
                })
            })
        }

        // set applicable dropdown
        const setEffectiveDropdown = () => {
            applicableDropdown.value = []

            controldetailsDropdownData.APPLICABLE.map((applicable) => {
                applicableDropdown.value.push({
                    id: applicable.ID,
                    value: t(applicable.VALUE, language.value),
                })
            })
        }

        // interval dropdown
        const setIntervalDropdownValues = () => {
            intervalDropDown.value = []

            controldetailsDropdownData.INTERVALS.map((interval) => {
                intervalDropDown.value.push({
                    id: interval.ID,
                    value: tc(interval.VALUE, 0),
                })
            })
        }

        // convert id values to name values
        const convertIdToNames = () => {
            //if applicable is true, mapping id of Yes (1) or No(2) from Yes/No dropdown
            const applicableId = props.entityDetails.applicable ? 1 : 2

            const executeType = utils.idToName(
                controldetailsDropdownData.EXECUTION_TYPE,
                props.entityDetails.executionType
            )
            executionTypeName.value = t(executeType, language.value)

            exPeriodUnitName.value = utils.idToName(
                controldetailsDropdownData.INTERVALS,
                props.entityDetails.executionIntervalUnit
            )

            exAssessmentUnitName.value = utils.idToName(
                controldetailsDropdownData.INTERVALS,
                props.entityDetails.assessmentIntervalUnit
            )

            const applicable = utils.idToName(controldetailsDropdownData.APPLICABLE, applicableId)
            applicableSelect.value = t(applicable, language.value)
        }

        // clear form fields
        const clearFields = () => {
            formData.value = {
                BIV: {
                    B: YesNo.NO,
                    I: YesNo.NO,
                    V: YesNo.NO,
                },
                PROCESS: '',
                VALID_FROM: new Date(),
                VALID_THRU: null,
                EXECUTION_TYPE: null,
                EXECUTION_GUIDE: '',
                CONTROL_EXPLANATION: '',
                EXECUTION_INTERVAL_UNIT: 0,
                EXECUTION_INTERVAL_VALUE: 0,
                ASSESSMENT_INTERVAL_UNIT: 0,
                ASSESSMENT_INTERVAL_VALUE: 0,
                APPLICABLE: 1,
                APPLICABLE_REMARK: '',
                IMPLEMENTATION_GUIDE: '',
                ADDITIONAL_INFORMATION: '',
                CONSIDERATION: '',
                RISKS: [],
                ASSETS: [],
                DOCUMENTATION_URL: '',
                CYBER_SECURITY_CONCEPTS: [],
                OPERATIONAL_CAPABILITIES: [],
                SECURITY_DOMAIN: [],
            }
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        /*If applicable is true display valid_from and valid_thru.
        valid_from must set to today date by default unless it doesn't have a value. If applicable to false, date periods should be hidden */
        const applicableChange = () => {
            // 1= yes , 2 = no
            disableValidFromThru.value = formData.value.APPLICABLE === 1 ? false : true
            const controlDetails = props.entityDetails
            // when applicable value is 'No'
            if (formData.value.APPLICABLE === 2) {
                formData.value.VALID_FROM = null
                formData.value.VALID_THRU = null
            } else {
                formData.value.VALID_FROM = controlDetails?.validFrom
                    ? moment(controlDetails.validFrom).toDate()
                    : new Date()
            }
        }

        // set form data
        const setFormData = () => {
            const controlDetails = props.entityDetails

            formData.value = {
                BIV: {
                    B: controlDetails.impactAvailability ? YesNo.YES : YesNo.NO,
                    I: controlDetails.impactIntegrity ? YesNo.YES : YesNo.NO,
                    V: controlDetails.impactConfidentiality ? YesNo.YES : YesNo.NO,
                },
                PROCESS: controlDetails.process,
                VALID_FROM: controlDetails.validFrom
                    ? moment(controlDetails.validFrom).toDate()
                    : new Date(),
                VALID_THRU: controlDetails.validThru && moment(controlDetails.validThru).toDate(),
                EXECUTION_TYPE: controlDetails.executionType,
                EXECUTION_GUIDE: controlDetails.executionGuide,
                CONTROL_EXPLANATION: controlDetails.controlExplanation,
                EXECUTION_INTERVAL_UNIT: controlDetails.executionIntervalUnit,
                EXECUTION_INTERVAL_VALUE: controlDetails.executionIntervalValue,
                ASSESSMENT_INTERVAL_UNIT: controlDetails.assessmentIntervalUnit,
                ASSESSMENT_INTERVAL_VALUE: controlDetails.assessmentIntervalValue,
                APPLICABLE: controlDetails.applicable ? 1 : 2,
                APPLICABLE_REMARK: controlDetails.applicableReason,
                IMPLEMENTATION_GUIDE: controlDetails.implementationGuide,
                ADDITIONAL_INFORMATION: controlDetails.implementationGuideAdditionalInformation,
                CONSIDERATION: controlDetails.consideration,
                RISKS: controlDetails.risks.map((risk) => risk.id),
                ASSETS: controlDetails.assets.map((asset) => asset.id),
                DOCUMENTATION_URL: controlDetails.documentationUrl,
                CYBER_SECURITY_CONCEPTS: controlDetails.cyberSecurityConcepts.map(
                    (csConcept) => csConcept.id
                ),
                OPERATIONAL_CAPABILITIES: controlDetails.operationalCapabilities.map(
                    (opCapability) => opCapability.id
                ),
                SECURITY_DOMAIN: controlDetails.securityDomains.map(
                    (securityDomain) => securityDomain.id
                ),
            }

            applicableChange()
            convertIdToNames()
        }

        // reset fields on cancel
        const cancelForm = () => {
            setFormData()
            errors.value = {
                EXECUTION_INTERVAL_UNIT: { missingValue: false },
                EXECUTION_INTERVAL_VALUE: { missingValue: false },
                ASSESSMENT_INTERVAL_UNIT: { missingValue: false },
                ASSESSMENT_INTERVAL_VALUE: { missingValue: false },
            }
        }

        const validateAllFields = () => {
            errors.value.EXECUTION_INTERVAL_UNIT.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_UNIT,
                'number'
            )

            errors.value.EXECUTION_INTERVAL_VALUE.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_VALUE,
                'number'
            )

            errors.value.ASSESSMENT_INTERVAL_UNIT.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_UNIT,
                'number'
            )

            errors.value.ASSESSMENT_INTERVAL_VALUE.missingValue = utils.validateFields(
                formData.value.EXECUTION_INTERVAL_VALUE,
                'number'
            )
        }

        const validateForm = () => {
            // validate all fields when creating a new entity/ updating self created entity
            /* This validation conditions had to add as some madatory data of system entered records
                 are missing in db for employee.In order to avoid throwing validation errors while editing such data, this condition has added.
                 We can remove this once we have stable data in db. (after BE ticket #458 is completed)*/
            if (
                !props.entityDetails ||
                props.createdByUser ||
                (props.editable && !props.createdByUser)
            )
                validateAllFields()
        }

        // get all assets
        const getAssets = async () => {
            const getAssetsQuery = `
                query{
                    assets{
                        items{
                            ${controlQueries.ASSETS_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getAssetsQuery)
            } catch (err) {
                throw Error('Error while retrieving assets')
            }

            if (result) {
                // Copy array to be able to sort it in place
                const assets = [...result.data.assets.items]
                // sort list by refId
                assets.sort((a, b) => (a.refId > b.refId ? 1 : -1))
                assets.map((asset) => {
                    const assetCopy = Object.create(asset)
                    // make the label unique
                    assetCopy.label = asset.refId + ' ' + asset.description
                    assetsList.value.push(assetCopy)
                })
            }
        }

        // Fields which super admin can edit, will be view under following conditions
        const isSuperAdminViewFieldEnabled = computed(() => {
            return (
                !props.superAdminEdit &&
                !(props.createdByUser && props.editable) &&
                props.entityDetails
            )
        })

        // Fields which super admin can edit, will be editable under following conditions
        const isSuperAdminEditFieldEnabled = computed(() => {
            return (
                props.superAdminEdit ||
                (props.createdByUser && props.editable) ||
                !props.entityDetails
            )
        })

        // Fields which employee can edit, will be view under following conditions
        const isEmployeeViewFieldEnabled = computed(() => {
            return (
                !props.editable && props.entityDetails && !(props.createdByUser && props.editable)
            )
        })

        // Fields which employee can edit, will be editable under following conditions
        const isEmployeeEditFieldEnabled = computed(() => {
            return props.editable || !props.entityDetails || (props.createdByUser && props.editable)
        })

        // display connected risk edit field uder following conditions.
        const isConnectRiskEditEnabled = computed(() => {
            return (
                (!props.editable && !props.entityDetails) || (props.createdByUser && props.editable)
            )
        })

        /* display super admin editable string fields under following conditions
        (#715, #795 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableSuperAdminStringFields = (field: string) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field] !== '' &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.superAdminEdit ||
                !props.entityDetails ||
                (props.createdByUser && props.editable)
            )
        }

        /* display super admin editable list fields under following conditions
        (#715 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableSuperAdminListFields = (field: string) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field].length > 0 &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.superAdminEdit ||
                !props.entityDetails ||
                (props.createdByUser && props.editable)
            )
        }

        /* display employee editable string fields under following conditions
        (#715, #795 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableEmployeeStringFields = (field) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field] !== '' &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.editable ||
                !props.entityDetails
            )
        }

        /* display employee editable dropdown fields where reading value is a number under following conditions
        (#715, #795 - When fields have no data, they should not be shown in the details overview in View mode) */
        const enableEmployeeNumberValueFields = (field) => {
            return (
                (props.entityDetails &&
                    props.entityDetails[field] &&
                    props.entityDetails[field] !== 0 &&
                    (!props.superAdminEdit || !props.editable)) ||
                props.editable ||
                !props.entityDetails
            )
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return props.editable || props.superAdminEdit || !props.entityDetails
        })

        onMounted(async () => {
            setExecutionTypeDropdownValues()
            setIntervalDropdownValues()
            setEffectiveDropdown()
            setDropdownValues()
            if (props.entityDetails) {
                setFormData()
            }
            await getAssets()
        })

        // watch for language chaneges and update dropdowns
        watch(language, () => {
            setExecutionTypeDropdownValues()
            setIntervalDropdownValues()
            setEffectiveDropdown()
            setDropdownValues()
            if (props.entityDetails) {
                convertIdToNames()
            }
        })

        return {
            t,
            language,
            moment,
            formData,
            executionTypeDropdow,
            intervalDropDown,
            executionTypeName,
            exPeriodUnitName,
            exAssessmentUnitName,
            applicableDropdown,
            applicableSelect,
            textType,
            labelInfomationOpProcess,
            labelInfomationOpApplicable,
            labelInfomationOpApplicablePeriod,
            labelInfomationOpValidFrom,
            labelInfomationOpValidThru,
            labelInfomationOpConsiderations,
            labelInfomationOpImplementationGuide,
            labelInfomationOpAdditionalInfomation,
            labelInfomationOpExecutionType,
            labelInfomationOpExecutionGuide,
            labelInfomationOpExecutionControlPeriod,
            labelInfomationOpExecutionAssessment,
            labelInfomationOpNote,
            toggleInformation,
            BIVComp,
            cancelForm,
            utils,
            errors,
            validateForm,
            applicableChange,
            disableValidFromThru,
            clearFields,
            BIVdropdown,
            assetsList,
            labelInfomationOpimpactConfidentiality,
            labelInfomationOpimpactIntegrity,
            labelInfomationOpimpactAvailability,
            labelInfomationOpDocumentationUrl,
            isSuperAdminViewFieldEnabled,
            isSuperAdminEditFieldEnabled,
            isEmployeeViewFieldEnabled,
            isEmployeeEditFieldEnabled,
            isConnectRiskEditEnabled,
            labelInfomationOpCyberSecurity,
            labelInfomationOpOperationalCap,
            labelInfomationOpSecurityDomain,
            YesNo,
            controlDropdowns,
            enableEmployeeStringFields,
            enableSuperAdminStringFields,
            enableSuperAdminListFields,
            enableEmployeeNumberValueFields,
            isEditableMode,
        }
    },
})
