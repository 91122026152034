const TAB_TASK_LIST = `
    id
    refId
    owner{
        id
        username
        firstName
        lastName
    }
    executedBy{
        id
        firstName
        lastName  
    }
    status{
        name_en
        name_nl
    }
    startDate
    endDate
    description
    `

const TASK_LIST = `
    id
    refId
    alertColor{
        color
        info
        infoKey
    }
    owner{
        id
        username
        firstName
        lastName
    }
    executedBy{
        id
        firstName
        lastName  
    }
    status{
        name_en
        name_nl
    }
    relatedControl{
        controlNumber
    }
    relatedMsr{
        msrNumber
    }
    taskPriority{
        name_en
        name_nl
    }
    relatedMsrNorm{
        name
    }
    relatedNorm{
        name
    }
    startDate
    endDate
    description
    `

const TASK_DETAILS = `
    id
    refId
    order
    owner{
        id
        username
        firstName
        lastName
    }
    repeatStrategy{
        key
        name_en
        name_nl
    }
    status{
        key
        name_en
        name_nl
    }
    startDate
    endDate
    description
    alertColor{
        color
        info
        infoKey
    }
    fullDescription
    frequencyUnit{
        id
        key
    }
    frequencyValue
    documentLink
    notes
    taskPriority{
        id
        key
    }
    taskType{
        id
        key
    }
    taskCompleted
    executedBy{
        id
        firstName
        lastName
    }
    startDate
    endDate
    relatedControl{
        id
        controlNumber
        description
    }
    relatedMsr{
        id
        msrNumber
        description
    }
    created_by{
        id
        username
    }
    relatedNorm{
        name
    }
    relatedMsrNorm{
        name
    }
    `

const TASK_DROPDOWN_VALUES = `
    taskTypes{
        id
        key
        name_en
        name_nl
    }
    taskPriorities{
        id
        key
        name_en
        name_nl
    }
    taskStatuses{
        id
        key
        name_en
        name_nl    
    }
    taskFrequencies{
        id
        key
        name
        name_en
        name_nl
    }
    taskRepeatStrategies{
        id
        key
        name
        name_en
        name_nl
    }
    `

   export default { TAB_TASK_LIST, TASK_DETAILS, TASK_DROPDOWN_VALUES, TASK_LIST }