


































































import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import removeMd from 'remove-markdown'
import variables from '@/shared/variables'
import ControlDetails from '@/components/controls/ControlDetails.vue'

export default defineComponent({
    name: 'ControlOperations',
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
    },
    components: {
        ControlDetails,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const TabviewCmp = ref(null)
        const nameInitals = ref('')
        const connectedControlsCmp = ref(null)

        // go to record (previous/ next)
        const goToRecord = (params) => {
            emit('go-to-record', params)
        }

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.owner && props.entityDetails.owner.firstName) +
                ' ' +
                (props.entityDetails.owner && props.entityDetails.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            goToRecord,
            TabviewCmp,
            removeMd,
            nameInitals,
            variables,
            connectedControlsCmp,
        }
    },
})
