


























































































































































import utils from '@/shared/mixins/utils'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import Confirmation from '@/shared/components/Confirmation.vue'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import variables from '@/shared/variables'
import ControlSection from './ControlSection.vue'
import ControlDetailsSection from './ControlDetailsSection.vue'
import ControlAssessment from '../control-assessment/ControlAssessment.vue'
import { UserRole } from '@/shared/enum/general-enum'
import controlQueries from '@/shared/queries/controlQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import TemplateDocs from './TemplateDocs.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        enableEditSaveNext: {},
        isConnectedControl: {
            type: Boolean,
        },
    },
    components: {
        Confirmation,
        ControlSection,
        ControlDetailsSection,
        ControlAssessment,
        TemplateDocs,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const superAdminEdit = ref(false)
        const createdByUser = ref(false)
        const editable = ref(false)
        const controlSectionCmp = ref(null)
        const controlDetailsSectionCmp = ref(null)
        const normsCreatedByUser = ref([])
        const { role } = useState(['role'])
        const saveType = ref('')
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const loading = ref(false)
        const { normTypes } = useState(['normTypes'])
        const { selectedNorms } = useState(['selectedNorms'])
        const riskList = ref([])

        // convert id values to name values
        const setFormattedDetails = () => {
            const createdByUsername =
                props.entityDetails.created_by && props.entityDetails.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false
        }

        const enableEditButton = computed(() => {
            return (
                !superAdminEdit.value &&
                !editable.value &&
                (role.value === UserRole.EMPLOYEE ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN) &&
                props.entityDetails &&
                !props.isConnectedControl
            )
        })

        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.entityDetails.no, type })
        }

        // enable edit fields
        const enableEdit = () => {
            if (role.value === UserRole.SUPER_ADMIN) {
                superAdminEdit.value = true
            } else {
                editable.value = true
            }
        }
        // cancel edit
        const cancelEdit = () => {
            editable.value = false
            superAdminEdit.value = false
            if (props.entityDetails) {
                controlSectionCmp.value.cancelForm()
                controlDetailsSectionCmp.value.cancelForm()
            } else {
                emit('close-create-new')
            }
        }

        // save control data
        const save = (type) => {
            controlDetailsSectionCmp.value.validateForm()
            controlSectionCmp.value.validateForm()

            if (
                utils.hasErrors(controlDetailsSectionCmp.value.errors) ||
                utils.hasErrors(controlSectionCmp.value.errors)
            ) {
                return
            }

            const formData = Object.assign(
                controlSectionCmp.value.formData,
                controlDetailsSectionCmp.value.formData
            )
            if (props.entityDetails) {
                emit('save-control', {
                    id: props.entityDetails.id,
                    formData: formData,
                    createdBy: createdByUser.value,
                    type,
                    no: props.entityDetails.no,
                })
            } else {
                emit('save-control', {
                    formData: formData,
                })
            }
        }

        // open save confirmation
        const openSaveConfirmation = (type) => {
            saveType.value = type
            if (props.entityDetails) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                save(type)
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            if (props.entityDetails) {
                displayCancelConfirmation.value = true
            } else {
                // for new create entity
                cancelEdit()
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            save(saveType.value)
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancelEdit()
        }

        const getControls = () => {
            emit('get-controls')
        }

        // get client config norms data
        const getClientConfig = async () => {
            // filter norms by created user
            const filteredNorms = selectedNorms.value.filter(
                (norm) => norm.created_by && norm.created_by.username !== variables.DEFAULT_USERNAME
            )
            // update object array with order
            normsCreatedByUser.value = filteredNorms.map((norm, index) => {
                const copyofNorm = Object.assign({}, norm)
                copyofNorm.order = index + 1
                return copyofNorm
            })
        }

        // get risks list
        const getRisks = async () => {
            const riskQuery = `
                query{
                    risks{
                        items{
                            ${controlQueries.RISK_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(riskQuery, null)
            } catch {
                throw Error('Error while retrieving risks')
            }

            if (result) {
                // Copy array to be able to sort it in place
                const risks = result?.data?.risks?.items ? [...result.data.risks.items] : []
                // sort list by refId
                risks.sort((a, b) => (a.refId > b.refId ? 1 : -1))
                risks.map((risk) => {
                    const riskCopy = Object.create(risk)
                    // make the label unique
                    riskCopy.label = risk.refId + ' ' + risk.description
                    riskList.value.push(riskCopy)
                })
            }
        }

        onMounted(async () => {
            getClientConfig()
            await getRisks()
            if (props.entityDetails) {
                setFormattedDetails()
            }
        })

        return {
            t,
            language,
            utils,
            goToRecord,
            editable,
            enableEdit,
            superAdminEdit,
            createdByUser,
            cancelEdit,
            save,
            role,
            variables,
            controlSectionCmp,
            controlDetailsSectionCmp,
            successCancelConfirmation,
            successSaveConfirmation,
            openCancelConfirmation,
            openSaveConfirmation,
            displayCancelConfirmation,
            displaySaveConfirmation,
            saveType,
            getControls,
            UserRole,
            normsCreatedByUser,
            loading,
            enableEditButton,
            riskList,
        }
    },
})
