










































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, onMounted, ref, watch, nextTick } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import TaskRegisterDetails from '@/components/task-register/TaskRegisterDetails.vue'
import { useToast } from 'vue-toastification/composition'
import removeMd from 'remove-markdown'
import utils from '@/shared/mixins/utils'
import { Status, YesNo } from '@/shared/enum/general-enum'
import { TaskStatus } from '@/shared/enum/tasks-enum'
import variables from '@/shared/variables'
import Confirmation from '@/shared/components/Confirmation.vue'
import { UserRole } from '@/shared/enum/general-enum'
import taskQueries from '@/shared/queries/taskQueries'
import { TaskFormDTO } from '@/dto/forms/taskFormDTO'

export default defineComponent({
    components: {
        TaskRegisterDetails,
        Confirmation,
    },
    props: {
        msrDetails: {
            type: Object,
        },
        controlDetails: {
            type: Object,
        },
        lastRecordNo: {},
        recordNo: {
            type: String,
        },
        taskId: {
            type: Number,
        },
        firstRecordNo: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const taskDetails = ref(null)
        const TaskRegisterDetails = ref(null)
        const formEditable = ref(false)
        const toast = useToast()
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const disable = ref(false)
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const normName = ref('')
        const controlMSRnumber = ref('')
        const parentDescription = ref('')
        const nameInitals = ref('')
        const routedFrom = ref('')
        const loading = ref(false)
        const taskRegisterInfoText = ref('')
        const superAdminEdit = ref(false)
        const createdByUser = ref(false)
        const saveType = ref('')
        const displayActivateConfirmation = ref(false)
        const displayDoneConfirmation = ref(false)
        const editFormType = ref({
            UPDATE: false,
            COPY: false,
        })
        const fullDescriptionWidth = ref(150)
        const subTitleDescWidth = ref(150)
        const displayCopyConfirmation = ref(false)

        const isTaskCompleted = computed(() => {
            return taskDetails.value && taskDetails.value.taskCompleted
        })

        // go to record (previous/ next)
        const goToRecord = (type: string) => {
            emit('go-to-record', { no: props.recordNo, type })
        }

        const buttonEnable = () => {
            if (taskDetails.value) {
                formEditable.value = false
                superAdminEdit.value = false
                editFormType.value.COPY = false
                editFormType.value.UPDATE = false
            } else {
                formEditable.value = true
            }
        }

        // enable edit fields
        const enableEdit = () => {
            if (role.value === UserRole.SUPER_ADMIN) {
                superAdminEdit.value = true
            } else {
                formEditable.value = true
            }
        }

        // turn all fields to formEditable including system created records
        const enableChoose = () => {
            superAdminEdit.value = true
            formEditable.value = true
            editFormType.value.COPY = true
            editFormType.value.UPDATE = false
        }

        const setTaskConfigurations = () => {
            // set alert color and info
            taskDetails.value.color = utils.getAlertInfo('', taskDetails.value).color
            taskDetails.value.info = utils.getAlertInfo('', taskDetails.value).info
            taskDetails.value.infoKey = utils.getAlertInfo('', taskDetails.value).infoKey

            taskDetails.value.no = utils.padLeft('000', taskDetails.value.refId)
            const fullNameOwner =
                (taskDetails.value.owner && taskDetails.value.owner.firstName) +
                ' ' +
                (taskDetails.value.owner && taskDetails.value.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
            const controlRelated = taskDetails.value.relatedControl
            taskDetails.value.isControlRelated = !!controlRelated

            // set parent data
            // when the parent is control
            if (taskDetails.value.isControlRelated) {
                normName.value = taskDetails.value.relatedNorm && taskDetails.value.relatedNorm.name
                controlMSRnumber.value =
                    taskDetails.value.relatedControl &&
                    taskDetails.value.relatedControl.controlNumber
                parentDescription.value = removeMd(
                    taskDetails.value.relatedControl && taskDetails.value.relatedControl.description
                )
            } else {
                // when the parent is MSR
                normName.value =
                    taskDetails.value.relatedMsrNorm && taskDetails.value.relatedMsrNorm.name
                controlMSRnumber.value =
                    taskDetails.value.relatedMsr && taskDetails.value.relatedMsr.msrNumber
                parentDescription.value = removeMd(
                    taskDetails.value.relatedMsr && taskDetails.value.relatedMsr.description
                )
            }
            const createdByUsername =
                taskDetails.value.created_by && taskDetails.value.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false

            if (taskDetails.value.status)
                taskDetails.value.fstatus = {
                    name:
                        language.value === variables.LANGUAGES.DUTCH
                            ? taskDetails.value.status.name_nl
                            : taskDetails.value.status.name_en,
                    key: taskDetails.value.status.key,
                }
        }

        const initialize = () => {
            taskDetails.value = null
            formEditable.value = false
        }

        // get task details
        const getTaskDetails = async () => {
            loading.value = true
            initialize()
            let result
            const getRiskDetailQuery = `
                query{
                    task(id: ${props.taskId}){
                        task {
                            ${taskQueries.TASK_DETAILS}
                            }
                        error
                    }
                    
                }
            `
            try {
                result = await apolloClient.getGraphqlData(getRiskDetailQuery)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving task details')
            }
            if (result) {
                taskDetails.value = result.data.task && result.data.task.task
                setTaskConfigurations()
                buttonEnable()
            }
        }

        // get task request input for create
        const getCreateTaskInput = (taskFormData: TaskFormDTO) => {
            let relatedMSR
            let relatedControl
            if (props.msrDetails) {
                relatedMSR = props.msrDetails && props.msrDetails.id
            } else {
                relatedControl = props.controlDetails && props.controlDetails.id
            }

            return {
                // copied record from a task
                createdFrom: editFormType.value.COPY
                    ? taskDetails.value && taskDetails.value.id
                    : null,
                description: taskFormData.DESCRIPTION,
                fullDescription: taskFormData.FULL_DESCRIPTION,
                frequencyUnit: taskFormData.FREQUENCY_INTERVAL_UNIT,
                frequencyValue: Number(taskFormData.FREQUENCY_INTERVAL_VALUE),
                documentLink: taskFormData.DOCUMENT_LINK,
                owner: taskFormData.OWNER,
                executedBy: taskFormData.EXECUTED_BY === 0 ? null : taskFormData.EXECUTED_BY,
                notes: taskFormData.NOTES,
                taskPriority: taskFormData.PRIORITY,
                taskType: taskFormData.TYPE,
                startDate: taskFormData.START_DATE,
                endDate: taskFormData.END_DATE,
                relatedMsr: relatedMSR,
                relatedControl: relatedControl,
                repeatStrategy: taskFormData.TASK_STRATERGY,
            }
        }

        // get task request input for update
        const getUpdateTaskInput = (taskFormData: TaskFormDTO) => {
            return {
                description: taskFormData.DESCRIPTION,
                fullDescription: taskFormData.FULL_DESCRIPTION,
                frequencyUnit: taskFormData.FREQUENCY_INTERVAL_UNIT,
                frequencyValue: Number(taskFormData.FREQUENCY_INTERVAL_VALUE),
                documentLink: taskFormData.DOCUMENT_LINK,
                owner: taskFormData.OWNER,
                executedBy: taskFormData.EXECUTED_BY === 0 ? null : taskFormData.EXECUTED_BY,
                notes: taskFormData.NOTES,
                taskPriority: taskFormData.PRIORITY,
                taskType: taskFormData.TYPE,
                startDate: taskFormData.START_DATE,
                endDate: taskFormData.END_DATE,
                repeatStrategy: taskFormData.TASK_STRATERGY,
                taskCompleted: taskFormData.TASK_COMPLETED === YesNo.YES ? true : false,
            }
        }

        // get task request input for update master data (superadmin)
        const getMasterUpdateTaskInput = (taskFormData: TaskFormDTO) => {
            return {
                description: taskFormData.DESCRIPTION,
                fullDescription: taskFormData.FULL_DESCRIPTION,
                taskType: taskFormData.TYPE,
            }
        }

        const postSaveConfigurations = () => {
            // display confirmation of copy when user updated `taskCompleted` to true
            if (taskDetails.value.taskCompleted) {
                displayCopyConfirmation.value = true
            }
        }

        // save
        const saveNewTask = async () => {
            TaskRegisterDetails.value.validateForm()
            // check for validation errors
            if (utils.hasErrors(TaskRegisterDetails.value.errors)) {
                return
            }
            const taskFormData = TaskRegisterDetails.value.TASK_FORM
            const mutationQuery = `mutation ($input: TaskCreateInput!) {
                        createTask(input: $input) {
                            status
                            error
                        }
                    }`

            const input = getCreateTaskInput(taskFormData)

            // create task api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('TASK_CREATE_ERROR_MESSAGE', language.value))
                throw Error('Error while creating task')
            }

            if (result.data.createTask && result.data.createTask.status) {
                toast.success(t('TASK_CREATE_SUCESS_MESSAGE', language.value))
                emit('update-records')
                emit('close-task-register')
            } else {
                toast.error(t('TASK_CREATE_ERROR_MESSAGE', language.value))
            }
        }

        // updating task details data
        const saveTaskDetails = async () => {
            TaskRegisterDetails.value.validateForm()
            // check for validation errors
            if (utils.hasErrors(TaskRegisterDetails.value.errors)) {
                return
            }
            let mutationQuery
            const taskFormData = TaskRegisterDetails.value.TASK_FORM
            let input

            if (role.value === UserRole.SUPER_ADMIN) {
                // mutation query for super admin
                mutationQuery = `
                    mutation ($input: TaskMasterUpdateInput!) {
                        updateMasterTask(id: ${taskDetails.value.id}, input: $input) {
                            status
                            error
                        }
                    }`
                input = getMasterUpdateTaskInput(taskFormData)
            } else {
                // mutation query for other users
                mutationQuery = `
                    mutation ($input: TaskUpdateInput!) {
                        updateTask(id: ${taskDetails.value.id}, input: $input) {
                            status
                            error
                        }
                    }`
                input = getUpdateTaskInput(taskFormData)
            }

            // update data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('TASK_UPDATE_ERROR_MESSAGE', language.value))
                throw Error('Error while updating risk data')
            }
            // other user updates
            if (result.data.updateTask && result.data.updateTask.status) {
                emit('update-records')
                buttonEnable()
                // if user clicks on save and next button, post record save, it should go to next record
                if (saveType.value === 'save-next') {
                    await goToRecord('next')
                } else {
                    await getTaskDetails()
                    postSaveConfigurations()
                }
                toast.success(t('TASK_UPDATE_SUCESS_MESSAGE', language.value))
            } else if (result.data.updateMasterTask && result.data.updateMasterTask.status) {
                // super admin updates
                emit('update-records')
                buttonEnable()

                // if user clicks on save and next button, post record save, it should go to next record
                if (saveType.value === 'save-next') {
                    await goToRecord('next')
                } else {
                    await getTaskDetails()
                }
                toast.success(t('TASK_UPDATE_SUCESS_MESSAGE', language.value))
            } else {
                toast.error(t('TASK_UPDATE_ERROR_MESSAGE', language.value))
            }
        }

        //  on click of cancel button
        const cancel = () => {
            if (formEditable.value && taskDetails.value) {
                // if the document is in edit mode, it will exit back to view
                formEditable.value = false
            } else {
                // if you are in create new form, form fields will be clear
                TaskRegisterDetails.value.cancelForm()
                editFormType.value.COPY = false
            }
            emit('close-task-register')
        }

        // update task status
        const updateStatus = async (status: string) => {
            const mutationQuery = `mutation($input: TaskStatusInput!){
                        updateTaskStatus(id: ${Number(taskDetails.value.id)}, input: $input) {
                            status
                            error
                        }
                      }
                      `
            const input = {
                status,
            }
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                return 'error'
            }

            if (result) {
                getTaskDetails()
                emit('update-records')
                return 'success'
            } else {
                return 'error'
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // open save confirmation
        const openSaveConfirmation = () => {
            if (taskDetails.value && !editFormType.value.COPY) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                saveNewTask()
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            // cancel for edit task details
            if (taskDetails.value && !editFormType.value.COPY) {
                displayCancelConfirmation.value = true
            } else {
                // for new create entity
                cancel()
            }
        }

        // save confirm-dialog, on success
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            saveTaskDetails()
        }

        // cancel confirm-dialog, on success
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancel()
        }

        // activate confirm-dialog, on success
        const successActivateConfirmation = async () => {
            displayActivateConfirmation.value = false
            const status = await updateStatus(TaskStatus.INPROGRESS)
            if (status === 'success')
                toast.success(t('TASK_ACTIVATE_SUCCESS_MESSAGE', language.value))
            else toast.error(t('TASK_ACTIVATE_ERROR_MESSAGE', language.value))
        }

        // completed confirm-dialog, on success
        const successDoneConfirmation = async () => {
            displayDoneConfirmation.value = false
            const status = await updateStatus(TaskStatus.COMPLETED)
            if (status === 'success') toast.success(t('TASK_DONE_SUCCESS_MESSAGE', language.value))
            else toast.error(t('TASK_DONE_ERROR_MESSAGE', language.value))
        }

        // display edit button based on following conditions
        const isEditBtnEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !superAdminEdit.value &&
                !formEditable.value &&
                taskDetails.value &&
                roles.includes(role.value) &&
                createdByUser.value
            )
        })

        // enable super admin edit button under following conditions
        const isSuperAdminEditBtnEnabled = computed(() => {
            return (
                !superAdminEdit.value &&
                !formEditable.value &&
                role.value === UserRole.SUPER_ADMIN &&
                !createdByUser.value &&
                taskDetails.value
            )
        })

        /* enable choose button for system created tasks when they are in Not activate status(only system 
        created items will be in not active status) */
        const isChooseBtnEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                roles.includes(role.value) &&
                taskDetails.value &&
                !superAdminEdit.value &&
                !formEditable.value &&
                taskDetails.value.fstatus.key === TaskStatus.TEMPLATE
            )
        })

        /* enable copy button for user created tasks when they are in Completed status(only user created tasks will have
         completed status) */
        const isCopyBtnEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                roles.includes(role.value) &&
                taskDetails.value &&
                !superAdminEdit.value &&
                !formEditable.value &&
                taskDetails.value.fstatus.key === TaskStatus.COMPLETED
            )
        })

        /* enable activate button when they are in To start status */
        const isActivateBtnEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            const startDate = taskDetails.value && new Date(taskDetails.value.startDate)
            const today = new Date()
            return (
                roles.includes(role.value) &&
                !superAdminEdit.value &&
                !formEditable.value &&
                taskDetails.value &&
                taskDetails.value.fstatus &&
                taskDetails.value.fstatus.key === TaskStatus.TOSTART &&
                today >= startDate
            )
        })

        /* enable Done button when they are in inprogress status */
        const isDoneBtnEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                roles.includes(role.value) &&
                !superAdminEdit.value &&
                !formEditable.value &&
                taskDetails.value &&
                taskDetails.value.fstatus &&
                taskDetails.value.fstatus.key === TaskStatus.INPROGRESS
            )
        })

        const isCancelButtonEnabled = computed(() => {
            return formEditable.value || superAdminEdit.value || !taskDetails.value
        })

        const isSaveNextBtnEnabled = computed(() => {
            return (
                (formEditable.value || superAdminEdit.value) &&
                taskDetails.value &&
                !editFormType.value.COPY
            )
        })

        const isSaveBtnEnabled = computed(() => {
            return formEditable.value || !taskDetails.value || superAdminEdit.value
        })

        const isPreviousNextBtnEnabled = computed(() => {
            return !formEditable.value && taskDetails.value && !superAdminEdit.value
        })

        const resetWidth = () => {
            const titleWrap = document.querySelector('#task-title-wrap .title-wrapper')
            const entityNumber = document.querySelector(
                '#task-title-wrap .title-wrapper .entity-number'
            )
            const title = document.querySelector('#task-title-wrap .title-wrapper .title')
            if (titleWrap === null || entityNumber === null || title === null) {
                return
            }
            const parentWidth = titleWrap.clientWidth
            // full width of all elements exclude full desciption.
            const leftWidth = entityNumber.clientWidth + title.clientWidth
            const margin = 40 // safe margin in px to show elipsis
            // fullWidth is equal to "leftWidth + margin + fullDescriptionWidth"
            // because of that we can calculate width of fullDescription as follows
            fullDescriptionWidth.value = parentWidth - leftWidth - margin
        }
        const resetSubtitleWidth = () => {
            const titleWrap = document.querySelector('#task-title-wrap .sub-title')
            const entity = document.querySelector('#task-title-wrap .entity-number')
            if (titleWrap === null || entity === null) {
                return
            }
            const parentWidth = titleWrap.clientWidth
            // full width of all elements exclude full desciption.
            const leftWidth = entity.clientWidth
            const margin = 65 // safe margin in px to show elipsis
            // fullWidth is equal to "leftWidth + margin + fullDescriptionWidth"
            // because of that we can calculate width of fullDescription as follows
            subTitleDescWidth.value = parentWidth - leftWidth - margin
        }
        watch(
            parentDescription,
            () => {
                if (parentDescription.value) {
                    // using nextTick to make sure that the corresponding UI elements are rendered
                    nextTick(() => {
                        resetWidth()
                        resetSubtitleWidth()
                    })
                }
            },
            { immediate: false }
        )

        onMounted(() => {
            initialize()
        })

        //  watch for taskId changes
        watch(
            () => props.taskId,
            () => {
                if (props.taskId) {
                    getTaskDetails()
                    taskRegisterInfoText.value = 'TASK_DETAILS_VIEW_INFO_TEXT'
                } else {
                    taskRegisterInfoText.value = 'TASK_DETAILS_CREATE_INFO_TEXT'
                }
            },
            { immediate: true }
        )

        return {
            taskDetails,
            TaskRegisterDetails,
            cancel,
            language,
            formEditable,
            saveNewTask,
            t,
            toast,
            infomationOp,
            toggleInformation,
            Status,
            utils,
            role,
            variables,
            disable,
            successSaveConfirmation,
            successCancelConfirmation,
            openCancelConfirmation,
            openSaveConfirmation,
            displaySaveConfirmation,
            displayCancelConfirmation,
            nameInitals,
            routedFrom,
            goToRecord,
            loading,
            UserRole,
            taskRegisterInfoText,
            isEditBtnEnabled,
            enableEdit,
            superAdminEdit,
            normName,
            controlMSRnumber,
            parentDescription,
            removeMd,
            isSuperAdminEditBtnEnabled,
            isChooseBtnEnabled,
            enableChoose,
            saveType,
            isActivateBtnEnabled,
            isDoneBtnEnabled,
            updateStatus,
            TaskStatus,
            displayActivateConfirmation,
            successActivateConfirmation,
            displayDoneConfirmation,
            successDoneConfirmation,
            isCopyBtnEnabled,
            editFormType,
            fullDescriptionWidth,
            subTitleDescWidth,
            displayCopyConfirmation,
            isCancelButtonEnabled,
            isSaveNextBtnEnabled,
            isSaveBtnEnabled,
            isPreviousNextBtnEnabled,
            isTaskCompleted
        }
    },
})
