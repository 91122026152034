










































































































































































































import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import removeMd from 'remove-markdown'
import variables from '@/shared/variables'
import ControlDetails from '@/components/controls/ControlDetails.vue'
import AssetsList from './AssetsList.vue'
import Heatmap from '@/shared/components/Heatmap.vue'
import AssetControlAssessmentsList from '@/shared/components/ac-assessment/AssetControlAssessmentsList.vue'
import ControlAssessmentList from './ControlAssessmentList.vue'
import ConnectedControls from './ConnectedControls.vue'
import TasksList from '@/shared/components/task-list-tab/TasksList.vue'

export default defineComponent({
    name: 'ControlOperations',
    props: {
        entityDetails: {
            type: Object,
            required: true,
        },
    },
    components: {
        ControlDetails,
        AssetsList,
        Heatmap,
        AssetControlAssessmentsList,
        ControlAssessmentList,
        ConnectedControls,
        TasksList
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labels = ref({})
        const copyOfEntityDetails = ref({})
        const levelExplanation = ref('')
        const TabviewCmp = ref(null)
        const nameInitals = ref('')
        const assetControlAssessmentsListcmp = ref(null)
        const connectedControlsCmp = ref(null)

        // go to record (previous/ next)
        const goToRecord = (params) => {
            emit('go-to-record', params)
        }

        // save edited data
        const saveControl = (params) => {
            emit('save-control', params)
        }

        // format data before display
        const setFormattedDetails = () => {
            // owner
            const fullNameOwner =
                (props.entityDetails.owner && props.entityDetails.owner.firstName) +
                ' ' +
                (props.entityDetails.owner && props.entityDetails.owner.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)
        }

        const getControls = () => {
            emit('get-controls')
        }

        // refresh asset control assessments list
        const refreshAssessmentList = () => {
            assetControlAssessmentsListcmp.value &&
                assetControlAssessmentsListcmp.value.getAssetControlAssessments()
        }

        onMounted(() => {
            setFormattedDetails()
        })

        return {
            t,
            language,
            utils,
            labels,
            copyOfEntityDetails,
            levelExplanation,
            goToRecord,
            saveControl,
            TabviewCmp,
            removeMd,
            nameInitals,
            variables,
            getControls,
            assetControlAssessmentsListcmp,
            refreshAssessmentList,
            connectedControlsCmp,
        }
    },
})
