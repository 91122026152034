



















































import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import utils from '@/shared/mixins/utils'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import controlQueries from '@/shared/queries/controlQueries'
import AssetControlAssessment from '@/components/asset-control assessments/AssetControlAssessment.vue'
import moment from 'moment'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import controlAssessmentDropdownData from '@/assets/data/control-assessment-data.json'
import generalData from '@/assets/data/general-data.json'
import Table from '@/shared/components/Table.vue'

export default defineComponent({
    props: {
        assetId: {
            type: Number,
        },
        controlId: {
            type: Number,
        },
        accessedFrom: {
            type: String,
        },
    },
    components: {
        AssetControlAssessment,
        Table,
    },
    setup(props) {
        const assessmentsList = ref([])
        const displayAssetControlAssessment = ref(false)
        const loading = ref(false)
        const { t } = useI18n()
        const { language } = useState(['language'])
        const lastRecordNo = ref(null)
        const firstRecordNo = ref(null)
        const assetAssessmentRecordNo = ref('')
        const assessmentId = ref(null)
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const tableCmp = ref(null)

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    fieldName: 'refId',
                    style: 'min-width: 150px;',
                },
                // add norm and remove asset name (#919)
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_TABLE_COLUMN_NORM_NAME', language.value),
                    fieldName: 'norm',
                    style: 'width: 210px;',
                },
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_CONTROL_NUMBER', language.value),
                    fieldName: 'controlNumber',
                    style: 'min-width: 150px;',
                },
                {
                    header: t('ASSESSMENT_LABEL_EXECUTION_DATE', language.value),
                    fieldName: 'fexecutionDate',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_EXECUTED_BY', language.value),
                    fieldName: 'fexecutedBy',
                },
                {
                    header: t('ASSESSMENT_LABEL_MATURITY', language.value),
                    fieldName: 'maturity',
                },
                {
                    header: t('ASSESSMENT_LABEL_DESIGN', language.value),
                    fieldName: 'design',
                    effectiveness: true,
                },
                {
                    header: t('ASSESSMENT_LABEL_EXISTENCE', language.value),
                    fieldName: 'existence',
                    effectiveness: true,
                },
                {
                    header: t('ASSESSMENT_LABEL_OPERATIONAL_EFFECTIVENESS', language.value),
                    fieldName: 'operationalEffectiveness',
                    effectiveness: true,
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_STATUS', language.value),
                    fieldName: 'status',
                },
                {
                    header: t('CONTROL_ASSESSMENTS_TABLE_COLUMN_PERIOD', language.value),
                    fieldName: 'period',
                },
            ]
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, state)

            return t(stateVal, language.value)
        }

        // updating assessmentsList array with additional fields
        const toTableFormat = (tempResultData) => {
            assessmentsList.value = []
            tempResultData.map((acassessment) => {
                let aca = {
                    no: null,
                    fexecutedBy: '',
                    fexecutionDate: '',
                    status: null,
                    fmaturity: '',
                    fdesign: '',
                    fexistence: '',
                    foperationalEffectiveness: '',
                    period: '',
                    norm: '',
                    controlNumber: '',
                }
                // creating a copy of asset object
                aca = Object.create(acassessment)
                // creating no
                // creating a copy of object to hold formatted and extra attributes
                aca = Object.create(acassessment)
                aca.no = utils.padLeft('000', acassessment.refId)
                aca.fexecutedBy =
                    acassessment.executedBy &&
                    acassessment.executedBy.firstName + ' ' + acassessment.executedBy.lastName
                aca.fexecutionDate = acassessment.executionDate
                    ? moment(acassessment.executionDate).format('DD-MM-YYYY')
                    : ''
                aca.status = convertIdToNames(acassessment.state)
                aca.fmaturity = controlAssessmentDropdownData.MATURITY.find(
                    (val) => val.SCORE === acassessment.maturity
                )?.VALUE
                if (acassessment.opStartDate && acassessment.opEndDate) {
                    aca.period =
                        (acassessment.opStartDate &&
                            moment(acassessment.opStartDate).format('DD-MM-YYYY')) +
                        '-' +
                        (acassessment.opEndDate &&
                            moment(acassessment.opEndDate).format('DD-MM-YYYY'))
                }
                aca.norm = acassessment.control?.originNorm?.name
                aca.controlNumber = acassessment.control && acassessment.control.controlNumber
                assessmentsList.value.push(aca)
            })
        }

        // get asset control assessments
        const getAssetControlAssessments = async () => {
            // fetch asset control assessments of controlId or assetId or mutual of both controlId and assetId
            loading.value = true
            const query = `
                query{
                    assetControlAssessments(controlId: ${
                        props.controlId ? props.controlId : null
                    }, assetId: ${props.assetId ? props.assetId : null}){
                         items{
                            ${controlQueries.ASSET_CONTROL_ASSESSMENTS_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving asset control assessments')
            }
            if (result) {
                const assessments = [...result.data.assetControlAssessments.items]
                assessments.sort((a, b) => (a.executionDate < b.executionDate ? 1 : -1))
                // updating assetControlAssessments with additional fields and setting description content
                toTableFormat(assessments)
            }
        }

        // go to asset-control-assessment record
        // go to asset-assessment record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `assessmentsList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the record id (of previous/ next)
                assessmentId.value = assessmentsList.value.find(
                    (assessment) => assessment.no === record.no
                ).id
                assetAssessmentRecordNo.value = record.no
            }
        }
        // open control assessment popup
        const openControlAssessmentPopup = (id) => {
            assessmentId.value = id
            assetAssessmentRecordNo.value = assessmentsList.value.find(
                (assessment) => assessment.id == id
            ).no
            displayAssetControlAssessment.value = true
            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close assessment sidebar if it's open
                    if (displayAssetControlAssessment.value) {
                        displayAssetControlAssessment.value = false
                    }
                }
            })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )

        onMounted(() => {
            closeSidebarEsc()
            getAssetControlAssessments()
        })

        return {
            t,
            language,
            assessmentsList,
            displayAssetControlAssessment,
            openControlAssessmentPopup,
            goToRecord,
            loading,
            getAssetControlAssessments,
            assetAssessmentRecordNo,
            lastRecordNo,
            assessmentId,
            moment,
            tableHeaders,
            tableCmp,
            firstRecordNo,
        }
    },
})
